import { ReactComponent as IconCopy } from "assets/icons/ic_copy.svg";
import { ReactComponent as IconFile } from "assets/icons/ic_file.svg";
import { ReactComponent as IconArrowDown } from "assets/icons/ic_arrow_down.svg";
import { ReactComponent as IconSquareFill } from "assets/icons/ic_check_square_fill.svg";
import { ReactComponent as IconSquare } from "assets/icons/ic_check_square.svg";
import { ReactComponent as IconCircleFill } from "assets/icons/ic_check_circle_fill.svg";
import { ReactComponent as IconCircle } from "assets/icons/ic_check_circle.svg";
import { ReactComponent as IconCalendarGoogle } from "assets/icons/ic_calendar_google.svg";
import { ReactComponent as ImgRefundRequest } from "assets/images/img_refund_request.svg";
import { ReactComponent as ImgRefundSuccess } from "assets/images/img_refund_success.svg";
import { Divider } from "components/divider";
import { onChat, onJoinGMeet } from "helpers";
import config from "helpers/config";
import {
  ArrowRight2,
  Calendar,
  CloseCircle,
  Dislike,
  Like1,
} from "iconsax-react";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  addPrecounseling,
  addReview,
  checkAnswerPrecounseling,
  checkClientNotes,
  checkClientNotesFile,
  checkFormPrecounseling,
  checkOvertime,
  checkReview,
  getCancelReason,
  getSessionDetail,
  postCancel,
  putRequestReschedule,
  putReschedule,
  updateReservation,
  updateSessionStatus,
} from "services/session";
import { CapitalizeFirstLetter, formatRupiah } from "utils";
import { ReactComponent as IconPhone } from "assets/icons/ic_phone.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, ListItemText, MenuItem } from "@mui/material";
import { checkCurrentTime, getTimezone } from "helpers/date";
import RescheduleSection from "./reschedule_section";
import { Loading } from "components/Loading";
import { createRefund } from "services/transaction";
import axios from "axios";
import ModalOvertime from "./modalOvertime";
import ModalOvertimeLink from "./modalOvertimeLink";
import { ModalDelete } from "components/modal/modalSimple";
import { addOvertime, deleteOvertime, updateOvertime } from "services/overtime";
import { getRoleCookies } from "helpers/localStorage";
import ApiCalendar from "react-google-calendar-api";

const configCalendar = {
  clientId: config.GOOGLE_CLIENT_ID,
  apiKey: config.GOOGLE_API_KEY,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const apiCalendar = new ApiCalendar(configCalendar);

export default function DetailSession(props: { code; onChange: () => void }) {
  function downloadFile(_url) {
    const tries = axios.create({
      responseType: "arraybuffer",
      responseEncoding: "binary",
      headers: {
        "Content-Type": "application/pdf",
      },
    });
    tries.get(config.AWS_URL_IMAGES + _url).then((res) => {
      const url = URL.createObjectURL(
        new Blob([res.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `client_notes_${Date.now()}.${_url.split("/").pop().split(".").pop()}`
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  const [dataDetailSession, setDataDetailSession] = useState<any>();
  const [dataNewSchedule, setDataNewSchedule] = useState<any>();

  const [hourReschedule, setHourReschedule] = useState<number>(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const fetchDetailSession = async (code) => {
    const res = await getSessionDetail({
      code: code,
      lang: "en",
    });
    setDataDetailSession(res.data);
  };

  const postUpdateStatus = async () => {
    updateSessionStatus({
      code: props.code,
      status: dataDetailSession.status === "done" ? "confirmed" : "done",
      roomLink: dataDetailSession.room_link,
      eventId: dataDetailSession.event_id,
    }).then((res) => {
      window.location.reload();
    });
  };

  useEffect(() => {
    if (dataDetailSession !== undefined) {
      setHourReschedule(
        checkCurrentTime({
          date: dataDetailSession.date + " " + dataDetailSession.time_from,
        }).hours()
      );

      const interval = setInterval(() => {
        setDays(
          checkCurrentTime({
            date: dataDetailSession.date + " " + dataDetailSession.time_from,
          }).days()
        );
        setHours(
          checkCurrentTime({
            date: dataDetailSession.date + " " + dataDetailSession.time_from,
          }).hours()
        );
        setMinutes(
          checkCurrentTime({
            date: dataDetailSession.date + " " + dataDetailSession.time_from,
          }).minutes()
        );
        setSeconds(
          checkCurrentTime({
            date: dataDetailSession.date + " " + dataDetailSession.time_from,
          }).seconds()
        );
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [dataDetailSession]);

  const [dataPrecounseling, setPrecounseling] = useState<any>();
  const checkPrecounselingAnswer = async (code) => {
    checkAnswerPrecounseling({
      code: code,
      lang: "en",
    }).then((res) => {
      setPrecounBirthday("");
      setPrecounGender("");
      setPrecounRelationship("");
      setPrecounConsideration([]);
      setPrecounExpectation([]);
      setPrecounConcern("");
      setPrecounseling(res.data);
    });
  };

  const [dataPrecounselingForm, setPrecounselingForm] = useState<any>();
  const checkPrecounselingForm = async () => {
    checkFormPrecounseling({
      lang: "en",
    }).then((res) => {
      setPrecounselingForm(res.data);
    });
  };

  const [dataOvertime, setDataOvertime] = useState<any>();
  const checkDataOvertime = async (code) => {
    checkOvertime({
      code: code,
    }).then((res) => {
      setDataOvertime(res.data);
    });
  };

  const [dataReview, setReview] = useState<any>();
  const checkReviewData = async (code) => {
    checkReview({
      code: code,
      lang: "en",
    }).then((res) => {
      setReview(res.data);
    });
  };

  const [dataClientNotes, setClientNotes] = useState<any>();
  const [dataClientNotesFile, setClientNotesFile] = useState<any>();
  const checkClientNotesData = async (code) => {
    checkClientNotes({
      code: code,
      lang: "en",
    }).then((res) => {
      setClientNotes(res.data);
      checkClientNotesFile({
        code: code,
        lang: "en",
      }).then((res) => {
        setClientNotesFile(res.data);
      });
    });
  };

  useEffect(() => {
    fetchDetailSession(props.code);
    checkPrecounselingAnswer(props.code);
    checkReviewData(props.code);
    checkClientNotesData(props.code);
    checkDataOvertime(props.code);
    checkPrecounselingForm();
    fetchCancelReason();
  }, [props.code]);

  const [showDetailPayment, setShowDetailPayment] = useState(false);

  function getTotalPayment(amount, point, promo) {
    return amount - point - promo;
  }

  const [showCancelRequest, setShowCancelRequest] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [email, setEmail] = useState("");

  const [dataCancelReasonChoosed, setDataCancelReasonChoosed] = useState<any>();
  const [dataCancelReason, setDataCancelReason] = useState<any>();
  const [otherReason, setOtherReason] = useState<any>();
  const [rescheduleReason, setRescheduleReason] = useState("");
  const fetchCancelReason = async () => {
    const res = await getCancelReason({
      lang: "en",
    });
    setDataCancelReason(res.data);
    setDataCancelReasonChoosed(res.data[0]);
  };

  const closeModalCancelRequest = async () => {
    if (!isLoadingCancel) {
      setShowCancelRequest(false);
    }
  };

  const onSubmitCancel = async () => {
    setIsLoadingCancel(true);
    postCancel({
      code: props.code,
      email: email,
      cancel_code: dataCancelReasonChoosed.cancel_reason_code,
      other_reason:
        dataCancelReasonChoosed.cancel_reason_code === "CRC-006"
          ? otherReason
          : "",
    })
      .then((res) => {
        setIsLoadingCancel(false);
        setShowCancelRequest(false);
        fetchDetailSession(props.code);
      })
      .catch((err) => {
        setIsLoadingCancel(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [reviewSession, setReviewSession] = useState(-1);
  const [reviewTextSession, setReviewTextSession] = useState("");
  const [isBtnReviewDisabled, setBtnReviewDisabled] = useState(true);
  const [isLoadingReview, setIsLoadingReview] = useState(false);
  const onSubmitReview = async () => {
    setIsLoadingReview(true);
    addReview({
      code: props.code,
      review: reviewTextSession,
      rating: reviewSession === 1 ? true : false,
    })
      .then((res) => {
        setIsLoadingReview(false);
        toast.success("Berhasil mereview");
        checkReviewData(props.code);
        setReviewSession(-1);
        setReviewTextSession("");
      })
      .catch((err) => {
        setIsLoadingReview(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [isDisabledPrecounseling, setIsDisabledPrecounseling] = useState(true);

  const checkIsDisabledPrecounseling = () => {
    setIsDisabledPrecounseling(
      precounBirthday === "" ||
        precounGender === "" ||
        precounRelationship === "" ||
        precounConsideration.length === 0 ||
        precounExpectation.length === 0 ||
        precounConcern === ""
    );
  };

  const [precounBirthday, setPrecounBirthday] = useState("");

  const handleChangeBirthday = (event: SelectChangeEvent) => {
    setPrecounBirthday(event.target.value);
    checkIsDisabledPrecounseling();
  };

  const [precounGender, setPrecounGender] = useState("");

  const handleChangeGender = (event: SelectChangeEvent) => {
    setPrecounGender(event.target.value);
    checkIsDisabledPrecounseling();
  };

  const [precounRelationship, setPrecounRelationship] = useState("");

  const handleChangeRelationship = (event: SelectChangeEvent) => {
    setPrecounRelationship(event.target.value);
    checkIsDisabledPrecounseling();
  };

  const [precounConsideration, setPrecounConsideration] = useState<string[]>(
    []
  );

  const handleChangeConsideration = (
    event: SelectChangeEvent<typeof precounConsideration>
  ) => {
    const {
      target: { value },
    } = event;
    setPrecounConsideration(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    checkIsDisabledPrecounseling();
  };

  const [precounExpectation, setPrecounExpectation] = useState<string[]>([]);

  const handleChangeExpectation = (
    event: SelectChangeEvent<typeof precounExpectation>
  ) => {
    const {
      target: { value },
    } = event;
    setPrecounExpectation(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    checkIsDisabledPrecounseling();
  };

  const [precounConcern, setPrecounConcern] = useState("");

  const [isLoadingPrecounseling, setIsLoadingPrecounseling] = useState(false);

  const onSubmitPrecounseling = async () => {
    setIsLoadingPrecounseling(true);
    addPrecounseling({
      code: props.code,
      form: [
        {
          counseling_id: 2,
          type: "pre_counseling",
          answer: precounBirthday,
        },
        {
          counseling_id: 3,
          type: "pre_counseling",
          answer: precounGender,
        },
        {
          counseling_id: 6,
          type: "pre_counseling",
          answer: precounRelationship,
        },
        {
          counseling_id: 24,
          type: "pre_counseling",
          answer: precounConsideration.toString().replaceAll(", ", "|"),
        },
        {
          counseling_id: 25,
          type: "pre_counseling",
          answer: precounExpectation.toString().replaceAll(", ", "|"),
        },
        {
          counseling_id: 7,
          type: "pre_counseling",
          answer: precounConcern,
        },
      ],
    })
      .then((res) => {
        setIsLoadingPrecounseling(false);
        checkPrecounselingForm();
        window.location.reload();
      })
      .catch((err) => {
        setIsLoadingPrecounseling(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [showOvertimeModal, setShowOvertimeModal] = useState(false);

  const closeModalOvertime = async () => {
    if (!isLoadingCancel) {
      setShowOvertimeModal(false);
    }
  };

  const [showSuccessReschedule, setShowSuccessReschedule] = useState(false);

  const closeSuccessReschedule = async () => {
    if (!isLoadingCancel) {
      setShowSuccessReschedule(false);
    }
  };

  const [showRescheduleModal, setShowRescheduleModal] = useState(false);

  const closeModalReschedule = async () => {
    if (!isLoadingCancel) {
      setShowRescheduleModal(false);
    }
  };

  const reschedule = async (schedule: any, event: any) => {
    putReschedule({
      lang: "id",
      reservationCode: dataDetailSession.reservation_code,
      scheduleCode: schedule.schedule_detail_code,
    }).then((val) => {
      updateDataReservation(event);
    });
  };

  const updateDataReservation = async (event: any) => {
    updateReservation({
      reservationCode: dataDetailSession.reservation_code,
      roomLink: event.conferenceData.entryPoints[0].uri,
      eventId: event.id,
    }).then((val) => {
      window.location.reload();
    });
  };

  const submitRequestReschedule = async () => {
    putRequestReschedule({
      lang: "id",
      reservationCode: dataDetailSession.reservation_code,
      reason: rescheduleReason,
    }).then((val) => {
      window.location.reload();
    });
  };

  const [loadingAddCalendar, setLoadingAddCalendar] = useState(false);
  const addToCalendar = () => {
    setLoadingAddCalendar(true);

    apiCalendar
      .handleAuthClick()
      .then((result: any) => {
        setLoadingAddCalendar(false);
        createEvent();
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
      });
  };

  const rescheduleCalendar = (schedule: any) => {
    setDataNewSchedule(schedule);
    closeModalReschedule();
    setLoadingAddCalendar(true);

    apiCalendar
      .handleAuthClick()
      .then((result: any) => {
        setLoadingAddCalendar(false);
        if (dataDetailSession.room_link === "") {
          createRescheduleEvent(schedule);
        } else {
          updateRescheduleEvent(schedule);
        }
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
      });
  };

  const createEvent = () => {
    console.log("CREATE");
    var _start = moment(
      dataDetailSession.date + " " + dataDetailSession.time_from
    ).format("yyyy-MM-DD HH:mm");
    var _end = moment(
      dataDetailSession.date + " " + dataDetailSession.time_to
    ).format("yyyy-MM-DD HH:mm");
    let events = {
      summary: "Sesi konsultasi 60 menit",
      guestsCanSeeOtherGuests: false,
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      start: {
        dateTime: moment(_start).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      end: {
        dateTime: moment(_end).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      attendees: [
        {
          email: dataDetailSession.member_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.member_username,
        },
        {
          email: dataDetailSession.psycholog_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.psycholog_username,
        },
      ],
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: { type: "hangoutsMeet" },
          requestId: dataDetailSession.reservation_code,
        },
      },
      visibility: "private",
    };

    apiCalendar
      .createEventWithVideoConference(events, "primary", "all")
      .then((data: any) => {
        setLoadingAddCalendar(false);
        toast.success("Sukses menambahkan ke Kalender Google");
        console.log(data.result);
        updateDataReservation(data.result);
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
        toast.success("Gagal menambahkan ke Kalender Google");
        console.log(error);
      });
  };

  const createRescheduleEvent = (schedule: any) => {
    console.log("CREATE");
    var _start = moment(schedule.date + " " + schedule.time_from).format(
      "yyyy-MM-DD HH:mm"
    );
    var _end =
      dataDetailSession.format_call === "preview"
        ? moment(
            schedule.date + " " + schedule.time_from.substring(0, 2) + ":15"
          ).format("yyyy-MM-DD HH:mm")
        : moment(schedule.date + " " + schedule.time_to).format(
            "yyyy-MM-DD HH:mm"
          );
    let events = {
      summary:
        dataDetailSession.format_call === "preview"
          ? "Sesi konsultasi 15 menit"
          : "Sesi konsultasi 60 menit",
      guestsCanSeeOtherGuests: false,
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      start: {
        dateTime: moment(_start).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      end: {
        dateTime: moment(_end).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      attendees: [
        {
          email: dataDetailSession.member_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.member_username,
        },
        {
          email: dataDetailSession.psycholog_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.psycholog_username,
        },
      ],
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: { type: "hangoutsMeet" },
          requestId: dataDetailSession.reservation_code,
        },
      },
      visibility: "private",
    };

    apiCalendar
      .createEventWithVideoConference(events, "primary", "all")
      .then((data: any) => {
        setLoadingAddCalendar(false);
        toast.success("Sukses menambahkan ke Kalender Google");
        console.log(data.result);
        reschedule(schedule, data.result);
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
        toast.success("Gagal menambahkan ke Kalender Google");
        console.log(error);
      });
  };

  const updateRescheduleEvent = (schedule: any) => {
    console.log("UPDATE");
    var _start = moment(schedule.date + " " + schedule.time_from).format(
      "yyyy-MM-DD HH:mm"
    );
    var _end =
      dataDetailSession.format_call === "preview"
        ? moment(
            schedule.date + " " + schedule.time_from.substring(0, 2) + ":15"
          ).format("yyyy-MM-DD HH:mm")
        : moment(schedule.date + " " + schedule.time_to).format(
            "yyyy-MM-DD HH:mm"
          );
    let events = {
      summary:
        dataDetailSession.format_call === "preview"
          ? "Sesi konsultasi 15 menit"
          : "Sesi konsultasi 60 menit",
      guestsCanSeeOtherGuests: false,
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      start: {
        dateTime: moment(_start).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      end: {
        dateTime: moment(_end).format(),
        timeZone: dataDetailSession.member_timezone.title,
      },
      attendees: [
        {
          email: dataDetailSession.member_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.member_username,
        },
        {
          email: dataDetailSession.psycholog_email,
          responseStatus: "needsAction",
          displayName: dataDetailSession.psycholog_username,
        },
      ],
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: { type: "hangoutsMeet" },
          requestId: dataDetailSession.reservation_code,
        },
      },
      visibility: "private",
    };

    apiCalendar
      .updateEvent(events, dataDetailSession.event_id, "primary", "all")
      .then((data: any) => {
        setLoadingAddCalendar(false);
        toast.success("Sukses menambahkan ke Kalender Google");
        console.log(data);
        reschedule(schedule, data.result);
      })
      .catch((error: any) => {
        setLoadingAddCalendar(false);
        toast.success("Gagal menambahkan ke Kalender Google");
        console.log(error);
      });
  };

  const [showRequestRefund, setShowRequestRefund] = useState(false);
  const [showRequestSuccess, setShowRequestSuccess] = useState(false);
  const [emailRefund, setEmailRefund] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isDisabledRefund, setIsDisabledRefund] = useState(true);

  const onSubmitRefund = async () => {
    setIsLoading(true);
    createRefund({
      order_code: dataDetailSession.order_code,
      email: emailRefund,
    })
      .then((res) => {
        setIsLoading(false);
        setShowRequestRefund(false);
        setShowRequestSuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const closeModalRequestRefund = async () => {
    if (!isLoading) {
      setShowRequestRefund(false);
    }
  };
  const [isShowModalOvertime, setShowModalOvertime] = useState(false);
  const [isShowModalOvertimeLink, setShowModalOvertimeLink] = useState(false);
  const [isShowModalDeleteOvertime, setShowModalDeleteOvertime] =
    useState(false);

  const onSubmitOvertime = async (val: any) => {
    if (dataOvertime) {
      await updateOvertime({
        reservationCode: dataDetailSession.reservation_code,
        ...val,
      });

      setShowModalOvertime(false);
    } else {
      await addOvertime({
        reservationCode: dataDetailSession.reservation_code,
        ...val,
      });

      setShowModalOvertime(false);
    }
    toast.success("data already updated");
    await checkDataOvertime(props.code);
    setShowModalOvertimeLink(true);
  };

  const onDeleteOvertime = () => {
    if (dataDetailSession.overtime.status !== "created") {
      return toast.warn("cannot delete overtime");
    }

    setShowModalOvertime(false);
    setShowModalDeleteOvertime(true);
  };

  const onEditOvertime = () => {
    setShowModalOvertime(true);
    setShowModalOvertimeLink(false);
  };

  const onDeleteOvertimeAction = async () => {
    await deleteOvertime({
      reservationCode: dataDetailSession.reservation_code,
    });

    setShowModalDeleteOvertime(false);
    toast.success("data already updated");
  };

  return (
    <div className="p-6">
      <div className="w-full">
        <h1 className="text-xl font-semibold">Session's Info</h1>
      </div>
      <div>
        {dataDetailSession !== undefined ? (
          <div className="">
            {getRoleCookies() === "member" || getRoleCookies() === "member_org"
              ? bodyMember()
              : bodySpecialist()}
          </div>
        ) : null}
      </div>

      <ModalOvertime
        hide={() => setShowModalOvertime(false)}
        isShow={isShowModalOvertime}
        onSubmit={onSubmitOvertime}
        onDelete={onDeleteOvertime}
        value={dataDetailSession ?? {}}
        overtime={dataOvertime ?? {}}
      ></ModalOvertime>

      <ModalOvertimeLink
        session={dataDetailSession}
        overtime={dataOvertime}
        isShow={isShowModalOvertimeLink}
        onHide={() => setShowModalOvertimeLink(false)}
        onSubmit={onEditOvertime}
      ></ModalOvertimeLink>

      <ModalDelete
        isOpen={isShowModalDeleteOvertime}
        onClose={() => setShowModalDeleteOvertime(false)}
        onDelete={onDeleteOvertimeAction}
        title="Apakah Anda yakin ingin menghapus overtime ini?"
      ></ModalDelete>

      {showCancelRequest ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                closeModalCancelRequest();
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[820px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <div className="flex place-self-stretch items-start justify-between min-w-20">
                    <h3 className="text-base font-semibold">Cancel Session</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => closeModalCancelRequest()}
                    >
                      <CloseCircle size="24" color="#121122" />
                    </button>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="place-items-center">
                      <ImgRefundRequest className="my-6" />
                      <p className="font-semibold text-lg">
                        Session Cancellation Refund
                      </p>
                      <p className="text-sm text-text-grey mt-2 mb-2">
                        Our customer service will reach out to you about your
                        cancellation refund via the email address you provided.
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-start">
                        Select Reason
                      </p>
                      {dataCancelReason !== undefined
                        ? dataCancelReason.map((val, i) => {
                            return (
                              <div
                                key={i}
                                className={
                                  "border border-background-neutral px-4 py-2 rounded-full mt-2 " +
                                  (val.cancel_reason_code ===
                                  dataCancelReasonChoosed.cancel_reason_code
                                    ? "bg-blue-background"
                                    : "bg-white")
                                }
                                onClick={() => setDataCancelReasonChoosed(val)}
                              >
                                <p className="text-sm">{val.name}</p>
                              </div>
                            );
                          })
                        : null}
                      {dataCancelReasonChoosed.cancel_reason_code ===
                      "CRC-006" ? (
                        <textarea
                          id="textarea"
                          rows={3}
                          value={otherReason}
                          onChange={(val) => setOtherReason(val.target.value)}
                          className="block p-2.5 mt-3 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
                          placeholder={"Other Reason"}
                        ></textarea>
                      ) : null}
                    </div>
                  </div>

                  <p className="text-sm text-text-black font-medium mt-6">
                    Which email address do you want to use?
                  </p>
                  <input
                    id="email"
                    defaultValue={email}
                    onChange={(val) => setEmail(val.target.value)}
                    type="email"
                    className=" w-80 mt-2 p-4 h-12 text-gray-900 rounded-full bg-white border border-neutral-300 focus:border-blue-200"
                    placeholder="Your email address"
                  />
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full w-80 h-12 mt-6 disabled:bg-button-disabled"
                    disabled={
                      dataCancelReasonChoosed === undefined || email === ""
                    }
                    onClick={() => onSubmitCancel()}
                  >
                    <strong>{isLoadingCancel ? "Loading..." : "Submit"}</strong>
                    {isLoadingCancel ? (
                      <div
                        className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      ></div>
                    ) : null}
                  </button>
                  <p className="text-sm mt-4 text-text-black">
                    Do you want to chat with customer service?
                  </p>
                  <p
                    className="text-sm text-text-dark-blue cursor-pointer"
                    onClick={() => onChat()}
                  >
                    Contact us on whatsapp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showOvertimeModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                closeModalOvertime();
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[520px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <div className="flex place-self-stretch items-start justify-between min-w-20">
                    <h3 className="text-base font-semibold">Overtime Price</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => closeModalOvertime()}
                    >
                      <CloseCircle size="24" color="#121122" />
                    </button>
                  </div>
                  <div className="border border-custom-border p-4 w-full rounded-lg text-start mt-4">
                    <p className="text-sm">Overtime Details</p>
                    <Divider className="my-3" />
                    <p className="text-xs text-text-grey font-medium">Time</p>
                    <p className="text-sm text-text-black mb-3 mt-1">
                      {dataOvertime.duration + " minutes"}
                    </p>
                    <p className="text-xs text-text-grey font-medium">Total</p>
                    <p className="text-sm text-text-black mb-3 mt-1">
                      {formatRupiah(dataOvertime.price)}
                    </p>
                    <p className="text-xs text-text-grey font-medium">Status</p>
                    <p className="text-sm text-text-black mt-1">
                      {dataOvertime.status === "confirmed" ? "Paid" : "Unpaid"}
                    </p>
                  </div>
                  {dataOvertime.status !== "confirmed" ? (
                    <div>
                      <div className="border border-custom-border p-4 w-full rounded-lg text-start mt-4">
                        <p className="text-sm">Payment Link</p>
                        <Divider className="my-3" />
                        <p className="text-sm text-text-black font-semibold">
                          Overtime Confirmation
                        </p>
                        <p className="text-sm text-text-black mt-1">
                          {"Name: " + dataDetailSession.psycholog_username}
                        </p>
                        <p className="text-sm text-text-black mt-1">
                          {"Session: " +
                            dataOvertime.duration +
                            " min Overtime"}
                        </p>
                        <p className="text-sm text-text-black mt-1">
                          {"Date: " +
                            moment(dataOvertime.created_date).format(
                              "DD MMMM YYYY"
                            )}
                        </p>
                        <p className="text-sm text-text-black mt-1">
                          {"Time: " +
                            moment(dataDetailSession.time_from, "HH:mm").format(
                              "HH:mm A"
                            )}
                        </p>
                        <p className="text-sm text-text-black mt-4">
                          Use this link to complete your payment via website:{" "}
                          <Link
                            to={
                              config.LANDING_URL +
                              "/booking/reservation/" +
                              dataDetailSession.reservation_code +
                              "/overtime"
                            }
                            target="blank"
                          >
                            <span className="text-text-dark-blue">
                              {config.LANDING_URL +
                                "/booking/reservation/" +
                                dataDetailSession.reservation_code +
                                "/overtime"}
                            </span>
                          </Link>{" "}
                          or pay via Maxi App
                        </p>
                      </div>
                      <Link
                        to={
                          config.LANDING_URL +
                          "/booking/reservation/" +
                          dataDetailSession.reservation_code +
                          "/overtime"
                        }
                        target="blank"
                      >
                        <button
                          type="button"
                          className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full px-8 h-12 mt-4 disabled:bg-slate-400 disabled:cursor-not-allowed"
                        >
                          <p className="font-medium">Pay Now</p>
                        </button>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showRescheduleModal ? (
        <RescheduleSection
          code={dataDetailSession.reservation_code}
          psychologCode={dataDetailSession.psycholog_code}
          formatCall={dataDetailSession.format_call}
          onClose={() => closeModalReschedule()}
          onReschedule={(scheduleCode: any) => rescheduleCalendar(scheduleCode)}
        />
      ) : null}

      {showSuccessReschedule ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                closeModalOvertime();
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[520px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <h1 className="text-2xl font-bold text-custom-dark-blue">
                    Reschedule Success
                  </h1>
                  <p>
                    Your session has been rescheduled
                    <br />
                    to{" "}
                    {moment(
                      dataNewSchedule.date + " " + dataNewSchedule.time_from
                    ).format("yyyy-MM-DD HH:mm")}
                  </p>
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full h-12 w-full mt-6"
                    onClick={() => closeSuccessReschedule()}
                  >
                    <p className="font-medium">Continue</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showRequestRefund ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                closeModalRequestRefund();
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[580px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <div className="flex place-self-stretch items-start justify-between min-w-20">
                    <h3 className="text-base font-semibold">Request Refund</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => closeModalRequestRefund()}
                    >
                      <CloseCircle size="24" color="#121122" />
                    </button>
                  </div>
                  <ImgRefundRequest className="my-6" />
                  <p className="font-semibold text-lg">
                    Request Refund via Email
                  </p>
                  <p className="text-sm text-text-grey mt-2 mb-2">
                    Our customer service will reach out to you via
                    <br />
                    the email address you provided.
                  </p>
                  <div className="grid place-items-start">
                    <div className="flex items-center justify-start mt-3">
                      <div className="border border-brand-dark rounded-full w-5 h-5 flex items-center justify-center">
                        <p className="text-xs text-center	text-brand-dark">1</p>
                      </div>
                      <p className="text-text-grey text-sm ml-2">
                        Submit your email address
                      </p>
                    </div>
                    <div className="flex items-center justify-start mt-3">
                      <div className="border border-brand-dark rounded-full w-5 h-5 flex items-center justify-center">
                        <p className="text-xs text-center	text-brand-dark">2</p>
                      </div>
                      <p className="text-text-grey text-sm ml-2">
                        We will review your request
                      </p>
                    </div>
                    <div className="flex items-center justify-start mt-3">
                      <div className="border border-brand-dark rounded-full w-5 h-5 flex items-center justify-center">
                        <p className="text-xs text-center	text-brand-dark">3</p>
                      </div>
                      <p className="text-text-grey text-sm ml-2">
                        We will inform you of the outcome via email
                      </p>
                    </div>
                  </div>
                  <input
                    id="email"
                    defaultValue={emailRefund}
                    onChange={(val) => {
                      setEmailRefund(val.target.value);
                      setIsDisabledRefund(val.target.value === "");
                    }}
                    type="email"
                    className=" w-80 mt-6 p-4 h-12 text-gray-900 rounded-full bg-white border border-neutral-300 focus:border-blue-200"
                    placeholder="Your email address"
                  />
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full w-80 h-12 mt-6 disabled:bg-slate-300"
                    disabled={isDisabledRefund}
                    onClick={() => onSubmitRefund()}
                  >
                    <strong>{isLoading ? "Loading..." : "Submit"}</strong>
                    {isLoading ? (
                      <div
                        className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      ></div>
                    ) : null}
                  </button>
                  <p className="text-sm mt-4 text-text-black">
                    Do you want to chat with customer service?
                  </p>
                  <p
                    className="text-sm text-text-dark-blue cursor-pointer"
                    onClick={() => onChat()}
                  >
                    Contact us on whatsapp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showRequestSuccess ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                setShowRequestSuccess(false);
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[580px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <div className="flex place-self-stretch items-start justify-between min-w-20">
                    <h3 className="text-base font-semibold">Request Refund</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => setShowRequestSuccess(false)}
                    >
                      <CloseCircle size="24" color="#121122" />
                    </button>
                  </div>
                  <ImgRefundSuccess className="my-6" />
                  <p className="font-semibold text-lg text-text-dark-blue">
                    Thank you
                  </p>
                  <p className="text-sm text-text-black mt-2 mb-2">
                    we have received your request and
                    <br />
                    will get back to you shortly by email.
                  </p>
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full w-80 h-12 mt-6"
                    onClick={() => setShowRequestSuccess(false)}
                  >
                    <strong>Thank you</strong>
                  </button>
                  <p className="text-sm mt-4 text-text-black">
                    Do you want to chat with customer service?
                  </p>
                  <p
                    className="text-sm text-text-dark-blue cursor-pointer"
                    onClick={() => onChat()}
                  >
                    Contact us on whatsapp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {loadingAddCalendar ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-white/60">
            <div className="absolute w-full my-6 mx-auto md:max-w-lg">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="px-10 py-6 w-full">
                  <Loading></Loading>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );

  function bodyMember() {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
        <div className="md:col-span-2">
          <div className="flex items-end justify-between">
            <p>Status:</p>

            {dataDetailSession.status === "done" ? (
              <div className="px-4 py-2 rounded-lg bg-green-background">
                <p className="text-text-dark-green">Past</p>
              </div>
            ) : dataDetailSession.status === "cancelled_book" ? (
              <div className="px-4 py-2 rounded-lg bg-red-background">
                <p className="text-text-dark-red">Cancelled</p>
              </div>
            ) : (
              <div className="px-4 py-2 rounded-lg bg-blue-background">
                <p className="text-text-dark-blue">
                  {dataDetailSession.status === "rescheduled"
                    ? "Rescheduled"
                    : dataDetailSession.status === "request_reschedule"
                    ? "Rescheduled"
                    : hours === 0 && minutes < 0 && days === 0
                    ? "Current"
                    : "Booked"}
                </p>
              </div>
            )}
          </div>

          {hours === 0 && minutes < 0 && days === 0 ? (
            <div className="flex flex-col items-center w-fill mt-2">
              <p className="text-sm text-text-grey">Remaining Time:</p>
              <div className="bg-blue-background w-full py-4 m-auto text-center rounded-lg mt-2 text-xl text-text-dark-blue font-medium">
                {days + " : " + hours + " : " + minutes + " : " + seconds}
              </div>
            </div>
          ) : null}
        </div>

        <div>
          <div className="border border-custom-border rounded-lg p-4">
            <div key={dataDetailSession.reservation_code} className="w-full">
              <div className="w-12 float-left">
                <img
                  src={
                    config.AWS_URL_IMAGES +
                    dataDetailSession.psycholog_profile_url
                  }
                  className="w-10 h-10 rounded-full object-cover"
                  alt=""
                />
              </div>
              <div className="ml-2">
                <p className="font-medium text-sm">
                  {dataDetailSession.psycholog_username}
                </p>
                <p className="font-normal text-text-grey text-xs">
                  {CapitalizeFirstLetter(
                    dataDetailSession.psycholog_membership
                  )}
                </p>
              </div>
            </div>
            <Divider className="py-4" />
            <div>
              <p className="text-xs font-medium text-text-grey">Appointment</p>
              <p className="text-sm pt-1">
                {dataDetailSession.format_call === "video_call"
                  ? "Video Call 60 minutes"
                  : dataDetailSession.format_call === "voice_call"
                  ? "Voice Call 60 minutes"
                  : "Preview 15 minutes"}
              </p>
            </div>
            <div className="mt-2">
              <p className="text-xs font-medium text-text-grey">
                Date and Time
              </p>
              <p className="text-sm pt-1">
                {moment(
                  dataDetailSession.date.toString() +
                    ", " +
                    dataDetailSession.time_from
                ).format("MMMM D YYYY, hh:mm A")}
              </p>
            </div>
            <div className="mt-2">
              <p className="text-xs font-medium text-text-grey">Time Zone</p>
              <p className="text-sm pt-1">
                {dataDetailSession.member_timezone.title +
                  " (" +
                  dataDetailSession.member_timezone.zone +
                  getTimezone({
                    offset: dataDetailSession.member_timezone.offset,
                  }) +
                  ")"}
              </p>
            </div>
            <Divider className="py-4" />
            <div>
              {showDetailPayment ? (
                <div>
                  <p className="text-sm font-medium">Payment Detail</p>
                  <div className="flex items-center justify-between pt-1.5">
                    <p className="text-sm text-text-grey">Subtotal</p>
                    <p className="text-sm text-text-grey">
                      {formatRupiah(dataDetailSession.amount)}
                    </p>
                  </div>
                  <div className="flex items-center justify-between pt-1.5">
                    <p className="text-sm text-text-grey">Maxi Point</p>
                    <p className="text-sm text-text-grey">
                      {"-" + formatRupiah(dataDetailSession.point_used)}
                    </p>
                  </div>
                  <div className="flex items-center justify-between pt-1.5">
                    <p className="text-sm text-text-grey">Promo</p>
                    <p className="text-sm text-text-grey">
                      {"-" + formatRupiah(dataDetailSession.promo_used)}
                    </p>
                  </div>
                  <div className="flex items-center justify-between pt-1.5">
                    <p className="text-base">Total</p>
                    <p className="text-base">
                      {formatRupiah(
                        getTotalPayment(
                          dataDetailSession.amount,
                          dataDetailSession.point_used,
                          dataDetailSession.promo_used
                        )
                      )}
                    </p>
                  </div>
                  <div className="flex items-center justify-center pt-1.5">
                    <p
                      className="text-sm text-text-dark-blue cursor-pointer"
                      onClick={() => setShowDetailPayment(false)}
                    >
                      Hide Details
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-xs font-medium text-text-grey">
                    Payment Amount
                  </p>
                  <div className="flex items-center justify-between">
                    <p className="text-sm pt-1">
                      {formatRupiah(
                        getTotalPayment(
                          dataDetailSession.amount,
                          dataDetailSession.point_used,
                          dataDetailSession.promo_used
                        )
                      )}
                    </p>
                    <p
                      className="text-sm text-text-dark-blue cursor-pointer"
                      onClick={() => setShowDetailPayment(true)}
                    >
                      See Details
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {dataDetailSession.status === "done" ? (
            <div>
              {dataOvertime !== undefined ? (
                dataOvertime !== null ? (
                  <div
                    className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer"
                    onClick={() => setShowOvertimeModal(true)}
                  >
                    <div>
                      <p className="text-xs text-text-grey">Overtime Price</p>
                      <p className="text-sm mt-1">
                        {dataOvertime.duration + " minutes "}
                        <span className="text-text-dark-blue">
                          {"(" + formatRupiah(dataOvertime.price) + ")"}
                        </span>
                      </p>
                    </div>
                    {dataOvertime.status === "confirmed" ? (
                      <IconCircleFill style={{ fill: "#167CB8" }} />
                    ) : (
                      <ArrowRight2 size="20" color="#292A2E" />
                    )}
                  </div>
                ) : null
              ) : null}

              <div className="border border-custom-border rounded-lg p-4 mt-3">
                <p className="text-sm">Was this session helpful?</p>
                {dataReview !== null && dataReview !== undefined ? (
                  <div>
                    {dataReview.rating ? (
                      <div>
                        <div className="flex items-center mt-3">
                          <Like1 size="20" color="#00AC56" variant="Bold" />
                          <p className="text-text-success ml-1 text-sm font-medium">
                            found this helpful
                          </p>
                        </div>
                        <p className="text-text-grey mt-3 text-sm">
                          {dataReview.review}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center mt-3">
                          <Dislike size="20" color="#FF1F7D" variant="Bold" />
                          <p className="text-text-error ml-1 text-sm font-medium">
                            found this unhelpful
                          </p>
                        </div>
                        <p className="text-text-grey mt-3 text-sm">
                          {dataReview.review}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center mt-4">
                      <Like1
                        size="26"
                        color={reviewSession === 1 ? "#00AC56" : "#A0AAB8"}
                        variant={reviewSession === 1 ? "Bold" : "Linear"}
                        className="cursor-pointer"
                        onClick={() => setReviewSession(1)}
                      />
                      <span className="w-2"></span>
                      <Dislike
                        size="26"
                        color={reviewSession === 0 ? "#FF1F7D" : "#A0AAB8"}
                        variant={reviewSession === 0 ? "Bold" : "Linear"}
                        className="cursor-pointer"
                        onClick={() => setReviewSession(0)}
                      />
                    </div>
                    <textarea
                      defaultValue={reviewTextSession}
                      placeholder="Write down your feedback"
                      onChange={(val) => {
                        setReviewTextSession(val.target.value);
                        setBtnReviewDisabled(val.target.value === "");
                      }}
                      rows={5}
                      className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-lg mt-4 text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue resize-none"
                    />
                    <div className="w-full flex items-center justify-center">
                      <button
                        type="button"
                        disabled={isBtnReviewDisabled}
                        className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-auto px-8 h-12 mt-4 disabled:bg-slate-400	disabled:cursor-not-allowed"
                        onClick={() => onSubmitReview()}
                      >
                        <strong>
                          {isLoadingReview ? "Loading..." : "Send"}
                        </strong>
                        {isLoadingReview ? (
                          <div
                            className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          ></div>
                        ) : null}
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {dataReview !== null && dataReview !== undefined ? (
                <div>
                  {dataReview.rating ? (
                    <div>
                      <p className="text-center	text-xs text-text-grey mt-4 px-4">
                        Session not completed yet?
                      </p>
                      <p
                        className="text-center	text-xs text-text-dark-blue mt-1 px-4 cursor-pointer"
                        onClick={() => postUpdateStatus()}
                      >
                        Update Session status
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-center	text-xs text-text-grey mt-4 px-4">
                        Not satisfied with the session?
                      </p>
                      <p
                        className="text-center	text-xs text-text-dark-blue mt-1 px-4 cursor-pointer"
                        onClick={() => setShowRequestRefund(true)}
                      >
                        Request Refund
                      </p>
                    </div>
                  )}
                </div>
              ) : null}

              <Link
                to={
                  config.LANDING_URL +
                  "/booking/profile/" +
                  dataDetailSession.psycholog_code
                }
              >
                <div className="bg-button-black h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-5">
                  <p className="text-white ml-2">Book Again</p>
                </div>
              </Link>
            </div>
          ) : (
            <div>
              {dataDetailSession.room_link === "" ? (
                <div
                  className="flex items-center justify-between border border-custom-border rounded-full p-4 mt-3 cursor-pointer"
                  onClick={() => addToCalendar()}
                >
                  <IconCalendarGoogle />
                  <p className="text-sm font-medium">Tambah ke Kalender</p>
                  <span className="w-[20px]"></span>
                </div>
              ) : (
                <div className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer">
                  <div>
                    <p className="text-sm">Tautan Google Meet</p>{" "}
                    <p
                      className="text-sm text-text-dark-blue"
                      onClick={() => {
                        if (dataDetailSession.room_link)
                          onJoinGMeet(dataDetailSession.room_link);
                      }}
                    >
                      {dataDetailSession.room_link.replaceAll("https://", "")}
                    </p>
                  </div>
                  <IconCopy
                    onClick={() => {
                      navigator.clipboard.writeText(
                        dataDetailSession.room_link
                      );
                      toast.success("Tautan berhasil disalin");
                    }}
                  />
                </div>
              )}

              {dataOvertime !== undefined ? (
                dataOvertime !== null ? (
                  <div
                    className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer"
                    onClick={() => setShowOvertimeModal(true)}
                  >
                    <div>
                      <p className="text-xs text-text-grey">Overtime Price</p>
                      <p className="text-sm mt-1">
                        {dataOvertime.duration + " minutes "}{" "}
                        <span className="text-text-dark-blue">
                          {"(" + formatRupiah(dataOvertime.price) + ")"}
                        </span>
                      </p>
                    </div>
                    {dataOvertime.status === "confirmed" ? (
                      <IconCircleFill style={{ fill: "#167CB8" }} />
                    ) : (
                      <ArrowRight2 size="20" color="#292A2E" />
                    )}
                  </div>
                ) : null
              ) : null}

              {dataDetailSession.status === "cancelled_book" ? (
                <div className="mt-4">
                  <p className="text-xs font-medium text-text-grey">
                    Cancellation Reason:
                  </p>
                  <p className="text-sm text-text-black mt-1">
                    {dataDetailSession.cancelled_book.cancel_reason ===
                      "Other reasons" ||
                    dataDetailSession.cancelled_book.cancel_reason ===
                      "Alasan lain"
                      ? dataDetailSession.cancelled_book.cancel_reason +
                        ": " +
                        dataDetailSession.cancelled_book.other_reason
                      : dataDetailSession.cancelled_book.cancel_reason}
                  </p>
                </div>
              ) : (
                <div>
                  <div className="grid grid-cols-2 gap-4">
                    <div
                      className={`flex items-center justify-center border border-custom-border rounded-lg p-4 mt-3 cursor-pointer ${
                        hourReschedule < 6 && days < 1
                          ? "bg-button-disabled"
                          : ""
                      }`}
                      onClick={() => {
                        if (days >= 0 && hourReschedule > 5)
                          setShowRescheduleModal(true);
                      }}
                    >
                      <Calendar
                        size="20"
                        color={`${
                          hourReschedule < 6 && days < 1 ? "#767E8C" : "#1F1F21"
                        }`}
                        variant="Bold"
                      />
                      <p
                        className={`text-sm ml-2 ${
                          hourReschedule < 6 && days < 1 ? "text-text-grey" : ""
                        }`}
                      >
                        Reschedule
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        if (days >= 0 && hourReschedule > 5)
                          setShowCancelRequest(true);
                      }}
                      className={`flex items-center justify-center border border-custom-border rounded-lg p-4 mt-3 cursor-pointer ${
                        hourReschedule < 6 && days < 1
                          ? "bg-button-disabled"
                          : ""
                      }`}
                    >
                      <CloseCircle
                        size="20"
                        color={`${
                          hourReschedule < 6 && days < 1 ? "#767E8C" : "#1F1F21"
                        }`}
                        variant="Bold"
                      />
                      <p
                        className={`text-sm ml-2 ${
                          hourReschedule < 6 && days < 1 ? "text-text-grey" : ""
                        }`}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>

                  <p className="text-center	text-xs text-text-grey mt-4 px-4">
                    Note: Sessions can only be rescheduled at least six hours
                    before it starts.
                  </p>
                  <div
                    className={`h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-4 ${
                      dataDetailSession.room_link
                        ? "bg-button-black"
                        : "bg-gray-200"
                    }`}
                    onClick={() => {
                      if (dataDetailSession.room_link)
                        onJoinGMeet(dataDetailSession.room_link);
                    }}
                  >
                    <IconPhone style={{ fill: "#fff" }} />
                    <p className="text-white ml-2">Join Google Meet</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <Divider className="md:hidden py-4" />

        {dataDetailSession.status === "cancelled_book" ? (
          <div></div>
        ) : (
          <div>
            {dataDetailSession.status === "done" ? (
              <div className="h-fit border border-custom-border rounded-lg p-4">
                <p className="text-base font-medium">Client Progress Notes</p>

                {dataClientNotes !== null && dataClientNotes !== undefined ? (
                  <div>
                    <div className="flex items-center mt-2">
                      {dataClientNotes.red_flag ? (
                        <IconSquareFill />
                      ) : (
                        <IconSquare />
                      )}
                      <p className="text-sm ml-2">Need further consultation</p>
                    </div>

                    <p className="text-sm font-medium text-text-grey mt-4">
                      Description of issues
                    </p>
                    <p className="text-sm text-text-black mt-1">
                      {dataClientNotes.description_issues}
                    </p>

                    <p className="text-sm font-medium text-text-grey mt-4">
                      Assessment of situation
                    </p>
                    <p className="text-sm text-text-black mt-1">
                      {dataClientNotes.assessment_situation}
                    </p>

                    <p className="text-sm font-medium text-text-grey mt-4">
                      Plans for follow up
                    </p>
                    <p className="text-sm text-text-black mt-1">
                      {dataClientNotes.plan_followup}
                    </p>

                    {dataClientNotesFile !== null &&
                    dataClientNotesFile !== undefined ? (
                      <div>
                        <p className="text-sm font-medium text-text-grey mt-4">
                          Attachment
                        </p>
                        <div className="flex flex-col gap-2 mt-2">
                          {dataClientNotesFile.map((val, i) => {
                            return (
                              <div className="flex items-center justify-around gap-2">
                                <IconFile width={20} height={20} />
                                <p className="w-full text-sm">
                                  {"File " +
                                    (i + 1) +
                                    "." +
                                    val.file_url
                                      .split("/")
                                      .pop()
                                      .split(".")
                                      .pop()}
                                </p>
                                <p
                                  onClick={() => downloadFile(val.file_url)}
                                  className="text-sm font-medium text-custom-dark-blue cursor-pointer"
                                >
                                  Download
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <p className="mt-2 text-sm text-text-grey">No Note Yet</p>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {dataPrecounseling !== undefined &&
                dataPrecounselingForm !== undefined ? (
                  <div>
                    {dataPrecounseling.answers !== null ? (
                      <div className="border border-custom-border rounded-lg p-4">
                        <p>Pre Counseling Form</p>
                        <div>
                          {dataPrecounseling.answers.map((item, i) => (
                            <div key={i} className="py-1">
                              <p className="text-sm text-text-grey mt-2">
                                {item.title}
                              </p>
                              <p className="text-sm text-text-black mt">
                                {item.answer}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="border border-custom-border rounded-lg p-4">
                        <p className="text-base font-medium">
                          Pre Counseling Form
                        </p>
                        {dataPrecounselingForm !== null ? (
                          <div className="mt-2">
                            {dataPrecounselingForm.map((item, i) => (
                              <div key={i} className="py-1">
                                {item.counseling_code === "CNS-00002" ? (
                                  <div className="mt-2">
                                    <p className="text-sm text-text-grey font-medium mb-2">
                                      {item.title}
                                    </p>
                                    <FormControl
                                      fullWidth
                                      sx={{
                                        m: 0,
                                      }}
                                    >
                                      <Select
                                        value={precounBirthday}
                                        onChange={handleChangeBirthday}
                                        renderValue={(selected) => selected}
                                        sx={{
                                          borderRadius: "50px",
                                          border: 0.5,
                                          borderColor: "#F3F5F9",
                                        }}
                                        IconComponent={IconArrowDown}
                                      >
                                        {item.answer.map((_answer) => (
                                          <MenuItem
                                            key={_answer.order}
                                            value={_answer.title}
                                          >
                                            {precounBirthday ===
                                            _answer.title ? (
                                              <IconCircleFill className="mr-2" />
                                            ) : (
                                              <IconCircle className="mr-2" />
                                            )}
                                            <ListItemText
                                              primary={_answer.title}
                                            />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : item.counseling_code === "CNS-00003" ? (
                                  <div className="mt-2">
                                    <p className="text-sm text-text-grey font-medium mb-2">
                                      {item.title}
                                    </p>
                                    <FormControl
                                      fullWidth
                                      sx={{
                                        m: 0,
                                      }}
                                    >
                                      <Select
                                        value={precounGender}
                                        onChange={handleChangeGender}
                                        renderValue={(selected) => selected}
                                        sx={{
                                          borderRadius: "50px",
                                          border: 0.5,
                                          borderColor: "#F3F5F9",
                                        }}
                                        IconComponent={IconArrowDown}
                                      >
                                        {item.answer.map((_answer) => (
                                          <MenuItem
                                            key={_answer.order}
                                            value={_answer.title}
                                          >
                                            {precounGender === _answer.title ? (
                                              <IconCircleFill className="mr-2" />
                                            ) : (
                                              <IconCircle className="mr-2" />
                                            )}
                                            <ListItemText
                                              primary={_answer.title}
                                            />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : item.counseling_code === "CNS-00006" ? (
                                  <div className="mt-2">
                                    <p className="text-sm text-text-grey font-medium mb-2">
                                      {item.title}
                                    </p>
                                    <FormControl
                                      fullWidth
                                      sx={{
                                        m: 0,
                                      }}
                                    >
                                      <Select
                                        value={precounRelationship}
                                        onChange={handleChangeRelationship}
                                        renderValue={(selected) => selected}
                                        sx={{
                                          borderRadius: "50px",
                                          border: 0.5,
                                          borderColor: "#F3F5F9",
                                        }}
                                        IconComponent={IconArrowDown}
                                      >
                                        {item.answer.map((_answer) => (
                                          <MenuItem
                                            key={_answer.order}
                                            value={_answer.title}
                                          >
                                            {precounRelationship ===
                                            _answer.title ? (
                                              <IconCircleFill className="mr-2" />
                                            ) : (
                                              <IconCircle className="mr-2" />
                                            )}
                                            <ListItemText
                                              primary={_answer.title}
                                            />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : item.counseling_code === "CNS-00025" ? (
                                  <div className="mt-2">
                                    <p className="text-sm text-text-grey font-medium mb-2">
                                      {item.title}
                                    </p>
                                    <FormControl
                                      fullWidth
                                      sx={{
                                        m: 0,
                                      }}
                                    >
                                      <Select
                                        value={precounConsideration}
                                        onChange={handleChangeConsideration}
                                        multiple
                                        renderValue={(selected) =>
                                          selected.join(", ")
                                        }
                                        sx={{
                                          borderRadius: "50px",
                                          border: 0.5,
                                          borderColor: "#F3F5F9",
                                        }}
                                        IconComponent={IconArrowDown}
                                      >
                                        {item.answer.map((_answer) => (
                                          <MenuItem
                                            key={_answer.order}
                                            value={_answer.title}
                                          >
                                            {precounConsideration.indexOf(
                                              _answer.title
                                            ) > -1 ? (
                                              <IconSquareFill className="mr-2" />
                                            ) : (
                                              <IconSquare className="mr-2" />
                                            )}
                                            <ListItemText
                                              primary={_answer.title}
                                            />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : item.counseling_code === "CNS-00026" ? (
                                  <div className="mt-2">
                                    <p className="text-sm text-text-grey font-medium mb-2">
                                      {item.title}
                                    </p>
                                    <FormControl
                                      fullWidth
                                      sx={{
                                        m: 0,
                                      }}
                                    >
                                      <Select
                                        value={precounExpectation}
                                        onChange={handleChangeExpectation}
                                        multiple
                                        renderValue={(selected) =>
                                          selected.join(", ")
                                        }
                                        sx={{
                                          borderRadius: "50px",
                                          border: 0.5,
                                          borderColor: "#F3F5F9",
                                        }}
                                        IconComponent={IconArrowDown}
                                      >
                                        {item.answer.map((_answer) => (
                                          <MenuItem
                                            key={_answer.order}
                                            value={_answer.title}
                                          >
                                            {precounExpectation.indexOf(
                                              _answer.title
                                            ) > -1 ? (
                                              <IconSquareFill className="mr-2" />
                                            ) : (
                                              <IconSquare className="mr-2" />
                                            )}
                                            <ListItemText
                                              primary={_answer.title}
                                            />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                ) : item.counseling_code === "CNS-00007" ? (
                                  <div className="mt-2">
                                    <p className="text-sm text-text-grey font-medium mb-2">
                                      {item.title}
                                    </p>
                                    <textarea
                                      id="textarea"
                                      rows={5}
                                      value={precounConcern}
                                      onChange={(val) => {
                                        setPrecounConcern(val.target.value);
                                        checkIsDisabledPrecounseling();
                                      }}
                                      className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
                                      placeholder={item.hint}
                                    ></textarea>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : null}
                        <button
                          type="button"
                          className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full w-80 h-12 disabled:bg-slate-300"
                          disabled={isDisabledPrecounseling}
                          onClick={() => onSubmitPrecounseling()}
                        >
                          <p className="font-medium">
                            {isLoadingPrecounseling ? "Loading..." : "Submit"}
                          </p>
                          {isLoadingPrecounseling ? (
                            <div
                              className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                              role="status"
                            ></div>
                          ) : null}
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function bodySpecialist() {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
        <div className="md:col-span-2">
          <div className="flex items-end justify-between">
            <p>Status:</p>

            {dataDetailSession.status === "done" ? (
              <div className="px-4 py-2 rounded-lg bg-green-background">
                <p className="text-text-dark-green">Past</p>
              </div>
            ) : dataDetailSession.status === "cancelled_book" ? (
              <div className="px-4 py-2 rounded-lg bg-red-background">
                <p className="text-text-dark-red">Cancelled</p>
              </div>
            ) : (
              <div className="px-4 py-2 rounded-lg bg-blue-background">
                <p className="text-text-dark-blue">
                  {dataDetailSession.status === "rescheduled"
                    ? "Rescheduled"
                    : dataDetailSession.status === "request_reschedule"
                    ? "Rescheduled"
                    : hours === 0 && minutes < 0 && days === 0
                    ? "Current"
                    : "Booked"}
                </p>
              </div>
            )}
          </div>

          {hours === 0 && minutes < 0 && days === 0 ? (
            <div className="flex flex-col items-center w-fill mt-2">
              <p className="text-sm text-text-grey">Remaining Time:</p>
              <div className="bg-blue-background w-full py-4 m-auto text-center rounded-lg mt-2 text-xl text-text-dark-blue font-medium">
                {days + " : " + hours + " : " + minutes + " : " + seconds}
              </div>
            </div>
          ) : null}
        </div>

        <div>
          <div className="border border-custom-border rounded-lg p-4">
            <div key={dataDetailSession.reservation_code} className="w-full">
              <div className="w-12 float-left">
                <img
                  src={
                    config.AWS_URL_IMAGES +
                    dataDetailSession.psycholog_profile_url
                  }
                  className="w-10 h-10 rounded-full object-cover"
                  alt=""
                />
              </div>
              <div className="ml-2">
                <p className="font-medium text-sm">
                  {dataDetailSession.psycholog_username}
                </p>
                <p className="font-normal text-text-grey text-xs">
                  {CapitalizeFirstLetter(
                    dataDetailSession.psycholog_membership
                  )}
                </p>
              </div>
            </div>
            <Divider className="py-4" />
            <div>
              <p className="text-xs font-medium text-text-grey">Appointment</p>
              <p className="text-sm pt-1">
                {dataDetailSession.format_call === "video_call"
                  ? "Video Call 60 minutes"
                  : dataDetailSession.format_call === "voice_call"
                  ? "Voice Call 60 minutes"
                  : "Preview 15 minutes"}
              </p>
            </div>
            <div className="mt-2">
              <p className="text-xs font-medium text-text-grey">
                Date and Time
              </p>
              <p className="text-sm pt-1">
                {moment(
                  dataDetailSession.date.toString() +
                    ", " +
                    dataDetailSession.time_from
                ).format("MMMM D YYYY, hh:mm A")}
              </p>
            </div>
            <div className="mt-2">
              <p className="text-xs font-medium text-text-grey">Time Zone</p>
              <p className="text-sm pt-1">
                {dataDetailSession.member_timezone.title +
                  " (" +
                  dataDetailSession.member_timezone.zone +
                  getTimezone({
                    offset: dataDetailSession.member_timezone.offset,
                  }) +
                  ")"}
              </p>
            </div>
            <Divider className="py-4" />
            <div>
              {showDetailPayment ? (
                <div>
                  <p className="text-sm font-medium">Payment Detail</p>
                  <div className="flex items-center justify-between pt-1.5">
                    <p className="text-sm text-text-grey">Subtotal</p>
                    <p className="text-sm text-text-grey">
                      {formatRupiah(dataDetailSession.amount)}
                    </p>
                  </div>
                  <div className="flex items-center justify-between pt-1.5">
                    <p className="text-sm text-text-grey">Maxi Point</p>
                    <p className="text-sm text-text-grey">
                      {"-" + formatRupiah(dataDetailSession.point_used)}
                    </p>
                  </div>
                  <div className="flex items-center justify-between pt-1.5">
                    <p className="text-sm text-text-grey">Promo</p>
                    <p className="text-sm text-text-grey">
                      {"-" + formatRupiah(dataDetailSession.promo_used)}
                    </p>
                  </div>
                  <div className="flex items-center justify-between pt-1.5">
                    <p className="text-base">Total</p>
                    <p className="text-base">
                      {formatRupiah(
                        getTotalPayment(
                          dataDetailSession.amount,
                          dataDetailSession.point_used,
                          dataDetailSession.promo_used
                        )
                      )}
                    </p>
                  </div>
                  <div className="flex items-center justify-center pt-1.5">
                    <p
                      className="text-sm text-text-dark-blue cursor-pointer"
                      onClick={() => setShowDetailPayment(false)}
                    >
                      Hide Details
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-xs font-medium text-text-grey">
                    Payment Amount
                  </p>
                  <div className="flex items-center justify-between">
                    <p className="text-sm pt-1">
                      {formatRupiah(
                        getTotalPayment(
                          dataDetailSession.amount,
                          dataDetailSession.point_used,
                          dataDetailSession.promo_used
                        )
                      )}
                    </p>
                    <p
                      className="text-sm text-text-dark-blue cursor-pointer"
                      onClick={() => setShowDetailPayment(true)}
                    >
                      See Details
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {dataDetailSession.status === "done" ? (
            <div>
              <div
                className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer"
                onClick={() => {
                  if (dataOvertime) {
                    setShowModalOvertimeLink(true);
                  } else {
                    setShowModalOvertime(true);
                  }
                }}
              >
                {dataOvertime ? (
                  <div>
                    <p className="text-xs text-text-grey">
                      Harga Kelebihan Waktu
                    </p>
                    <p className="text-sm mt-1">
                      {dataOvertime.duration + " menit "}
                      <span className="text-text-dark-blue">
                        {"(" + formatRupiah(dataOvertime.price) + ")"}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-sm">Tambah Harga Kelebihan Waktu</p>
                  </div>
                )}
                <ArrowRight2 size="20" color="#292A2E" />
              </div>

              <div className="border border-custom-border rounded-lg p-4 mt-3">
                <p className="text-sm">Was this session helpful?</p>
                {dataReview !== null && dataReview !== undefined ? (
                  <div>
                    {dataReview.rating ? (
                      <div>
                        <div className="flex items-center mt-3">
                          <Like1 size="20" color="#00AC56" variant="Bold" />
                          <p className="text-text-success ml-1 text-sm font-medium">
                            found this helpful
                          </p>
                        </div>
                        <p className="text-text-grey mt-3 text-sm">
                          {dataReview.review}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center mt-3">
                          <Dislike size="20" color="#FF1F7D" variant="Bold" />
                          <p className="text-text-error ml-1 text-sm font-medium">
                            found this unhelpful
                          </p>
                        </div>
                        <p className="text-text-grey mt-3 text-sm">
                          {dataReview.review}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center mt-4">
                      <Like1
                        size="26"
                        color={reviewSession === 1 ? "#00AC56" : "#A0AAB8"}
                        variant={reviewSession === 1 ? "Bold" : "Linear"}
                        className="cursor-pointer"
                        onClick={() => setReviewSession(1)}
                      />
                      <span className="w-2"></span>
                      <Dislike
                        size="26"
                        color={reviewSession === 0 ? "#FF1F7D" : "#A0AAB8"}
                        variant={reviewSession === 0 ? "Bold" : "Linear"}
                        className="cursor-pointer"
                        onClick={() => setReviewSession(0)}
                      />
                    </div>
                    <textarea
                      defaultValue={reviewTextSession}
                      placeholder="Write down your feedback"
                      onChange={(val) => {
                        setReviewTextSession(val.target.value);
                        setBtnReviewDisabled(val.target.value === "");
                      }}
                      rows={5}
                      className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-lg mt-4 text-sm focus:outline-none focus:ring-1 focus:ring-custom-dark-blue resize-none"
                    />
                    <div className="w-full flex items-center justify-center">
                      <button
                        type="button"
                        disabled={isBtnReviewDisabled}
                        className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-auto px-8 h-12 mt-4 disabled:bg-slate-400	disabled:cursor-not-allowed"
                        onClick={() => onSubmitReview()}
                      >
                        <strong>
                          {isLoadingReview ? "Loading..." : "Send"}
                        </strong>
                        {isLoadingReview ? (
                          <div
                            className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          ></div>
                        ) : null}
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {dataReview !== null && dataReview !== undefined ? (
                <div>
                  {dataReview.rating ? (
                    <div>
                      <p className="text-center	text-xs text-text-grey mt-4 px-4">
                        Session not completed yet?
                      </p>
                      <p
                        className="text-center	text-xs text-text-dark-blue mt-1 px-4 cursor-pointer"
                        onClick={() => postUpdateStatus()}
                      >
                        Update Session status
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-center	text-xs text-text-grey mt-4 px-4">
                        Not satisfied with the session?
                      </p>
                      <p
                        className="text-center	text-xs text-text-dark-blue mt-1 px-4 cursor-pointer"
                        onClick={() => setShowRequestRefund(true)}
                      >
                        Request Refund
                      </p>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border border-custom-border rounded-lg p-4 mt-3">
                <p className="text-sm">Review</p>
                {dataReview !== null && dataReview !== undefined ? (
                  <div>
                    {reviewSession === 1 ? (
                      <Like1
                        size="26"
                        color={"#00AC56"}
                        variant={"Bold"}
                        className="cursor-pointer"
                        onClick={() => setReviewSession(1)}
                      />
                    ) : (
                      <Dislike
                        size="26"
                        color={"#FF1F7D"}
                        variant={"Bold"}
                        className="cursor-pointer"
                        onClick={() => setReviewSession(0)}
                      />
                    )}

                    <p className="text-text-grey mt-1 text-sm">
                      {dataReview.review}
                    </p>
                  </div>
                ) : (
                  <p className="text-xs text-text-grey">Belum Ada Review</p>
                )}
              </div>
            </div>
          ) : (
            <div>
              {dataDetailSession.room_link === "" ? (
                <div
                  className="flex items-center justify-between border border-custom-border rounded-full p-4 mt-3 cursor-pointer"
                  onClick={() => addToCalendar()}
                >
                  <IconCalendarGoogle />
                  <p className="text-sm font-medium">Tambah ke Kalender</p>
                  <span className="w-[20px]"></span>
                </div>
              ) : (
                <div className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer">
                  <div>
                    <p className="text-sm">Tautan Google Meet</p>{" "}
                    <p
                      className="text-sm text-text-dark-blue"
                      onClick={() => {
                        if (dataDetailSession.room_link)
                          onJoinGMeet(dataDetailSession.room_link);
                      }}
                    >
                      {dataDetailSession.room_link.replaceAll("https://", "")}
                    </p>
                  </div>
                  <IconCopy
                    onClick={() => {
                      navigator.clipboard.writeText(
                        dataDetailSession.room_link
                      );
                      toast.success("Tautan berhasil disalin");
                    }}
                  />
                </div>
              )}

              <div
                className="flex items-center justify-between border border-custom-border rounded-lg p-4 mt-3 cursor-pointer"
                onClick={() => {
                  if (dataOvertime) {
                    setShowModalOvertimeLink(true);
                  } else {
                    setShowModalOvertime(true);
                  }
                }}
              >
                {dataOvertime ? (
                  <div>
                    <p className="text-xs text-text-grey">
                      Harga Kelebihan Waktu
                    </p>
                    <p className="text-sm mt-1">
                      {dataOvertime.duration + " menit "}
                      <span className="text-text-dark-blue">
                        {"(" + formatRupiah(dataOvertime.price) + ")"}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-sm">Tambah Harga Kelebihan Waktu</p>
                  </div>
                )}
                <ArrowRight2 size="20" color="#292A2E" />
              </div>

              {dataDetailSession.status === "cancelled_book" ? (
                <div className="mt-4">
                  <p className="text-xs font-medium text-text-grey">
                    Cancellation Reason:
                  </p>
                  <p className="text-sm text-text-black mt-1">
                    {dataDetailSession.cancelled_book.cancel_reason ===
                      "Other reasons" ||
                    dataDetailSession.cancelled_book.cancel_reason ===
                      "Alasan lain"
                      ? dataDetailSession.cancelled_book.cancel_reason +
                        ": " +
                        dataDetailSession.cancelled_book.other_reason
                      : dataDetailSession.cancelled_book.cancel_reason}
                  </p>
                </div>
              ) : (
                <div>
                  {dataDetailSession.status === "request_reschedule" ? (
                    <div>
                      <div className="flex flex-col gap-1 mt-4">
                        <p className="text-xs text-neutral-secondary font-medium">
                          Alasan Permintaan Penjadwalan Ulang:
                        </p>
                        <p className="text-sm">
                          {dataDetailSession.reschedule_reason}
                        </p>
                      </div>
                      <div
                        className={`h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-4 ${
                          dataDetailSession.room_link
                            ? "bg-button-black"
                            : "bg-gray-200"
                        }`}
                        onClick={() => {
                          if (dataDetailSession.room_link)
                            onJoinGMeet(dataDetailSession.room_link);
                        }}
                      >
                        <IconPhone style={{ fill: "#fff" }} />
                        <p className="text-white ml-2">Gabung Google Meet</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        className={`flex flex-col gap-2 items-center border border-custom-border rounded-lg p-4 mt-3 cursor-pointer ${
                          hourReschedule < 6 && days > -1
                            ? "bg-button-disabled"
                            : ""
                        }`}
                      >
                        <div className="flex items-start justify-start w-full">
                          <Calendar
                            size="20"
                            color={`${
                              hourReschedule < 6 && days > -1
                                ? "#767E8C"
                                : "#1F1F21"
                            }`}
                            variant="Bold"
                          />
                          <p
                            className={`text-sm ml-2 ${
                              hourReschedule < 6 && days < 1
                                ? "text-text-grey"
                                : ""
                            }`}
                          >
                            Minta Penjadwalan Ulang
                          </p>
                        </div>
                        <p className="text-xs text-neutral-secondary">
                          Kami akan memberi tahu klien Anda tentang alasan Anda
                          menjadwal ulang.
                        </p>
                        <textarea
                          id="textarea"
                          rows={3}
                          value={rescheduleReason}
                          onChange={(val) =>
                            setRescheduleReason(val.target.value)
                          }
                          className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
                          placeholder={"Silakan jelaskan alasan Anda di sini."}
                        ></textarea>

                        <button
                          className={`bg-button-black rounded-full text-white px-20 h-10 text-sm disabled:bg-slate-300`}
                          disabled={
                            rescheduleReason === "" || hourReschedule < 24
                          }
                          onClick={() => submitRequestReschedule()}
                        >
                          <p>Kirim Permintaan</p>
                        </button>
                      </div>

                      <p className="text-center	text-xs text-text-grey mt-4 px-4">
                        Catatan: Sesi hanya bisa dijadwalkan ulang paling lambat
                        18 jam sebelum sesi dimulai.
                      </p>
                      <div
                        className={`h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-4 ${
                          dataDetailSession.room_link
                            ? "bg-button-black"
                            : "bg-gray-200"
                        }`}
                        onClick={() => {
                          if (dataDetailSession.room_link)
                            onJoinGMeet(dataDetailSession.room_link);
                        }}
                      >
                        <IconPhone style={{ fill: "#fff" }} />
                        <p className="text-white ml-2">Gabung Google Meet</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <Divider className="md:hidden py-4" />

        {dataDetailSession.status === "cancelled_book" ? (
          <div></div>
        ) : (
          <div>
            {dataDetailSession.status === "done" ? (
              <div className="h-fit border border-custom-border rounded-lg p-4">
                <p className="text-base font-medium">Client Progress Notes</p>

                {dataClientNotes !== null && dataClientNotes !== undefined ? (
                  <div>
                    <div className="flex items-center mt-2">
                      {dataClientNotes.red_flag ? (
                        <IconSquareFill />
                      ) : (
                        <IconSquare />
                      )}
                      <p className="text-sm ml-2">Need further consultation</p>
                    </div>

                    <p className="text-sm font-medium text-text-grey mt-4">
                      Description of issues
                    </p>
                    <p className="text-sm text-text-black mt-1">
                      {dataClientNotes.description_issues}
                    </p>

                    <p className="text-sm font-medium text-text-grey mt-4">
                      Assessment of situation
                    </p>
                    <p className="text-sm text-text-black mt-1">
                      {dataClientNotes.assessment_situation}
                    </p>

                    <p className="text-sm font-medium text-text-grey mt-4">
                      Plans for follow up
                    </p>
                    <p className="text-sm text-text-black mt-1">
                      {dataClientNotes.plan_followup}
                    </p>

                    {dataClientNotesFile !== null &&
                    dataClientNotesFile !== undefined ? (
                      <div>
                        <p className="text-sm font-medium text-text-grey mt-4">
                          Attachment
                        </p>
                        <div className="flex flex-col gap-2 mt-2">
                          {dataClientNotesFile.map((val, i) => {
                            return (
                              <div className="flex items-center justify-around gap-2">
                                <IconFile width={20} height={20} />
                                <p className="w-full text-sm">
                                  {"File " +
                                    (i + 1) +
                                    "." +
                                    val.file_url
                                      .split("/")
                                      .pop()
                                      .split(".")
                                      .pop()}
                                </p>
                                <p
                                  onClick={() => downloadFile(val.file_url)}
                                  className="text-sm font-medium text-custom-dark-blue cursor-pointer"
                                >
                                  Download
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <p className="mt-2 text-sm text-text-grey">No Note Yet</p>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {dataPrecounseling !== undefined &&
                dataPrecounselingForm !== undefined ? (
                  <div>
                    {dataPrecounseling.answers !== null ? (
                      <div className="border border-custom-border rounded-lg p-4">
                        <p>Pre Counseling Form</p>
                        <div>
                          {dataPrecounseling.answers.map((item, i) => (
                            <div key={i} className="py-1">
                              <p className="text-sm text-text-grey mt-2">
                                {item.title}
                              </p>
                              <p className="text-sm text-text-black mt">
                                {item.answer}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="border border-custom-border rounded-lg p-4">
                        <p className="text-base font-medium">
                          Pre Counseling Form
                        </p>
                        <p className="text-sm text-neutral-secondary">
                          Klien belum mengisi Pre Counseling Form
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
