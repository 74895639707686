import * as LocalStorage from "./localStorage";

export const calendlyUrl = "https://calendly.com/hariadi-tjandra/founder-call";

export const RE_DIGIT = new RegExp(/^\d+$/);

export const onChat = () => {
  window.open("https://api.whatsapp.com/send/?phone=628885591188", "_blank");
};

export const onDownloadApp = () => {
  return window.open("/download-now", "_blank");
};

export const onViewCalendar = () => {
  return window.open("https://calendar.google.com", "_blank");
};

export const onJoinGMeet = (link) => {
  return window.open(link, "_blank");
};

export { LocalStorage };
