import { Video } from "iconsax-react";
import { ReactComponent as IconHome } from "assets/icons/ic_home.svg";

export function CardLocation(props: {
  title: string;
  choosed: boolean;
  isOnline?: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={`flex gap-2 items-center justify-center cursor-pointer border rounded-lg py-3 ${
        props.choosed
          ? "bg-blue-background border-black-app"
          : "border-neutral-200"
      }`}
      onClick={props.onClick}
    >
      {props.isOnline ? (
        <Video className="w-5 md:w-8" variant="Bold" />
      ) : (
        <IconHome className="w-8 h-8" />
      )}
      <p className="text-xs md:text-sm font-medium">{props.title}</p>
    </div>
  );
}
