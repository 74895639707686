import { ReactComponent as IconCircleFill } from "assets/icons/ic_check_circle_fill.svg";
import { ReactComponent as IconCircle } from "assets/icons/ic_check_circle.svg";
import { useEffect, useState } from "react";

export default function YOE(props: {
  data;
  choosedData;
  onSave: (newData) => void;
}) {
  const onSubmit = async () => {
    props.onSave(dataChoosed);
  };

  const onChoosed = async (code) => {
    setDataChoosed(code);
  };

  const entries = Object.entries(props.data);

  const [dataChoosed, setDataChoosed] = useState("");

  useEffect(() => {
    setDataChoosed(props.choosedData);
  }, [props.choosedData]);

  return (
    <div className="w-full p-4 m-4 bg-white rounded-lg">
      <label
        htmlFor="message"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Tahun Pengalaman Saya
      </label>

      <div className="divide-y divide-custom-white max-h-[500px] overflow-y-auto	">
        {entries.map(([key, value]: [string, any]) => (
          <div
            key={key}
            className="flex items-center py-4 cursor-pointer"
            onClick={() => onChoosed(key)}
          >
            {key === dataChoosed ? <IconCircleFill /> : <IconCircle />}
            {
              <p className="ml-3 hover:text-text-dark-blue">
                {value.toString()}
              </p>
            }
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center ">
        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full w-80 h-12"
          onClick={() => onSubmit()}
        >
          <strong>{"Simpan"}</strong>
        </button>
      </div>
    </div>
  );
}
