import { ReactComponent as IconVerify } from "assets/icons/ic_verify.svg";
import { ReactComponent as IconOvertime } from "assets/icons/ic_overtime.svg";
import { ReactComponent as IconBriefcase } from "assets/icons/ic_briefcase.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { Carousel } from "@material-tailwind/react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/id";
import { ReactComponent as IconNoSession } from "assets/icons/ic_no_session.svg";
import {
  AddCircle,
  ArrowRight2,
  Calendar,
  Dislike,
  DocumentText,
  Like1,
} from "iconsax-react";
import { ReactComponent as IcMenuAddSession } from "assets/icons/ic_menu_add_session.svg";
import { ReactComponent as IcMenuNextSession } from "assets/icons/ic_menu_next_session.svg";
import { ReactComponent as IcMenuRefer } from "assets/icons/ic_menu_refer.svg";
import { useEffect, useRef, useState } from "react";
import {
  addReview,
  getSessionCalendar,
  getSessionCalendarSpecialist,
  getSessionDaily,
  getSessionDailySpecialist,
  lastReservation,
  nextReservation,
} from "services/session";
import Badge from "@mui/material/Badge";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import moment from "moment";
import config from "helpers/config";
import { useQuery } from "react-query";
import {
  getRoleCookies,
  getTokenAuth,
  getUserdata,
  setUserData,
} from "helpers/localStorage";
import { getTransaction } from "services/transaction";
import { CapitalizeFirstLetter, formatRupiah, getScheduleByDay } from "utils";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { checkCurrentTime } from "helpers/date";
import Rating from "@mui/material/Rating";
import ModalRefer from "./content/modalRefer";
import ModalReferSuccess from "./content/modalReferSuccess";
import { fetchSchedule } from "services/schedule";
import { toast } from "react-toastify";
import { getProfile } from "services/profile";
import Chip from "components/Chip";
import { ListSession } from "components/ListSession";
import ModalCreateSession from "./content/modalCreateSession";
import ModalSessionLink from "./content/modalSessionLink";

export default function Home() {
  const navigate = useNavigate();
  const initialValue = dayjs(new Date().toLocaleDateString());
  const requestAbortController = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [value, setValue] = useState<Dayjs>(
    dayjs(new Date().toLocaleDateString())
  );

  useEffect(() => {
    fetchHighlightedDays(dayjs(new Date().toLocaleDateString()));
    fetchDaily(dayjs(new Date().toLocaleDateString()));
    getSchedule();
    fetchProfile();
    // fetchNextSession();
  }, []);

  const [profile, setProfile] = useState<any>();
  const fetchProfile = () => {
    getProfile()
      .then((val) => {
        var _profile = val.data;
        setProfile(_profile);
        setUserData({
          memberCode: _profile.member_code,
          username: _profile.username,
          email: _profile.email,
          profile_url: _profile.profile_url,
          role_name: _profile.role_name,
        });
      })
      .catch((err) => {
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date.add(1, "day"));
  };

  const fetchHighlightedDays = (date: Dayjs) => {
    if (getRoleCookies() === "member" || getRoleCookies() === "member_org") {
      return getSessionCalendar({
        year: date.year().toString(),
        month: (date.month() + 1).toString(),
      }).then((res) => {
        var tifOptions: number[] = [];

        for (let index = 0; index < res.data.activities.length; index++) {
          if (res.data.activities[index].counseling.count > 0) {
            tifOptions.push(res.data.activities[index].date);
          }
        }

        setIsLoading(false);
        setHighlightedDays(tifOptions);
      });
    } else {
      return getSessionCalendarSpecialist({
        year: date.year().toString(),
        month: (date.month() + 1).toString(),
      }).then((res) => {
        var tifOptions: number[] = [];

        for (let index = 0; index < res.data.activities.length; index++) {
          if (res.data.activities[index].counseling.count > 0) {
            tifOptions.push(res.data.activities[index].date);
          }
        }

        setIsLoading(false);
        setHighlightedDays(tifOptions);
      });
    }
  };

  function ServerDay(
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
  ) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        sx={
          isSelected
            ? {
                "& .MuiBadge-badge": {
                  width: 36,
                  height: 36,
                  borderRadius: 30,
                  mt: 1.6,
                  mr: 1.8,
                  backgroundColor: "#8BD2F038",
                  zIndex: -10,
                },
              }
            : {}
        }
        overlap="circular"
        // variant="dot"
        badgeContent={isSelected ? "" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  const [dataSchedule, setDataSchedule] = useState<any>();
  const getSchedule = async () => {
    fetchSchedule({ lang: "id" })
      .then((res) => {
        setDataSchedule(res.data);
      })
      .catch((err) => {
        // toast.
      });
  };

  const [dataDaily, setDataDaily] = useState<any[]>([]);
  const fetchDaily = async (date: Dayjs) => {
    if (getRoleCookies() === "member" || getRoleCookies() === "member_org") {
      const res = await getSessionDaily({
        date: moment(date.toString()).format("YYYY-MM-DD"),
        lang: "id",
        types: "counseling",
      });
      setDataDaily(res.data);
    } else {
      const res = await getSessionDailySpecialist({
        date: moment(date.toString()).format("YYYY-MM-DD"),
        lang: "id",
        types: "counseling",
      });
      setDataDaily(res.data);
    }
  };

  const onGetTransaction = () => {
    if (getTokenAuth() === null) {
      return () => null;
    }

    return () => {
      try {
        return getTransaction({ lang: "id", keyword: "", limit: "6" }).then(
          (res) => res.data
        );
      } catch (error) {
        alert(error);
      }
    };
  };

  const queryTransaction = useQuery("getTransaction", onGetTransaction());
  const dataTransaction = queryTransaction.data;

  const onGetLastReservation = () => {
    return () => {
      try {
        return lastReservation({ lang: "id" }).then((res) => res.data);
      } catch (error) {
        alert(error);
      }
    };
  };

  const queryLastReservation = useQuery(
    "getLastReservation",
    onGetLastReservation()
  );
  const dataLastTransaction = queryLastReservation.data;
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);
  const [minutes, setMinutes] = useState(0);
  useEffect(() => {
    if (dataLastTransaction !== undefined) {
      const interval = setInterval(() => {
        setDays(
          checkCurrentTime({
            date: dataLastTransaction.date + " " + dataLastTransaction.time_to,
          }).days()
        );
        setHours(
          checkCurrentTime({
            date: dataLastTransaction.date + " " + dataLastTransaction.time_to,
          }).hours()
        );
        setMinutes(
          checkCurrentTime({
            date: dataLastTransaction.date + " " + dataLastTransaction.time_to,
          }).minutes()
        );
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [dataLastTransaction]);

  const onGetNextReservation = () => {
    return () => {
      try {
        return nextReservation({ lang: "id" }).then((res) => res.data);
      } catch (error) {
        alert(error);
      }
    };
  };

  const queryNextReservation = useQuery(
    "getNextReservation",
    onGetNextReservation()
  );
  const dataNextTransaction = queryNextReservation.data;

  // Modal
  const [isShowModalRefer, setShowModalRefer] = useState(false);
  const [isShowModalReferSuccess, setShowModalReferSuccess] = useState(false);

  const submitReferClient = () => {
    setShowModalRefer(false);
    setShowModalReferSuccess(true);
  };

  const [isShowModalCreateSession, setShowModalCreateSession] = useState(false);
  const [isShowModalSessionLink, setShowModalSessionLink] = useState(false);
  const [sessionLink, setSessionLink] = useState<any>();

  const onCreateSession = async (val: any) => {
    var data = {
      psycholog_username: profile.username,
      psycholog_code: profile.member_code,
      schedule_detail_code: val.schedule.schedule_detail_code,
      time_from: val.schedule.time_from,
      format_call: val.format_call,
      location_code: val.location.location_code,
      date: val.date,
    };

    setShowModalCreateSession(false);
    setShowModalSessionLink(true);
    setSessionLink(data);
  };

  const onSubmitReview = async (reservationCode: string, isLike: boolean) => {
    addReview({
      code: reservationCode,
      review: "",
      rating: isLike ? true : false,
    })
      .then((res) => {
        toast.success("Berhasil mereview");
      })
      .catch((err) => {
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  return (
    <div className="p-6">
      <div className="md:grid md:grid-cols-3 md:grid-rows-6 md:grid-flow-col gap-4 mt-2">
        {getRoleCookies() === "member" || getRoleCookies() === "member_org" ? (
          <div className="col-span-2">
            <h1 className="text-xl font-semibold">Janji Saya</h1>
            <>
              {dataLastTransaction !== undefined ? (
                dataLastTransaction !== null ? (
                  <HaveLastTransaction
                    dataTransaction={dataLastTransaction}
                    nextTransaction={dataNextTransaction}
                    days={days}
                    minutes={minutes}
                    hour={hours}
                    onSubmit={(code, isLike) => onSubmitReview(code, isLike)}
                  />
                ) : (
                  <NoLastTransaction nextTransaction={dataNextTransaction} />
                )
              ) : (
                <NoLastTransaction nextTransaction={dataNextTransaction} />
              )}
            </>
          </div>
        ) : (
          <div className="col-span-2">
            <h1 className="text-xl font-semibold">Quick Action</h1>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-3">
              <div
                className="bg-[#F4FBFE] col-span-1 py-4 md:py-10 px-4 rounded-lg flex items-center justify-center cursor-pointer"
                onClick={() => setShowModalCreateSession(true)}
              >
                <IcMenuAddSession
                  style={{ fill: "#167CB8" }}
                  width={30}
                  className="mr-2"
                />
                <p>Tambah Sesi</p>
              </div>
              {dataNextTransaction !== undefined ? (
                dataNextTransaction !== null ? (
                  <HaveNextTransaction
                    dataNextTransaction={dataNextTransaction}
                  />
                ) : (
                  <NoNextTransaction />
                )
              ) : (
                <NoNextTransaction />
              )}
              <div
                className="col-span-2 md:col-span-1"
                onClick={() => setShowModalRefer(true)}
              >
                <div className="bg-[#F4FBFE] col-span-1 py-4 md:py-10 px-4 rounded-lg flex items-center justify-center cursor-pointer">
                  <IcMenuRefer
                    style={{ fill: "#167CB8" }}
                    width={30}
                    className="mr-2"
                  />
                  <p>Refer Klien</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="md:row-span-5 md:col-span-2 ">
          <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mt-4 md:mt-0">
            {getRoleCookies() !== "member" &&
            getRoleCookies() !== "member_org" ? (
              <div className="">
                <p>Ketersediaan Saya</p>
                <div className="border rounded-lg mt-4">
                  <div className="w-full bg-[#c4e8f7] h-[50px] grid grid-cols-5 items-center px-4 rounded-t-lg">
                    <p className="text-base col-span-2">Hari</p>
                    <p className="text-base col-span-3">Ketersediaan</p>
                  </div>

                  <div className="w-full bg-white py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
                    <p className="text-sm col-span-2">Senin</p>
                    <p className="text-sm text-neutral-secondary col-span-3">
                      {getScheduleByDay("Monday", dataSchedule ?? null)
                        .split("\n")
                        .map(function (item: any, idx: any) {
                          return (
                            <span key={idx}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </p>
                  </div>

                  <div className="w-full bg-[#f5fbfe] py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
                    <p className="text-sm col-span-2">Selasa</p>
                    <p className="text-sm text-neutral-secondary col-span-3">
                      {getScheduleByDay("Tuesday", dataSchedule ?? null)
                        .split("\n")
                        .map(function (item: any, idx: any) {
                          return (
                            <span key={idx}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </p>
                  </div>

                  <div className="w-full bg-white py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
                    <p className="text-sm col-span-2">Rabu</p>
                    <p className="text-sm text-neutral-secondary col-span-3">
                      {getScheduleByDay("Wednesday", dataSchedule ?? null)
                        .split("\n")
                        .map(function (item: any, idx: any) {
                          return (
                            <span key={idx}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </p>
                  </div>

                  <div className="w-full bg-[#f5fbfe] py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
                    <p className="text-sm col-span-2">Kamis</p>
                    <p className="text-sm text-neutral-secondary col-span-3">
                      {getScheduleByDay("Thursday", dataSchedule ?? null)
                        .split("\n")
                        .map(function (item: any, idx: any) {
                          return (
                            <span key={idx}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </p>
                  </div>

                  <div className="w-full bg-white py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
                    <p className="text-sm col-span-2">Jumat</p>
                    <p className="text-sm text-neutral-secondary col-span-3">
                      {getScheduleByDay("Friday", dataSchedule ?? null)
                        .split("\n")
                        .map(function (item: any, idx: any) {
                          return (
                            <span key={idx}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </p>
                  </div>

                  <div className="w-full bg-[#f5fbfe] py-3 min-h-[50px] grid grid-cols-5 items-start px-4">
                    <p className="text-sm col-span-2">Sabtu</p>
                    <p className="text-sm text-neutral-secondary col-span-3">
                      {getScheduleByDay("Saturday", dataSchedule ?? null)
                        .split("\n")
                        .map(function (item: any, idx: any) {
                          return (
                            <span key={idx}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </p>
                  </div>

                  <div className="w-full bg-white py-3 min-h-[50px] grid grid-cols-5 items-start px-4 rounded-b-lg">
                    <p className="text-sm col-span-2">Minggu</p>
                    <p className="text-sm text-neutral-secondary col-span-3">
                      {getScheduleByDay("Sunday", dataSchedule ?? null)
                        .split("\n")
                        .map(function (item: any, idx: any) {
                          return (
                            <span key={idx}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            <div>
              <div className="flex items-center justify-between">
                <p>Sesi Saya</p>
                <span
                  onClick={() => navigate("../session")}
                  className="flex items-center cursor-pointer"
                >
                  <p className="text-xs text-text-dark-blue">Lihat </p>
                  <ArrowRight2 size="12" color="#167CB8" />
                </span>
              </div>
              <div className="border border-custom-neutral rounded-lg px-4 pb-4 mt-4">
                <LocalizationProvider
                  adapterLocale="id"
                  dateAdapter={AdapterDayjs}
                >
                  <DateCalendar
                    views={["day"]}
                    defaultValue={initialValue}
                    loading={isLoading}
                    onMonthChange={handleMonthChange}
                    className="w-[290px] m-0"
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{
                      day: ServerDay,
                    }}
                    sx={{
                      width: 1,
                      mb: 3,
                      "& .MuiPickersFadeTransitionGroup-root ": {
                        width: 1,
                        display: "",
                      },
                      "& .MuiPickersCalendarHeader-root  ": {
                        padding: 0,
                      },
                      "& .MuiDayCalendar-header ": {
                        width: 1,
                        justifyContent: "space-between",
                      },
                      "& .MuiPickersSlideTransition-root ": {
                        width: 1,
                        display: "",
                      },
                      "& .MuiDayCalendar-weekContainer ": {
                        width: 1,
                        justifyContent: "space-between",
                      },
                    }}
                    slotProps={{
                      day: {
                        highlightedDays,
                      } as any,
                    }}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue!);
                      fetchDaily(newValue!);
                    }}
                  />
                </LocalizationProvider>
                <p className="text-base font-medium">
                  {moment(value.toString()).format("DD MMM YYYY")}
                </p>
                <div className="divide-y divide-custom-white">
                  {dataDaily !== undefined
                    ? dataDaily !== null
                      ? dataDaily.map((item, i) => (
                          <ListSession value={value} item={item} />
                        ))
                      : null
                    : null}
                </div>
              </div>
            </div>
            {getRoleCookies() === "member" ||
            getRoleCookies() === "member_org" ? (
              <div>
                <div className="flex items-center justify-between">
                  <p>Transaksi Saya</p>
                  <span
                    onClick={() => navigate("../transaction")}
                    className="flex items-center cursor-pointer"
                  >
                    <p className="text-xs text-text-dark-blue">Lihat </p>
                    <ArrowRight2 size="12" color="#167CB8" />
                  </span>
                </div>
                <div className="border border-custom-neutral rounded-lg px-4 pb-4 mt-4">
                  <div className="divide-y divide-custom-white">
                    {dataTransaction !== undefined ? (
                      dataTransaction !== null ? (
                        dataTransaction.map((item, i) => (
                          <Link to={`../transaction/${item.reservation_code}`}>
                            <div key={item.order_code} className="py-3">
                              <div className="flex items-start justify-between mb-2">
                                <p className="font-medium text-sm">
                                  {"Konsultasi (" + item.title + ")"}
                                </p>
                                <p className="font-medium text-xs min-w-[100px] text-end text-neutral-secondary">
                                  {moment(item.created_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="flex items-start justify-between">
                                <p className="font-medium text-base text-brand-dark">
                                  {formatRupiah(item.amount)}
                                </p>
                                <div
                                  className={
                                    "rounded-md items-center " +
                                    (item.order_refund !== null
                                      ? "bg-transaction-refund"
                                      : item.status === "completed"
                                      ? "bg-transaction-success"
                                      : item.status === "failed" ||
                                        item.status === "cancelled" ||
                                        item.status === "cancelled_book"
                                      ? "bg-transaction-cancel"
                                      : item.status === "expired"
                                      ? "bg-transaction-expired"
                                      : "bg-transaction-pending")
                                  }
                                >
                                  <p
                                    className={
                                      "font-normal px-2 py-1 text-xs text-center text-neutral-secondary " +
                                      (item.order_refund !== null
                                        ? "text-transaction-refund-text"
                                        : item.status === "completed"
                                        ? "text-transaction-success-text"
                                        : item.status === "failed" ||
                                          item.status === "cancelled" ||
                                          item.status === "cancelled_book"
                                        ? "text-transaction-cancel-text"
                                        : item.status === "expired"
                                        ? "text-transaction-expired-text"
                                        : "text-transaction-pending-text")
                                    }
                                  >
                                    {item.order_refund !== null
                                      ? "Permintaan Pengembalian Dana"
                                      : item.status === "completed"
                                      ? "Pembayaran Berhasil"
                                      : item.status === "failed" ||
                                        item.status === "cancelled" ||
                                        item.status === "cancelled_book"
                                      ? "Pembayaran Dibatalkan"
                                      : item.status === "expired"
                                      ? "Pembayaran Kedaluwarsa"
                                      : "Menunggu Pembayaran"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))
                      ) : (
                        <div className="w-full flex items-center justify-center text-center mt-[80px] mb-[80px]">
                          <div className="">
                            <IconNoSession />
                            <p className="text-text-grey text-xs">
                              Belum Ada Transaksi
                            </p>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <>
          {getRoleCookies() === "member" ||
          getRoleCookies() === "member_org" ? (
            <div className="md:row-span-6 bg-background-neutral px-8 py-10 rounded-md content-center mt-4 md:mt-0">
              <p className="text-2xl text-center">
                Want to access more resources?
              </p>
              <div className="grid w-56 place-items-center overflow-x-scroll rounded-lg p-6 mx-auto lg:overflow-visible">
                <Carousel
                  className="rounded-xl"
                  autoplay
                  loop
                  prevArrow={() => <div></div>}
                  nextArrow={() => <div></div>}
                  navigation={({ setActiveIndex, activeIndex, length }) => (
                    <div className="absolute h-auto bottom-0 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                      {new Array(length).fill("").map((_, i) => (
                        <span
                          key={i}
                          className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                            activeIndex === i
                              ? "bg-neutral-black w-4"
                              : "bg-custom-white w-2"
                          }`}
                          onClick={() => setActiveIndex(i)}
                        />
                      ))}
                    </div>
                  )}
                >
                  <div className="h-full pb-8">
                    <img
                      src="/images/img_home_1.png"
                      alt=""
                      className="w-full h-full inline-block flex-none"
                    />
                  </div>

                  <div className="h-full pb-8">
                    <img
                      src="/images/img_home_2.png"
                      alt=""
                      className="w-full h-full inline-block flex-none"
                    />
                  </div>

                  <div className="h-full pb-8">
                    <img
                      src="/images/img_home_3.png"
                      alt=""
                      className="w-full h-full inline-block flex-none"
                    />
                  </div>

                  <div className="h-full pb-8">
                    <img
                      src="/images/img_home_4.png"
                      alt=""
                      className="w-full h-full inline-block flex-none"
                    />
                  </div>

                  <div className="h-full pb-8">
                    <img
                      src="/images/img_home_5.png"
                      alt=""
                      className="w-full h-full inline-block flex-none"
                    />
                  </div>

                  <div className="h-full pb-8">
                    <img
                      src="/images/img_home_6.png"
                      alt=""
                      className="w-full h-full inline-block flex-none"
                    />
                  </div>
                  <div className="h-full pb-8">
                    <img
                      src="/images/img_home_7.png"
                      alt=""
                      className="w-full h-full inline-block flex-none"
                    />
                  </div>
                </Carousel>
              </div>
              <h2 className="text-base font-medium text-center">
                Download Maxi App now and start your happiness journey
              </h2>
              <div className="grid grid-cols-2 gap-2 mt-4">
                <img
                  src="/images/img_download_playstore.png"
                  alt=""
                  className="col-span-1"
                />
                <img
                  src="/images/img_download_appstore.png"
                  alt=""
                  className="col-span-1"
                />
              </div>
            </div>
          ) : (
            <div className="md:row-span-6 mt-4 md:mt-0">
              <div className="flex items-center justify-between">
                <p>Profil Saya</p>
                <span
                  onClick={() => navigate("../profile")}
                  className="flex items-center cursor-pointer"
                >
                  <p className="text-xs text-text-dark-blue">Edit </p>
                  <ArrowRight2 size="12" color="#167CB8" />
                </span>
              </div>
              <div className=" bg-white border p-4 rounded-md content-center mt-4">
                <>
                  {profile ? (
                    <>
                      <div className="w-full flex flex-col items-center">
                        <img
                          src={config.AWS_URL_IMAGES + profile.profile_url}
                          className="w-[100px] h-[100px] border rounded-full object-center object-cover"
                          alt=""
                        />
                        <span className="flex items-center gap-2">
                          <p className="text-base font-medium">
                            {profile.username}
                          </p>
                          <IconVerify width={20} height={20} />
                        </span>
                        <p className="text-sm text-neutral-secondary">
                          {profile.specialist_title}
                        </p>
                      </div>
                      <div className="border-t border-b py-4 grid grid-cols-9 mt-4">
                        <span className="flex items-center justify-center col-span-4 gap-2">
                          <Like1
                            size="26"
                            color={"#A0AAB8"}
                            variant={"Linear"}
                            className="cursor-pointer"
                          />
                          <p>5.0</p>
                        </span>
                        <span className="h-10 bg-slate-300 w-[1px] mx-auto" />
                        <span className="flex items-center justify-center col-span-4 gap-2">
                          <IconBriefcase width={20} height={20} />
                          <p>
                            {profile.year_of_experience
                              .replaceAll("Years", "Tahun")
                              .replaceAll("Year", "Tahun")}
                          </p>
                        </span>
                      </div>
                      <div className="mt-4">
                        <p className="text-base">Tentang Saya</p>
                        <p className="text-sm text-neutral-secondary mt-2">
                          {profile.bio
                            .split("\n")
                            .map(function (item: any, idx: any) {
                              return (
                                <span key={idx}>
                                  {item}
                                  <br />
                                </span>
                              );
                            })}
                        </p>

                        {profile.target_users ? (
                          <>
                            <p className="text-base mt-3">Tipe Klien</p>
                            <div className="flex gap-2 flex-wrap mt-2">
                              {profile.target_users.map((val: any) => {
                                return (
                                  <Chip key={val.target_code}>{val.name}</Chip>
                                );
                              })}
                            </div>
                          </>
                        ) : null}

                        {profile.expertises ? (
                          <>
                            <p className="text-base mt-3">Expertise Saya</p>
                            <div className="flex gap-2 flex-wrap mt-2">
                              {profile.expertises.map((val: any) => {
                                return (
                                  <Chip key={val.expertise_code}>
                                    {val.name}
                                  </Chip>
                                );
                              })}
                            </div>
                          </>
                        ) : null}

                        {profile.approaches ? (
                          <>
                            <p className="text-base mt-3">Approach Saya</p>
                            <div className="flex gap-2 flex-wrap mt-2">
                              {profile.approaches.map((val: any) => {
                                return (
                                  <Chip key={val.approach_code}>
                                    {val.name}
                                  </Chip>
                                );
                              })}
                            </div>
                          </>
                        ) : null}

                        {profile.universities &&
                        profile.universities.length > 0 ? (
                          <>
                            <p className="text-base mt-3">Edukasi</p>
                            <div className="flex flex-col gap-2 mt-2">
                              {(profile.universities ?? []).map(
                                (val: any, i: number) => {
                                  return (
                                    <div key={i}>
                                      <div className="font-medium">
                                        {val.university}
                                      </div>
                                      <div className="flex gap-3 items-center text-neutral-secondary">
                                        <div>{val.graduate_year}</div>
                                        <div className="bg-neutral-300 h-2 w-2 rounded-full"></div>
                                        <div>{val.degree}</div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        ) : null}

                        {profile.licenses && profile.licenses.length > 0 ? (
                          <>
                            <p className="text-base mt-3">Lisensi</p>
                            <div className="flex flex-col gap-2 mt-2">
                              {(profile.licenses ?? []).map(
                                (val: any, i: number) => {
                                  return (
                                    <div className="flex" key={i}>
                                      <DocumentText variant="Bold" />
                                      <div className="ml-5">{`${val.license_name} - ${val.license_number}`}</div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        ) : null}

                        {profile.language ? (
                          <>
                            <p className="text-base mt-3">Bahasa</p>
                            <div className="flex gap-2 flex-wrap mt-2">
                              {profile.language.map((val: string) => {
                                return <Chip key={val}>{val}</Chip>;
                              })}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </>
              </div>
            </div>
          )}
        </>
      </div>

      {profile &&
      getRoleCookies() !== "member" &&
      getRoleCookies() !== "member_org" ? (
        <ModalCreateSession
          profile={profile}
          isShow={isShowModalCreateSession}
          hide={() => setShowModalCreateSession(false)}
          onSubmit={(val) => onCreateSession(val)}
        ></ModalCreateSession>
      ) : null}

      {getRoleCookies() !== "member" && getRoleCookies() !== "member_org" ? (
        <ModalSessionLink
          value={sessionLink}
          isShow={isShowModalSessionLink}
          hide={() => setShowModalSessionLink(false)}
        ></ModalSessionLink>
      ) : null}

      {getRoleCookies() !== "member" && getRoleCookies() !== "member_org" ? (
        <ModalRefer
          isShow={isShowModalRefer}
          onSubmit={submitReferClient}
          toggle={() => setShowModalRefer(false)}
        ></ModalRefer>
      ) : null}

      {getRoleCookies() !== "member" && getRoleCookies() !== "member_org" ? (
        <ModalReferSuccess
          isShow={isShowModalReferSuccess}
          toggle={() => setShowModalReferSuccess(false)}
        ></ModalReferSuccess>
      ) : null}
    </div>
  );
}

export function HaveLastTransaction(props: {
  dataTransaction: any;
  nextTransaction: any;
  hour: any;
  minutes: any;
  days: any;
  onSubmit: (code, isLike) => void;
}) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:grid md:grid-cols-2 md:col-span-2 gap-2">
      <div className=" flex flex-col bg-[#FAFBFD] p-4 justify-center">
        {props.dataTransaction.overtime_status === "overtime_pending" ? (
          <div className="bg-transaction-cancel flex justify-center items-center gap-2 px-2 py-2 rounded-lg mb-3">
            <IconOvertime />
            <p className="text-sm text-text-dark-red">
              Pending Overtime Payment
            </p>
          </div>
        ) : null}
        <div
          className=" col-span-1 h-auto rounded-lg flex items-start cursor-pointer"
          onClick={() => {
            navigate(
              `../session/${props.dataTransaction.reservation_code}/${moment(
                props.dataTransaction.date.toString()
              ).format("yyyy-MM-DD")}`
            );
          }}
        >
          <img
            src={
              config.AWS_URL_IMAGES +
              props.dataTransaction?.psycholog_profile_url
            }
            className="w-[40px] h-[40px] min-w-[40px] rounded-full object-cover mr-4"
            alt=""
          />
          <div className="flex flex-auto justify-between gap-2">
            <div className="flex flex-col items-start">
              <p className="text-sm font-medium">
                {props.dataTransaction.psycholog_username}
              </p>
              <p className="text-xs text-custom-grey">
                {props.dataTransaction.psycholog_title}
              </p>
              {props.dataTransaction.status === "done" ? null : (
                <div>
                  <p className="text-xs text-custom-grey mt-2">
                    Tanggal dan Waktu
                  </p>
                  <p className="text-sm text-text-black font-normal">
                    {moment(props.dataTransaction.created_date).format(
                      "D MMM YYYY, hh:mm A"
                    )}
                  </p>
                </div>
              )}
            </div>
            <div>
              {props.dataTransaction.overtime_status !== "overtime_pending" ? (
                <div
                  className={`px-4 py-2 rounded-lg ${
                    props.dataTransaction.status === "done"
                      ? "bg-green-background"
                      : props.dataTransaction.status === "cancelled_book"
                      ? "bg-red-background"
                      : "bg-blue-background"
                  }`}
                >
                  <p
                    className={`text-xs text-center ${
                      props.dataTransaction.status === "done"
                        ? "text-text-dark-green"
                        : props.dataTransaction.status === "cancelled_book"
                        ? "text-text-dark-red"
                        : "text-text-dark-blue"
                    }`}
                  >
                    {props.dataTransaction.status === "rescheduled"
                      ? "Dijadwalkan Ulang"
                      : props.dataTransaction.status === "done"
                      ? "Selesai"
                      : props.dataTransaction.status === "request_reschedule"
                      ? "Dijadwalkan Ulang"
                      : props.dataTransaction.status === "cancelled_book"
                      ? "Dibatalkan"
                      : props.dataTransaction.status === "waiting_payment"
                      ? "Menunggu Pembayaran"
                      : props.hour === 0 &&
                        props.minutes < 0 &&
                        props.days === 0
                      ? "Berlangsung"
                      : "Dipesan"}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {props.dataTransaction.status === "cancelled_book" ||
        props.dataTransaction.status === "done" ||
        props.dataTransaction.overtime_status === "overtime_pending" ||
        props.dataTransaction.status === "waiting_payment" ? (
          <div>
            {props.dataTransaction.status === "done" &&
            !props.dataTransaction.is_reviewed ? (
              <div className="flex justify-center gap-2">
                <div
                  className="flex flex-col gap-2"
                  onClick={() =>
                    props.onSubmit(props.dataTransaction.reservation_code, true)
                  }
                >
                  <Like1
                    size="26"
                    color={"#A0AAB8"}
                    variant={"Linear"}
                    className="cursor-pointer"
                  />
                  <p>Helpful</p>
                </div>
                <span className="w-2"></span>
                <div
                  className="flex flex-col gap-2"
                  onClick={() =>
                    props.onSubmit(
                      props.dataTransaction.reservation_code,
                      false
                    )
                  }
                >
                  <Dislike
                    size="26"
                    color={"#A0AAB8"}
                    variant={"Linear"}
                    className="cursor-pointer"
                  />
                  <p>Unhelpful</p>
                </div>
              </div>
            ) : (
              <Link
                to={
                  props.dataTransaction.overtime_status === "overtime_pending"
                    ? config.LANDING_URL +
                      "/booking/reservation/" +
                      props.dataTransaction.reservation_code +
                      "/overtime"
                    : props.dataTransaction.status === "cancelled_book" ||
                      props.dataTransaction.status === "done"
                    ? config.LANDING_URL +
                      "/booking/profile/" +
                      props.dataTransaction.psycholog_code
                    : config.LANDING_URL +
                      "/booking/reservation/" +
                      props.dataTransaction.reservation_code
                }
                target="blank"
              >
                <button
                  type="button"
                  className="text-sm flex items-center justify-center font-medium text-white bg-black border border-gray-20 rounded-full h-10 w-full mt-2"
                >
                  {props.dataTransaction.status === "waiting_payment" ||
                  props.dataTransaction.overtime_status ===
                    "overtime_pending" ? (
                    <p>Bayar Sekarang</p>
                  ) : (
                    <p>Pesan Lagi</p>
                  )}
                </button>
              </Link>
            )}
          </div>
        ) : null}
      </div>
      <Link to={config.LANDING_URL + "/booking"} target="_blank">
        <div className="bg-[#F4FBFE] col-span-1 h-full p-4 rounded-lg flex items-center justify-center cursor-pointer">
          <AddCircle
            size="32"
            color="#167CB8"
            variant="Bold"
            className="mr-2"
          />
          <p>Pesan Sesi</p>
        </div>
      </Link>
    </div>
  );
}

export function NoLastTransaction(props: { nextTransaction: any }) {
  return (
    <div className="grid grid-cols-1 col-span-2 gap-4">
      <Link to={config.LANDING_URL + "/booking"} target="_blank">
        <div className="bg-[#F4FBFE] col-span-1 py-4 md:py-10 px-4 rounded-lg flex items-center justify-center cursor-pointer">
          <AddCircle
            size="32"
            color="#167CB8"
            variant="Bold"
            className="mr-2"
          />
          <p>Pesan Sesi</p>
        </div>
      </Link>
    </div>
  );
}

export function HaveNextTransaction(props: { dataNextTransaction: any }) {
  return (
    <Link
      to={
        "../session/" +
        props.dataNextTransaction.reservation_code +
        "/" +
        props.dataNextTransaction.date
      }
    >
      <div className="bg-[#F4FBFE] col-span-1 py-4 md:py-10 px-4 rounded-lg flex items-center justify-center cursor-pointer">
        <IcMenuNextSession
          style={{ fill: "#167CB8" }}
          width={30}
          className="mr-2"
        />
        <p>Go to next session</p>
      </div>
    </Link>
  );
}

export function NoNextTransaction() {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate("../session")}
      className="bg-[#F4FBFE] col-span-1 py-4 md:py-10 px-4 rounded-lg flex items-center justify-center cursor-pointer"
    >
      <IcMenuNextSession
        style={{ fill: "#167CB8" }}
        width={30}
        className="mr-2"
      />
      <p>Go to next session</p>
    </div>
  );
}
