import moment from "moment";

export const getTimezone = (data: { offset: number }) => {
  if (data.offset === 0) return "0";
  var indicator = "+";
  if (data.offset! < 0) {
    indicator = "-";
  }
  var dataFormatted = moment()
    .startOf("day")
    .seconds(parseInt(data.offset!.toString().replaceAll("-", "")))
    .format("HH:mm");

  var hasil = indicator + dataFormatted;

  return hasil;
};

export const checkCurrentTime = (data: { date: string }) => {
  var now = new Date();

  const startDate = moment(data.date);
  const timeEnd = moment(now);
  const diff = startDate.diff(timeEnd);
  const diffDuration = moment.duration(diff);

  return diffDuration;
};
