import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ReactComponent as IconNoSession } from "assets/icons/ic_no_session.svg";
import {
  getEarningDetail,
  getEarningList,
  getEarningSummary,
} from "services/earning";
import moment from "moment";
import { formatRupiah } from "utils";
import DetailEarning from "./content/detail_earning";
import { ArrowRight2 } from "iconsax-react";

const Earning = () => {
  const onGetEarningSummary = () => {
    return () =>
      getEarningSummary().then((val) => {
        return val.data;
      });
  };

  const queryEarningSummary = useQuery(
    "getEarningSummary",
    onGetEarningSummary()
  );
  const dataEarningSummary = queryEarningSummary.data;

  const onGetEarningList = () => {
    return () =>
      getEarningList().then((val) => {
        return val.data;
      });
  };

  const queryEarningList = useQuery("getEarningList", onGetEarningList());
  const dataEarningList = queryEarningList.data;

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const onGetEarningDetail = () => {
    return () =>
      getEarningDetail({
        year: year,
        month: month,
      }).then((val) => {
        return val.data;
      });
  };

  const queryEarningDetail = useQuery("getEarningDetail", onGetEarningDetail());
  const dataEarningDetail = queryEarningDetail.data;

  useEffect(() => {
    queryEarningDetail.refetch();
  }, [month, year]);

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-6 h-auto min-h-screen">
      <div className="col-span-2 h-auto min-h-full md:border-r md:border-r-grey-500 border-b border-b-grey-500">
        <h1 className="text-xl font-semibold p-6">Pendapatan</h1>
        <div className="p-6 bg-background-neutral text-center">
          <p className="text-base text-neutral-secondary">All Time</p>
          <p className="text-lg font-semibold">
            {formatRupiah(
              dataEarningSummary ? dataEarningSummary.total_earning : 0
            )}
          </p>
        </div>
        <div className="divide-y divide-custom-white">
          {dataEarningList ? (
            dataEarningList !== null ? (
              dataEarningList.map((item, i) => (
                <div
                  key={i}
                  className="py-3 flex items-center justify-between border-b cursor-pointer hover:bg-blue/50 p-6"
                  onClick={() => {
                    setYear(item.date.toString().substring(0, 4));
                    setMonth(item.date.toString().substring(5));
                  }}
                >
                  <div className="flex flex-col items-start gap-1">
                    <p className="font-medium text-sm">
                      {formatRupiah(item.amount)}
                    </p>
                    <p className="text-xs text-neutral-secondary">
                      {moment(item.date).format("YYYY MMM")}
                    </p>
                  </div>
                  <ArrowRight2 size="20" className="neutral-black" />
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center text-center mt-[80px] mb-[80px]">
                <div className="">
                  <IconNoSession />
                  <p className="text-text-grey text-xs">Belum Ada Pendapatan</p>
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
      <div className="col-span-4 h-auto min-h-full bg-white">
        {dataEarningDetail && dataEarningDetail !== "" ? (
          <DetailEarning dataEarning={dataEarningDetail} onChange={() => {}} />
        ) : (
          <div className="p-6">
            <div className="w-full">
              <h1 className="text-xl font-semibold">Info Detail Pendapatan</h1>
            </div>
            <div className="w-full flex flex-col items-center justify-center text-center mt-[150px] mb-[150px]">
              <IconNoSession />
              <p className="text-text-grey text-xs">Belum Ada Pendapatan</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Earning;
