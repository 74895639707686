export default function TermCondition() {
  return (
    <div className="p-6 bg-white rounded-lg max-h-[340px] overflow-y-auto">
      <h2 className="text-base font-medium">1. INTRODUCTION</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Welcome to Maxi. These Terms and Conditions is a legal contract that
        binds you, the reader and us, PT. Maxi Pilihan Indonesia (hereafter
        “Maxi”). It relates to your use of our website, mobile, web and desktop
        applications, dashboards, extension, and services including clinical and
        medical services. These shall subsequently be referred to in these Terms
        as the “Platform”. Our Platform allows you to, among other things,
        submit, store, and access certain data and other information related to
        you as applicable (collectively, “User Data”). Carefully read through
        the terms and clauses to understand the eligibility requirements, rights
        and obligations, liability, and other related terms of this contract. If
        you do not agree with these terms of service, you must reject them by
        not registering for or using our platform. Please note that, by
        registering, accessing, using or subscribing to our Platform and by
        clicking “I Agree,” or otherwise affirmatively manifesting your intent
        to be bound by these Terms of Service, you signify that you have read,
        understood, and agree to be bound by the following terms, including any
        additional guidelines and any future modifications.
      </p>

      <h2 className="text-base font-medium">2. MODIFICATION OF THE PLATFORM</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Subject to applicable law, the Company may change or discontinue,
        temporarily or permanently, any feature or component of our Platform at
        any time without notice.
      </p>

      <h2 className="text-base font-medium">3. PARTIES TO THIS CONTRACT</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        This Terms of Service binds the following persons to this contract.
        Company – This refers to Maxi and may often be referred to as “We”,
        “our”, “us”, or “Company” throughout these Terms. You – This refers to
        the users which may include individual, corporation, organisation,
        legally recognised entity excluding minors and persons without the
        capacity to enter into a contract. Please note that you must be 18 years
        of age or you must have attained the age of majority before using or
        accessing our Platform. Therefore, our platform is not directed at
        minors. The use of this platform is void where prohibited by applicable
        law and we assume no responsibility or liability for any
        misrepresentation of age. Employer – This refers to our Client (which
        may be a Company/Corporation/Organisation) who enters into a binding
        contract to create accounts on our platform and enrol its employees to a
        selected program. Employee – This refers to a person employed by our
        Client for salary or wages. Our Services – This refers to all services
        provided for our website including clinical and clinical support
        services, coaching services, and medical services. Digital Platform –
        This refers to our website, mobile, web and desktop applications,
        dashboards, and extension. Telemedicine Services – This refers to the
        remote diagnosis and treatment of users through our platform. Third
        Party Providers – This refers to providers, agents, and affiliates of
        both parties but who may disclose personal information relating to our
        users. This includes payment providers, insurance companies, cloud
        communication platforms, and in the case of an emergency, hospitals or
        designated health practitioners (example, a medical doctor). Elaborate
        instances include: Third Party Insurance Company – This refers to
        insurance companies that we or Employers may work with. Third Party
        Coaches – This refers to professionals we may hire as independent
        contractors to provide coaching services to our users. Third Party
        Medical Professionals – This refers to clinical psychologists, medical
        doctors, and psychiatrists we hire as independent contractors to provide
        clinical support and telemedicine. Please note that we shall not be
        liable for the actions or liability of these third-party providers.
        Ensure to abide by their terms and conditions.
      </p>

      <h2 className="text-base font-medium">4. SERVICES PROVIDED</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Maxi was founded to solve the cost of employee wellbeing on their
        productivity. Individuals in the modern workplace face myriad
        challenges, from career, family, workplace conflict - with many
        individuals burning out, or even experiencing mental ill-health.
        However, there are very few companies that use evidence informed
        practices to tackle workplace mental health. Maxi aims to equip each and
        every person with the knowledge, skills, and know-how to improve their
        mental wellbeing and productivity.
      </p>

      <h2 className="text-base font-medium">5. REGISTRATION OBLIGATIONS</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Upon registration on our Platform, you agree to the following:● You must
        comply with these Terms of Service and all applicable laws at all times
        whenever you access or use Platform including any services made
        available through it. ● The Company and its licensors and suppliers hold
        all proprietary rights in and to the Platform and the information made
        available through the Platform, and the Company provides a licence to
        you for the purposes of receiving its services subject to your
        compliance with these Terms of Service. ● We collect data from you to
        deliver our services as set out more fully in the Privacy Statement. ●
        You give us your informed consent to use the means necessary to provide
        you with our coaching services in compliance with applicable law,
        including remote-access communication features/functionality (such as
        video, text, or audio) and the information you voluntarily provide to
        us. ● You understand and confirm that the Company is not a licensed
        medical provider and does not provide medical care, healthcare services
        or emergency services. We only provide coaching services and do not
        intend at any time to provide medical or healthcare services to you.
      </p>

      <h2 className="text-base font-medium">
        6. YOU AGREE THAT THE PLATFORM, ANY COMPONENT OF THE PLATFORM, AND ANY
        INFORMATION MADE AVAILABLE THROUGH THE PLATFORM AND OUR SERVICES ARE NOT
        INTENDED TO BE USED FOR MEDICAL EMERGENCIES, SELF-DIAGNOSIS OR MEDICAL
        ADVICE. ALWAYS SEEK THE ADVICE OF YOUR PHYSICIAN OR OTHER QUALIFIED
        HEALTH PROVIDER REGARDING A MEDICAL CONDITION OR TREATMENT. YOU AGREE
        THAT: (i) IF YOU ARE IN NEED OF OR ARE SEEKING MEDICAL TREATMENT OR
        EMERGENCY CARE, YOU SHOULD CONTACT YOUR HEALTHCARE PROVIDER OR CALL
        EMERGENCY SERVICES IMMEDIATELY; (ii) WE DO NOT PROVIDE MEDICAL
        TREATMENT, ADVICE, OR DIAGNOSIS AND IS NOT A HEALTHCARE PROVIDER; (iii)
        WE ARE NOT YOUR HEALTHCARE PROVIDER, IS NOT AND DO NOT PROVIDE EMERGENCY
        SERVICES, AND MAY NOT CONTACT YOU OR ANYONE ON YOUR BEHALF WITH RESPECT
        TO YOUR MEDICAL CONDITION OR TREATMENT; (iv) DATA PROVIDED BY US THROUGH
        THE PLATFORM IS FOR INFORMATIONAL PURPOSES ONLY AND IS NOT INTENDED TO
        REPLACE THE RELATIONSHIP BETWEEN YOU AND YOUR PHYSICIAN OR OTHER
        HEALTHCARE PROVIDER; (v) WE ARE NOT A LICENSED MEDICAL CARE PROVIDER AND
        HAS NO EXPERTISE IN DIAGNOSING, EXAMINING, OR TREATING MEDICAL
        CONDITIONS OF ANY KIND, OR IN DETERMINING THE EFFECT OF ANY SPECIFIC
        TREATMENT ON A MEDICAL CONDITION; (vi) YOU SHOULD ALWAYS CONSULT A
        PHYSICIAN IF YOU HAVE ANY QUESTIONS REGARDING A MEDICAL CONDITION; (vii)
        NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN SEEKING IT
        BECAUSE OF SOMETHING YOU HAVE READ OR RECEIVED USING THE PLATFORM;
        (viii) WE HAVE NO LIABILITY WITH RESPECT TO ANY SURVEY OR
        RESEARCH/MEDICAL PROGRAM OR STUDY WITH WHICH YOU MAY BE USING THE
        PLATFORM; AND, (ix) WE ARE NOT RESPONSIBLE FOR THE ACCURACY,
        RELIABILITY, EFFECTIVENESS, OR CORRECT USE OF INFORMATION YOU RECEIVE
        THROUGH OR IS GENERATED BY THE PLATFORM. AGE OF CONSENT
      </h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        You agree that you are at least 18 years of age or have attained the age
        of majority in the jurisdiction where you have access to this Platform.
        If you are below the required age of majority, you are prohibited from
        further use and access to this Platform. We shall not be liable to the
        use of this Platform by a minor or person below the age of 18.
      </p>

      <h2 className="text-base font-medium">
        7. ACCOUNT ACTIVITY RESPONSIBILITY
      </h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Users Account – In order to use our Platform, you will have to register
        and create an account (“User Account”). When creating your account for
        the Service, you agree to provide true, accurate, current, and complete
        information. You are solely responsible for maintaining the
        confidentiality of your account and password and for restricting access
        to your mobile device, and you agree to accept responsibility for all
        activities that occur under your account or password. If you have reason
        to believe that your account is no longer secure (for example, in the
        event of a loss, theft or unauthorised disclosure or use of your
        username or password), you agree to immediately notify the Company. You
        may be liable for the losses incurred by us or others due to any
        unauthorised use of your User Account. System Access – You may choose to
        allow us to automatically retrieve data from your system(s). You hereby
        warrant that you have the permission, authority, and rights to allow the
        Company to access such system(s) and services and retrieve User Data
        therefrom by indicating the same within your User Account. We disclaim
        any and all liability associated with accessing and retrieving User Data
        from such system(s) and services on your behalf. If at any time you do
        not have the right and authority to allow us automatic access to such
        system(s), then you hereby agree to immediately disable such
        functionality within your User Account. Account Information – You
        acknowledge and agree that we may access, preserve and disclose your
        account information and related contents if required to do so by law or
        in a good faith belief that such access preservation or disclosure is
        reasonably necessary to: (a) comply with legal process; (b) enforce
        these Terms; (c) respond to claims that any User Data violates the
        rights of third parties; (d) respond to your requests for customer
        service; or (e) protect the rights, property or personal safety of
        Company, its users, or the public.
      </p>

      <h2 className="text-base font-medium">8. GRANT OF LICENCE</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Subject to the terms and conditions of these Terms of Service, we hereby
        grant to you a personal, non-transferable, non-exclusive, revocable,
        royalty-free, limited licence to install and use our platform on your
        mobile device for the purposes of receiving and participating in the
        services we provide. Users shall have no right to sub-license or resell
        the Service or any component thereof.
      </p>

      <h2 className="text-base font-medium">9. LOGIN INFORMATION AND ACCESS</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        We will protect all confidential and any personally identifying
        information that you may provide to complete the applicable online forms
        to establish your username/password or account with us (“Account Data”).
        You agree to provide, maintain and update true, accurate, current and
        complete Account Data, and represent that you will not misrepresent your
        identity or your affiliation with any person or entity. Company reserves
        the right to authenticate any information you provide. You shall be
        responsible for all use and access of the App by means of
        usernames/passwords issued to you. You will notify the Company promptly
        of any unauthorised use of your username/password or account or any
        other known or suspected breach of security, or other occurrence
        requiring username/password or account cancellations or changes. We
        cannot and do not assume any responsibility or liability for any
        information submitted by someone who has fraudulently accessed your
        account or for any third party's fraudulent use or misuse of information
        submitted by you. You are solely responsible for providing accurate
        information so that you can be provided with the appropriate coaching
        services. We are not responsible for your failure to provide the
        information needed to provide you with appropriate services.
      </p>

      <h2 className="text-base font-medium">10. RESTRICTIONS</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        When using our Platform, you agree not to: 1. Attempt to gain
        unauthorised access to our Platform, computer systems or networks
        connected to the Service, or any part of them, through hacking, password
        mining or any other means or interfere or attempt to interfere with the
        proper working of the Service or any activities conducted on the
        Service; 2. Modify our Platform in any manner or form, including but not
        limited to for the purpose of obtaining unauthorised access to our
        platform; 3. Impersonate another person or access another User’s User
        Account without that User’s permission or to violate any contractual or
        fiduciary relationships; 4. Reverse engineer, decompile, disassemble or
        otherwise attempt to discover the source code of the Platform, except
        and only to the extent that such activity is expressly permitted by
        applicable law notwithstanding this limitation; 5. Remove, circumvent,
        disable, damage or otherwise interfere with security-related features of
        the App and the Service, features that prevent or restrict use or
        copying of any content accessible through the Platform; 6. Access our
        platform if you are a direct competitor of the Company, except with the
        Company’s prior written consent, or for any other competitive purposes;
        or 7. Collect or harvest any personally identifiable information,
        including account names, from the Platform.
      </p>

      <h2 className="text-base font-medium">11. PRIVACY TERMS</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        For information about our privacy practices in connection with what we
        do with the personal information we collect from you, please read our
        Privacy Policy. The Privacy Policy explains how Maxi collects, uses,
        discloses, and secures your personally identifiable information in
        connection with your use of our services. In connection with creating an
        account or clicking an “I Accept” button or an equivalent indicator with
        regards to the access and use of App and the Platform, you have
        indicated that you agree to the terms of the Privacy Policy.
      </p>

      <h2 className="text-base font-medium">
        12. CONSENT TO ELECTRONIC CONTRACTING
      </h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        You agree that execution of these Terms of Service may occur by
        manifesting your acceptance of it when you use our Platform, and that no
        signature on a paper copy of these Terms is required in order to form a
        binding contract.
      </p>

      <h2 className="text-base font-medium">13. LINKS TO THIRD PARTIES</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Our Platform may contain links to other sites that are not operated by
        us. If you click a third-party link, you will be directed to that third
        party’s site. We strongly advise you to review the terms of every site
        you visit. We have no control over and assume no responsibility for the
        content, security, terms, compliance or practices of any third-party
        sites or services.
      </p>

      <h2 className="text-base font-medium">14. DISCLAIMERS</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Our Platform is provided “as is” and “as available” without warranties
        of any kind either express or implied. To the fullest extent permissible
        pursuant to applicable law, Maxi, its partners, affiliates, licensors,
        and agents disclaim all warranties, statutory, express or implied,
        including, but not limited to, implied warranties of merchantability,
        fitness for a particular purpose, and non-infringement of proprietary
        rights. We do not warrant that the functions contained in the Platform
        will be uninterrupted or error-free, that the Platform will meet your
        requirements, that defects will be corrected, or that the Platform or
        the server that makes it available is free of viruses or other harmful
        components.
      </p>

      <h2 className="text-base font-medium">15. LIMITATION OF LIABILITY</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        We will not under any circumstances, including, but not limited to,
        negligence, be liable for any special, indirect, incidental,
        consequential, punitive, reliance, or exemplary damages (including
        without limitation losses or liability resulting from loss of data, loss
        of revenue, anticipated profits, or loss of business opportunity) that
        result from your use or your inability to use the information or
        materials on the Platform, or any other interactions with us, even if we
        or out authorised representative has been advised of the possibility of
        such damages. Applicable law may not allow the limitation or exclusion
        of liability for incidental or consequential damages, so the above
        limitation or exclusion may not apply to you. In such cases, our
        liability will be limited to the fullest extent permitted by applicable
        law. In no event our total liability to you for all damages, losses, and
        causes of action arising out of or relating to these terms or your use
        of the Platform, including without limitation your interactions with
        other users, (whether in contract, tort including negligence, warranty,
        or otherwise) exceed the amount paid by you, if any, for accessing the
        Platform during the twelve (12) months immediately preceding the day the
        act or omission occurred that gave rise to your claim. Upon request by
        us, you agree to defend, indemnify and hold us and our partners,
        affiliates, use providers, licensors, suppliers, officers, directors,
        employees and agents harmless from and against any and all losses,
        liabilities, damages and costs, including but not limited to reasonable
        legal and accounting fees, arising from any claims, actions or demands
        related to or alleged to relate to: (a) your violation of these Terms of
        Service; or (b) your violation or infringement of any intellectual
        property or other third party rights or any applicable law in connection
        with your use of the Platform. We reserve the right to assume control of
        the defence of any third-party claim that is subject to indemnification
        by you, in which event you will cooperate with us.
      </p>

      <h2 className="text-base font-medium">16. FORCE MAJEURE</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        We shall not be liable for failure to perform any of its obligations
        hereunder during any period in which such performance is delayed or
        impracticable due to circumstances beyond our reasonable control,
        including without limitation earthquakes, fire, flood, war, embargo,
        riot, inability to secure materials, transportation, or power/utilities,
        intervention of any governmental authority, or acts of nature.
      </p>

      <h2 className="text-base font-medium">17. COMPLETE AGREEMENT</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        These Terms contain the complete and entire agreement and understanding
        of the parties regarding the subject matter hereof, and supersede all
        proposals, oral or written, all negotiations, conversations, discussions
        and all past course of dealing between you and us relating thereto.
      </p>

      <h2 className="text-base font-medium">18. WAIVER</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        Our failure to exercise or enforce any right or provision of these Terms
        will not constitute a waiver of such right or provision unless such
        waiver is in writing and signed by the Company.
      </p>

      <h2 className="text-base font-medium">19. SEVERABILITY</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        In the event any term in these Terms is held by a court of competent
        jurisdiction to be unenforceable, such unenforceability shall not affect
        the remaining terms of these Terms of Platform in such jurisdiction or
        render unenforceable or invalidate such terms and provisions of these
        Terms in other jurisdictions. Upon such determination that any of the
        terms or provisions of these Terms of Service are held to be invalid
        under any applicable statute or rule of law, they shall be severed from
        the rest of these Terms and the remaining provisions of these Terms
        shall survive and be interpreted so as best to reasonably effect the
        intent of the parties and the parties agree to replace any invalid or
        unenforceable provisions in a mutually acceptable manner in order for
        the transactions contemplated hereby to be consummated as originally
        contemplated to the greatest extent possible.
      </p>

      <h2 className="text-base font-medium">
        20. GOVERNING LAW AND DISPUTE RESOLUTION
      </h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        These Terms of Service shall be governed by and construed in accordance
        with the laws of Indonesia. Any dispute, controversy or claim arising
        out of or relating to these Terms, including the conclusion,
        interpretation, breach, termination, or invalidity thereof, which we
        fail to resolve within reasonable time after receiving notification from
        you, shall be settled by the Indonesia International Arbitration Centre
        (BANI) in accordance with its Rules. These Terms of Service shall be
        regulated by the substantive laws of Indonesia. The number of
        arbitrators shall be one (1). The place of arbitration shall be
        Indonesia. The language to be used in the arbitral proceedings shall be
        Bahasa Indonesia. Awards of the Arbitration Court shall be final and
        binding upon all parties.
      </p>

      <h2 className="text-base font-medium">21. MODIFICATION OF THE TERMS</h2>
      <p className="text-sm text-text-grey font-regular mt-3 mb-5 leading-normal">
        We reserve the right, at its sole discretion, to change, modify, add, or
        remove portions of these Terms at any time. You agree to review these
        Terms periodically for changes. When we make minor changes to the Terms,
        we will update the ‘Last Updated’ date at the top of this page. When we
        make changes to the Terms in a material manner, we will update the ‘Last
        Updated’ date at the top of this page and notify you that material
        changes have been made to these Terms. If any such revision is
        unacceptable to you, your only remedy is to terminate your User Account.
      </p>

      <h2 className="text-base font-medium">
        YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE, UNDERSTAND
        THEM, AND AGREE TO BE BOUND BY THEM.
      </h2>
    </div>
  );
}
