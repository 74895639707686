import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";

export default function Expertise(props: {
  data;
  choosedData;
  onSave: (any) => void;
}) {
  const [dataChoosed, setDataChoosed] = useState<any>([]);

  useEffect(() => {
    setDataChoosed(props.choosedData ?? []);
  }, [props.choosedData]);

  useEffect(() => {
    console.log(dataChoosed);
  }, [dataChoosed]);

  const saveData = (newData) => {
    const index = dataChoosed.findIndex(
      (_x) => _x.expertise_code === newData.expertise_code
    );
    const newArray = dataChoosed;
    if (index > -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(newData);
    }
    setDataChoosed([...newArray]);
  };

  return (
    <div className="w-full p-4 m-4 bg-white rounded-lg">
      <label
        htmlFor="message"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Keahlian Saya
      </label>

      <div className="divide-y divide-custom-white max-h-[500px] overflow-y-auto">
        {props.data.map((item, i) => (
          <div
            key={i}
            className="flex items-center py-4 cursor-pointer"
            onClick={() => saveData(item)}
          >
            <Checkbox
              color="default"
              checked={
                dataChoosed.findIndex(
                  (_x) => _x.expertise_code === item.expertise_code
                ) > -1
              }
            />
            {<p className={`ml-3 hover:text-text-dark-blue`}>{item.name}</p>}
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center ">
        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full w-80 h-12"
          onClick={() => props.onSave(dataChoosed)}
        >
          <strong>{"Simpan"}</strong>
        </button>
      </div>
    </div>
  );
}
