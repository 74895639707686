import { ReactComponent as ImgRegisterName } from "assets/images/img_register_name.svg";
import { InputText } from "components/cinput";
import config from "helpers/config";
import { setNameCookies, setRoleCookies } from "helpers/localStorage";
import { useState } from "react";
import { toast } from "react-toastify";
import { personalize } from "services/auth";
import { CapitalizeFirstLetter } from "utils";

export default function Register() {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const onSubmit = async () => {
    setIsLoading(true);
    personalize({
      username: name,
    })
      .then((res) => {
        setIsLoading(false);
        setNameCookies(name);
        setRoleCookies("member");
        window.location.replace(config.LANDING_URL);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  return (
    <div className="w-full h-screen max-h-screen max-w-screen relative">
      <div className="flex bg-[url('./assets/images/bg_header_register.png')] w-full h-4/5 bg-no-repeat">
        <div className="m-auto text-center">
          <ImgRegisterName className="w-72 h-72 mt-20" />
          <p className="text-xl font-medium mt-12">
            First, let’s choose a name
          </p>
          <p className="text-base text-text-grey mt-2">
            How do you want to be called?
          </p>
          <div className="mt-8">
            <InputText
              placeholder="Name"
              onChange={(val) => {
                setName(val);
                setIsDisabled(val === "");
              }}
              defaultValue={name}
              type="text"
              id="name"
            ></InputText>
          </div>
          <button
            type="button"
            className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-12 mt-4 disabled:bg-slate-100"
            onClick={onSubmit}
            disabled={isDisabled}
          >
            <strong>{isLoading ? "Loading..." : "Continue"}</strong>
            {isLoading ? (
              <div
                className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              ></div>
            ) : null}
          </button>
        </div>
      </div>
    </div>
  );
}
