import api from "./api";

export const getProfile = async () => {
  const res = await api.get("/v1/members/profile?lang=id");
  return res.data;
};

export const editProfile = (params: {
  username: string;
  phone: string;
  email: string;
  gender: string;
  birthyear: string;
  profileUrl?: string;
  job?: string;
  marital?: string;
  country?: string;
  religion?: string;
  educationLevel?: string;
}) => {
  return api
    .put(`/v1/members/profile`, {
      username: params.username,
      phone: params.phone,
      email: params.email,
      gender_key: params.gender,
      birthyear: params.birthyear,
      profile_url: params.profileUrl,
      job: params.job,
      marital: params.marital,
      country: params.country,
      religion_id: params.religion,
      education_level_id: params.educationLevel,
    })
    .then((val) => val.data);
};

export const editProfileSpecialist = (body) => {
  return api.put(`/v1/members/profile`, body).then((val) => val.data);
};

export const getLatestPoint = () => {
  return api.get("/v1/members/points/latest").then((val) => val.data);
};

export const changeEmail = (params: { email: string }) => {
  return api
    .put(`/v1/members/profile/change-email`, {
      email: params.email,
    })
    .then((val) => val.data);
};

export const verifyChangeEmail = (params: { email: string; pin: string }) => {
  return api
    .post(`/v1/members/profile/verify-pin`, {
      email: params.email,
      pin: params.pin,
    })
    .then((val) => val.data);
};

export const changePassword = (params: {
  password: string;
  newPassword: string;
  confirmPassword: string;
}) => {
  return api
    .put(`/v1/members/change-password`, {
      old_password: params.password,
      new_password: params.newPassword,
      confirm_new_password: params.confirmPassword,
    })
    .then((val) => val.data);
};

export const reqForgotPassword = (params: { email: string }) => {
  return api
    .post(`/v1/auths/forgot-password`, {
      email: params.email,
    })
    .then((val) => val.data);
};

export const verifyPinForgotPassword = (params: {
  email: string;
  pin: string;
}) => {
  return api
    .post(`/v1/auths/verify-pin`, {
      email: params.email,
      pin: params.pin,
    })
    .then((val) => val.data);
};

export const confirmNewPassword = (params: {
  pin: string;
  memberCode: string;
  newPassword: string;
  confirmPassword: string;
}) => {
  return api
    .put(`/v1/auths/new-password`, {
      pin: params.pin,
      member_code: params.memberCode,
      new_password: params.newPassword,
      confirm_password: params.confirmPassword,
    })
    .then((val) => val.data);
};

export const getSpecialistRates = async (params: {
  memberCode: string;
  lang?: string;
}) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}/rates`, {
    params: {
      lang: params.lang,
    },
  });

  return resp.data;
};
