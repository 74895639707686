import { Divider } from "components/divider";
import moment from "moment";
import { CapitalizeFirstLetter, formatRupiah } from "utils";
import { createRefund } from "services/transaction";
import { useState } from "react";
import { ArrowRight2, CloseCircle } from "iconsax-react";
import { ReactComponent as ImgRefundRequest } from "assets/images/img_refund_request.svg";
import { ReactComponent as ImgRefundSuccess } from "assets/images/img_refund_success.svg";
import { toast } from "react-toastify";
import { onChat } from "helpers";
import { getTokenAuth } from "helpers/localStorage";
import axios from "axios";
import { Link } from "react-router-dom";
import config from "helpers/config";

export default function DetailTransaction(props: {
  dataReservation;
  onChange: () => void;
}) {
  function downloadFile(code) {
    const tries = axios.create({
      responseType: "arraybuffer",
      responseEncoding: "binary",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: getTokenAuth(),
      },
    });
    tries
      .post(`https://api-stage.maxiapp.co/v1/payment/${code}/invoice`)
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf" })
        );
        console.log(url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice_${Date.now()}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
  }

  const closeModalRequestRefund = async () => {
    if (!isLoading) {
      setShowRequestRefund(false);
    }
  };

  const [showRequestRefund, setShowRequestRefund] = useState(false);
  const [showRequestSuccess, setShowRequestSuccess] = useState(false);

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isDisabledRefund, setIsDisabledRefund] = useState(true);

  const onSubmit = async () => {
    setIsLoading(true);
    createRefund({
      order_code: props.dataReservation.order_code,
      email: email,
    })
      .then((res) => {
        setIsLoading(false);
        setShowRequestRefund(false);
        setShowRequestSuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  return (
    <div className="sticky top-0">
      <div className="w-full p-6 border-b border-b-grey-500">
        <h1 className="text-xl font-semibold">Transaction Detail</h1>
      </div>
      <div className="fflex flex-row flex-wrap h-full p-4 bg-grey-500">
        {props.dataReservation !== undefined ? (
          <div>
            <div className="w-[440px] p-4 border rounded-md border-grey-500 m-auto">
              <div
                className={
                  "flex rounded-md items-center justify-center h-10 " +
                  (props.dataReservation.order_refund !== null
                    ? "bg-transaction-refund"
                    : props.dataReservation.status === "completed"
                    ? "bg-transaction-success"
                    : props.dataReservation.status === "failed" ||
                      props.dataReservation.status === "cancelled" ||
                      props.dataReservation.status === "cancelled_book"
                    ? "bg-transaction-cancel"
                    : props.dataReservation.status === "expired"
                    ? "bg-transaction-expired"
                    : "bg-transaction-pending")
                }
              >
                <p
                  className={
                    "font-normal text-xs text-neutral-secondary " +
                    (props.dataReservation.order_refund !== null
                      ? "text-transaction-refund-text"
                      : props.dataReservation.status === "completed"
                      ? "text-transaction-success-text"
                      : props.dataReservation.status === "failed" ||
                        props.dataReservation.status === "cancelled" ||
                        props.dataReservation.status === "cancelled_book"
                      ? "text-transaction-cancel-text"
                      : props.dataReservation.status === "expired"
                      ? "text-transaction-expired-text"
                      : "text-transaction-pending-text")
                  }
                >
                  {props.dataReservation.order_refund !== null
                    ? "Refund Requested"
                    : props.dataReservation.status === "completed"
                    ? "Payment Success"
                    : props.dataReservation.status === "failed" ||
                      props.dataReservation.status === "cancelled" ||
                      props.dataReservation.status === "cancelled_book"
                    ? "Payment Cancelled"
                    : props.dataReservation.status === "expired"
                    ? "Payment Expired"
                    : "Payment Pending"}
                </p>
              </div>
              <Divider className="my-4" />
              <p className="text-xs text-text-grey mb-0.5">Item</p>
              <p className="text-sm text-text-black mb-2.5">
                {"Consultation (" + props.dataReservation.title + ")"}
              </p>
              <p className="text-xs text-text-grey mb-0.5">Details</p>
              <p className="text-sm text-text-black mb-2.5">
                {props.dataReservation.format_call === "video_call"
                  ? "Video Call 60 Minutes"
                  : props.dataReservation.format_call === "voice_call"
                  ? "Voice Call 60 Minutes"
                  : "Preview 15 Minutes"}
              </p>
              <p className="text-xs text-text-grey mb-0.5">Purchased on</p>
              <p className="text-sm text-text-black mb-2.5">
                {moment(props.dataReservation.created_date).format(
                  "MMMM DD YYYY, HH:MM A"
                )}
              </p>
              <p className="text-xs text-text-grey mb-0.5">Payment Method</p>
              <p className="text-sm text-text-black mb-2.5">
                {props.dataReservation.payment_method === ""
                  ? "Poin"
                  : props.dataReservation.payment_method}
              </p>
              <p className="text-xs text-text-grey mb-0.5">Payment Amount</p>
              <p className="text-sm text-text-black mb-2.5">
                {formatRupiah(props.dataReservation.amount)}
              </p>
              {props.dataReservation.status === "completed" &&
              props.dataReservation.order_refund === null ? (
                <div
                  className="bg-button-black h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-4"
                  onClick={() => downloadFile(props.dataReservation.order_code)}
                >
                  <p className="text-white ml-2">Download Receipt</p>
                </div>
              ) : props.dataReservation.status === "created" ? (
                <Link
                  to={
                    config.LANDING_URL +
                    "/home/booking/reservation/" +
                    props.dataReservation.reservation_code
                  }
                  target="blank"
                >
                  <div className="bg-button-black h-11 rounded-full w-full flex items-center justify-center cursor-pointer mt-4">
                    <p className="text-white ml-2">Bayar Sekarang</p>
                  </div>
                </Link>
              ) : null}
            </div>
            {props.dataReservation.status === "completed" &&
            props.dataReservation.order_refund === null ? (
              <div
                className="flex justify-between items-center w-[440px] border rounded-md border-grey-500 m-auto mt-4 px-4 py-3 cursor-pointer"
                onClick={() => {
                  if (props.dataReservation.order_refund !== null) {
                    setShowRequestSuccess(true);
                  } else {
                    setShowRequestRefund(true);
                  }
                }}
              >
                <div>
                  <p className="text-sm font-semibold text-text-black">
                    Request Refund
                  </p>
                  <p className="text-xs text-text-grey mt-1">
                    Talk to a customer service for refund
                  </p>
                </div>
                <ArrowRight2 size="16" color="#121212" />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      {showRequestRefund ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                closeModalRequestRefund();
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[580px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <div className="flex place-self-stretch items-start justify-between min-w-20">
                    <h3 className="text-base font-semibold">Request Refund</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => closeModalRequestRefund()}
                    >
                      <CloseCircle size="24" color="#121122" />
                    </button>
                  </div>
                  <ImgRefundRequest className="my-6" />
                  <p className="font-semibold text-lg">
                    Request Refund via Email
                  </p>
                  <p className="text-sm text-text-grey mt-2 mb-2">
                    Our customer service will reach out to you via
                    <br />
                    the email address you provided.
                  </p>
                  <div className="grid place-items-start">
                    <div className="flex items-center justify-start mt-3">
                      <div className="border border-brand-dark rounded-full w-5 h-5 flex items-center justify-center">
                        <p className="text-xs text-center	text-brand-dark">1</p>
                      </div>
                      <p className="text-text-grey text-sm ml-2">
                        Submit your email address
                      </p>
                    </div>
                    <div className="flex items-center justify-start mt-3">
                      <div className="border border-brand-dark rounded-full w-5 h-5 flex items-center justify-center">
                        <p className="text-xs text-center	text-brand-dark">2</p>
                      </div>
                      <p className="text-text-grey text-sm ml-2">
                        We will review your request
                      </p>
                    </div>
                    <div className="flex items-center justify-start mt-3">
                      <div className="border border-brand-dark rounded-full w-5 h-5 flex items-center justify-center">
                        <p className="text-xs text-center	text-brand-dark">3</p>
                      </div>
                      <p className="text-text-grey text-sm ml-2">
                        We will inform you of the outcome via email
                      </p>
                    </div>
                  </div>
                  <input
                    id="email"
                    defaultValue={email}
                    onChange={(val) => {
                      setEmail(val.target.value);
                      setIsDisabledRefund(val.target.value === "");
                    }}
                    type="email"
                    className=" w-80 mt-6 p-4 h-12 text-gray-900 rounded-full bg-white border border-neutral-300 focus:border-blue-200"
                    placeholder="Your email address"
                  />
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full w-80 h-12 mt-6 disabled:bg-slate-300"
                    disabled={isDisabledRefund}
                    onClick={() => onSubmit()}
                  >
                    <strong>{isLoading ? "Loading..." : "Submit"}</strong>
                    {isLoading ? (
                      <div
                        className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      ></div>
                    ) : null}
                  </button>
                  <p className="text-sm mt-4 text-text-black">
                    Do you want to chat with customer service?
                  </p>
                  <p
                    className="text-sm text-text-dark-blue cursor-pointer"
                    onClick={() => onChat()}
                  >
                    Contact us on whatsapp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showRequestSuccess ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                setShowRequestSuccess(false);
              }}
            ></div>
            <div className="absolute w-full my-6 mx-auto max-w-[580px]">
              <div className="border-0 rounded-lg shadow-lg flex justify-center bg-white focus:outline-none">
                <div className="grid place-items-center text-center px-10 py-6 w-full">
                  <div className="flex place-self-stretch items-start justify-between min-w-20">
                    <h3 className="text-base font-semibold">Request Refund</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => setShowRequestSuccess(false)}
                    >
                      <CloseCircle size="24" color="#121122" />
                    </button>
                  </div>
                  <ImgRefundSuccess className="my-6" />
                  <p className="font-semibold text-lg text-text-dark-blue">
                    Thank you
                  </p>
                  <p className="text-sm text-text-black mt-2 mb-2">
                    we have received your request and
                    <br />
                    will get back to you shortly by email.
                  </p>
                  <button
                    type="button"
                    className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full w-80 h-12 mt-6"
                    onClick={() => setShowRequestSuccess(false)}
                  >
                    <strong>Thank you</strong>
                  </button>
                  <p className="text-sm mt-4 text-text-black">
                    Do you want to chat with customer service?
                  </p>
                  <p
                    className="text-sm text-text-dark-blue cursor-pointer"
                    onClick={() => onChat()}
                  >
                    Contact us on whatsapp
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
