import moment from "moment";
import React from "react";
import { formatRupiah, getSessionType, getSessionTypeShort } from "utils";

export default function DetailEarning(props: {
  dataEarning;
  onChange: () => void;
}) {
  return (
    <div>
      <h1 className="text-xl font-semibold p-6">Detail Pendapatan</h1>
      <div className="px-6">
        <div className="flex items-center bg-background-neutral h-[50px] px-4">
          <div className="w-[140px] text-sm">Nama Klien</div>
          <div className="w-[100px] text-sm">Tanggal</div>
          <div className="w-[100px] text-sm">Jam</div>
          <div className="w-[250px] text-sm">Tipe Sesi</div>
          <div className="w-[100px] text-sm">Pendapatan</div>
        </div>
        <div className="px-4">
          {props.dataEarning.map((item, i) => (
            <div className="flex items-center h-[50px]">
              <div className="w-[140px] text-sm">{item.member_username}</div>
              <div className="w-[100px] text-sm">
                {moment(item.date).format("DD MMM yyyy")}
              </div>
              <div className="w-[100px] text-sm">
                {moment(item.time_from, "HH:mm:ss").format("HH:mm A")}
              </div>
              <div className="w-[250px] text-sm">
                {getSessionType(item.format_call)}
              </div>
              <div className="w-[100px] text-sm text-text-dark-blue">
                {formatRupiah(item.psycholog_fee)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
