import { LoadingModal } from "components/loadingModal";
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "screens/dashboard";
import Home from "screens/home";
import Linker from "screens/linker";
import Login from "screens/login";
import Transaction from "screens/transaction";
import Session from "screens/session";
import Profile from "screens/profile";
import Register from "screens/register";
import ForgotPassword from "screens/forgot";
import { LocalStorage } from "helpers";
import Availability from "screens/availability";
import Earning from "screens/earning";

interface IRoute {
  path: string;
  element: () => JSX.Element;
}

const publicRoutes: IRoute[] = [
  {
    path: "/",
    element: Login,
  },
  {
    path: "/register",
    element: Register,
  },
  {
    path: "/forgot",
    element: ForgotPassword,
  },
];

const privateRoutes: IRoute[] = [
  {
    path: "/home",
    element: Home,
  },
  {
    path: "/session",
    element: Session,
  },
  {
    path: "/transaction",
    element: Transaction,
  },
  {
    path: "/availability",
    element: Availability,
  },
  {
    path: "/earning",
    element: Earning,
  },
  {
    path: "/profile",
    element: Profile,
  },
];

export default function RoutePage() {
  return (
    <Suspense fallback={<LoadingModal></LoadingModal>}>
      <Routes>
        {publicRoutes.map((route) => {
          const token = LocalStorage.getTokenAuth();
          const name = LocalStorage.getNameCookies();

          if (
            token === undefined ||
            token === "" ||
            token === null ||
            name === undefined ||
            name === "" ||
            name === null
          ) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<route.element />}
              />
            );
          }

          return (
            <Route
              key={route.path}
              path={route.path}
              element={<Navigate to={"/home"} />}
            />
          );
        })}

        <Route
          path="/*"
          key={"private"}
          element={
            <Dashboard>
              <Routes>
                {privateRoutes.map((route) => {
                  const token = LocalStorage.getTokenAuth();
                  const name = LocalStorage.getNameCookies();

                  if (
                    token === undefined ||
                    token === "" ||
                    token === null ||
                    name === undefined ||
                    name === "" ||
                    name === null
                  ) {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={<Navigate to={"/"} />}
                      />
                    );
                  }

                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<route.element />}
                    />
                  );
                })}
                <Route path="/session/:id/:date" element={<Session />} />
                <Route path="/transaction/:id" element={<Transaction />} />
              </Routes>
            </Dashboard>
          }
        />
        <Route path="/download-now" element={<Linker />} />
      </Routes>
    </Suspense>
  );
}
