import api from "./api";

export const getEarningSummary = async () => {
  const res = await api.get("/v1/specialist/earning/summary");

  return res.data;
};

export const getEarningList = async () => {
  const res = await api.get("/v1/specialist/earning/list-group");

  return res.data;
};

export const getEarningDetail = async (params: {
  year: string;
  month: string;
}) => {
  const res = await api.get("/v1/specialist/earning/list", {
    params: {
      year: params.year,
      month: params.month,
      limit: "99",
    },
  });
  return res.data;
};
