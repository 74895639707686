import React, { useRef } from "react";
import { Divider } from "components/divider";
import { DialogContent } from "@mui/material";
import { toast } from "react-toastify";
import { ModalSimple } from "components/modal/modalSimple";
import config from "helpers/config";
import { formatRupiah } from "utils";
import moment from "moment";

export default function ModalOvertimeLink(props: {
  session: any;
  overtime: any;
  isShow: boolean;
  onHide: () => void;
  onSubmit: () => void;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const session = props.session;
  const overtime = props.overtime;

  const onSubmit = () => {
    window.navigator.clipboard.writeText(
      contentRef.current!.innerText!.replace(/\n\n/g, "\n")
    );
    toast.success("link already copied into clipboard!");
  };

  return (
    <ModalSimple
      hide={props.onHide}
      isShown={props.isShow}
      title=""
      width="480px"
    >
      <DialogContent>
        <div className="border border-custom-border p-4 w-full rounded-lg text-start mt-4">
          <p className="text-sm">Detail Kelebihan Waktu</p>
          <Divider className="my-3" />
          <p className="text-xs text-text-grey font-medium">Waktu</p>
          <p className="text-sm text-text-black mb-3 mt-1">
            {overtime?.duration + " menit"}
          </p>
          <p className="text-xs text-text-grey font-medium">Total</p>
          <p className="text-sm text-text-black mb-3 mt-1">
            {formatRupiah(overtime?.price)}
          </p>
          <p className="text-xs text-text-grey font-medium">Status</p>
          <p className="text-sm text-text-black mt-1">
            {overtime?.status === "confirmed" ? "Dibayar" : "Belum dibayar"}
          </p>
        </div>

        <div className="border border-custom-border p-4 w-full rounded-lg text-start mt-4">
          <div ref={contentRef}>
            <p className="text-sm">Tautan Pembayaran</p>
            <Divider className="my-3" />
            <p className="text-sm text-text-black font-semibold">
              Konfirmasi Harga Kelebihan Waktu
            </p>
            <p className="text-sm text-text-black mt-1">
              {"Nama: " + session?.psycholog_username}
            </p>
            <p className="text-sm text-text-black mt-1">
              {`Sesi: ${overtime?.duration} menit kelebihan Waktu`}
            </p>
            <p className="text-sm text-text-black mt-1">
              {"Tanggal: " +
                moment(overtime?.created_date).format("DD MMMM YYYY")}
            </p>
            <p className="text-sm text-text-black mt-1">
              {"Waktu: " +
                moment(session?.time_from, "HH:mm").format("HH:mm A")}
            </p>
            <p className="text-sm text-text-black mt-4">
              Gunakan tautan ini untuk menyelesaikan pembayaran Anda melalui
              situs web:{" "}
              <a
                className="text-brand-dark"
                href={getLink(session)}
                target="_blank"
                rel="noreferrer"
              >
                {getLink(session)}
              </a>{" "}
              atau bayar melalui Aplikasi Ohana
            </p>
          </div>

          <button
            onClick={onSubmit}
            className="text-white bg-black hover:bg-black/60 rounded-full w-full h-12 disabled:bg-slate-300"
            title="Salin"
            style={{ marginTop: "30px" }}
          >
            Salin teks
          </button>
        </div>

        {overtime?.status !== "confirmed" ? (
          <button
            onClick={props.onSubmit}
            className="text-white bg-black hover:bg-black/60 rounded-full w-full h-12 disabled:bg-slate-300"
            title="Salin"
            style={{ marginTop: "20px" }}
          >
            Ubah Data
          </button>
        ) : null}
      </DialogContent>
    </ModalSimple>
  );
}

function getLink(value: any) {
  return `${config.LANDING_URL}/booking/reservation/${value?.reservation_code}/overtime`;
}
