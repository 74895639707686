import styled from "styled-components";

const ModalCLoseIcon = styled.i`
  color: rgba(118, 126, 140, 1);
  font-size: 21px;
  position: absolute;
  top: 14px;
  right: 23px;
  cursor: pointer;
`;

export { ModalCLoseIcon };
