import api from "./api";

export const getCountry = async () => {
  const res = await api.get("/v1/administrative/countries");
  return res.data;
};

export const getJob = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/specialist/targets", {
    params: {
      is_active: true,
      lang: data.lang,
    },
  });

  return res.data;
};

export const getGender = async (data: { lang: string }) => {
  var res;
  if (data.lang === "id") {
    res = {
      male: "Laki laki",
      female: "Perempuan",
    };
  } else {
    res = {
      male: "Male",
      female: "Female",
    };
  }

  return res;
};

export const getBirthyear = async () => {
  const res: String[] = [];
  const currentDate = new Date();

  for (
    let i = currentDate.getFullYear() - 60;
    i <= currentDate.getFullYear() - 16;
    i++
  ) {
    res.push(i.toString());
  }

  return res.reverse();
};

export const getMarital = async (data: { lang: string }) => {
  var res = {};
  if (data.lang === "id") {
    res = {
      single: "Lajang",
      married: "Menikah",
      divorced: "Cerai",
      widowed: "Janda / Duda",
      in_a_relationship: "Dalam hubungan",
      prefer_not_to_say: "Lainnya",
    };
  } else {
    res = {
      single: "Single",
      married: "Married",
      divorced: "Divorced",
      widowed: "Widowes",
      in_a_relationship: "In a Relationship",
      prefer_not_to_say: "Other",
    };
  }

  return res;
};

export const getYOE = async (data: { lang: string }) => {
  var res = {};
  if (data.lang === "id") {
    res = {
      "1 Year": "1 Tahun",
      "2 Years": "2 Tahun",
      "3 Years": "3 Tahun",
      "4 Years": "4 Tahun",
      "5 Years": "5 Tahun",
      "6 Years": "6 Tahun",
      "7 Years": "7 Tahun",
      "8 Years": "8 Tahun",
      "9 Years": "9 Tahun",
      "10 Years": "10 Tahun",
      "11 Years": "11 Tahun",
      "12 Years": "12 Tahun",
      "13 Years": "13 Tahun",
      "14 Years": "14 Tahun",
      "15 Years": "15 Tahun",
      "16 Years": "16 Tahun",
      "17 Years": "17 Tahun",
      "18 Years": "18 Tahun",
      "19 Years": "19 Tahun",
      "20 Years": "20 Tahun",
      "more Years": "> 20 Tahun",
    };
  } else {
    res = {
      "1 Year": "1 Year",
      "2 Years": "2 Years",
      "3 Years": "3 Years",
      "4 Years": "4 Years",
      "5 Years": "5 Years",
      "6 Years": "6 Years",
      "7 Years": "7 Years",
      "8 Years": "8 Years",
      "9 Years": "9 Years",
      "10 Years": "10 Years",
      "11 Years": "11 Years",
      "12 Years": "12 Years",
      "13 Years": "13 Years",
      "14 Years": "14 Years",
      "15 Years": "15 Years",
      "16 Years": "16 Years",
      "17 Years": "17 Years",
      "18 Years": "18 Years",
      "19 Years": "19 Years",
      "20 Years": "20 Years",
      "more Years": "> 20 Years",
    };
  }

  return res;
};

export const getLanguages = async (data: { lang: string }) => {
  var res = {};
  if (data.lang === "id") {
    res = {
      English: "Inggris",
      Indonesian: "Indonesia",
    };
  } else {
    res = {
      English: "English",
      Indonesian: "Indonesian",
    };
  }

  return res;
};

export const getReligion = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/religion", {
    params: {
      lang: data.lang,
    },
  });

  return res.data;
};

export const getEducationLevel = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/education-level", {
    params: {
      lang: data.lang,
    },
  });

  return res.data;
};

export const getTarget = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/specialist/targets", {
    params: {
      is_active: true,
      lang: data.lang,
    },
  });

  return res.data;
};

export const getExpertise = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/specialist/expertises", {
    params: {
      is_active: true,
      lang: data.lang,
    },
  });

  return res.data;
};

export const getApproaches = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/specialist/approaches/flat", {
    params: {
      is_active: true,
      lang: data.lang,
    },
  });

  return res.data;
};

export const getTimeZone = async () => {
  const res = await api.get("/v1/timezone");

  return res.data;
};

export const getDateRange = async (data: { lang: string }) => {
  var res = {};
  if (data.lang === "id") {
    res = {
      "30": "30 Hari",
      "60": "60 Hari",
    };
  } else {
    res = {
      "30": "30 Days",
      "60": "60 Days",
    };
  }

  return res;
};

export const getNoticePeriods = async (data: { lang: string }) => {
  const res = await api.get("/v1/specialist/schedule/notice-period", {
    params: {
      lang: data.lang,
    },
  });

  return res.data;
};
