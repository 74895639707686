import { ReactComponent as IconChangePasswordSuccess } from "assets/icons/ic_change_password_success.svg";
import { useState } from "react";
import { changePassword } from "services/profile";
import { requestOTP } from "services/auth";
import { toast } from "react-toastify";
import { CapitalizeFirstLetter } from "utils";
import ShowHidePassword from "components/showHidePassword";

export default function ChangePassword(props: {
  email;
  onSave: () => void;
  onForgot: () => void;
  onCancel: () => void;
}) {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const checkFormDisabled = () => {
    setIsDisabled(
      password === "" || newPassword === "" || confirmPassword === ""
    );
  };

  const onSubmit = async () => {
    setIsLoading(true);
    changePassword({
      password: password,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    })
      .then((res) => {
        requestOTP().then((res) => {
          setIsLoading(false);
          setShowState("success");
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const [showState, setShowState] = useState("");

  return (
    <div className="w-full m-8 bg-white rounded-lg ">
      {showState === "success" ? (
        <div className="m-auto text-center py-8">
          <div className="flex justify-center">
            <IconChangePasswordSuccess />
          </div>
          <p className="text-lg font-medium">
            Your password has been updated
            <br />
            successfully!
          </p>
        </div>
      ) : (
        <div className="m-auto text-center py-8">
          <p className="text-lg font-semibold">Change Password</p>

          <div className="text-start max-w-[400px] m-auto">
            <p className="text-sm text-text-grey mt-6 mb-1">Current Password</p>
            <ShowHidePassword
              name={password}
              defaultValue={password}
              placeholder="Password"
              onChange={(val) => {
                setPassword(val);
                checkFormDisabled();
              }}
            ></ShowHidePassword>
            <p
              className="text-xs text-text-dark-blue mt-1 cursor-pointer"
              onClick={() => props.onForgot()}
            >
              Forgot Password
            </p>
            <p className="text-sm text-text-grey mt-4 mb-1">New Password</p>
            <ShowHidePassword
              name={newPassword}
              defaultValue={newPassword}
              placeholder="New Password"
              onChange={(val) => {
                setNewPassword(val);
                checkFormDisabled();
              }}
            ></ShowHidePassword>
            <p className="text-sm text-text-grey mt-4 mb-1">
              Confirm New Password
            </p>
            <ShowHidePassword
              name={confirmPassword}
              defaultValue={confirmPassword}
              placeholder="Confirm Password"
              onChange={(val) => {
                setConfirmPassword(val);
                checkFormDisabled();
              }}
            ></ShowHidePassword>
            <button
              type="button"
              className="text-sm flex items-center justify-center font-medium text-white bg-black hover:bg-black/70 rounded-full h-12 w-full mt-6 disabled:bg-slate-300"
              onClick={() => onSubmit()}
              disabled={isDisabled}
            >
              <strong>{isLoading ? "Loading..." : "Reset Password"}</strong>
              {isLoading ? (
                <div
                  className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                ></div>
              ) : null}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
