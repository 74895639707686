import api from "./api";

export const getSessionCalendar = async (data: {
  year: string;
  month: string;
}) => {
  const res = await api.get("/v1/members/activity/calendar", {
    params: {
      year: data.year,
      month: data.month,
    },
  });
  return res.data;
};

export const getSessionDaily = async (data: {
  date: string;
  lang: string;
  types: string;
}) => {
  const res = await api.get("/v1/members/activity/daily", {
    params: {
      date: data.date,
      lang: data.lang,
      types: data.types,
    },
  });
  return res.data;
};

export const getSessionCalendarSpecialist = async (data: {
  year: string;
  month: string;
}) => {
  const res = await api.get("/v1/members/activity/specialist/calendar", {
    params: {
      year: data.year,
      month: data.month,
    },
  });
  return res.data;
};

export const getSessionDailySpecialist = async (data: {
  date: string;
  lang: string;
  types: string;
}) => {
  const res = await api.get("/v1/members/activity/specialist/daily", {
    params: {
      date: data.date,
      lang: data.lang,
      types: data.types,
    },
  });
  return res.data;
};

export const getSessionDetail = async (data: {
  code: string;
  lang: string;
}) => {
  const res = await api.get("/v1/members/specialist/reservation/" + data.code, {
    params: {
      lang: data.lang,
    },
  });
  return res.data;
};

export const checkAnswerPrecounseling = async (data: {
  code: string;
  lang: string;
}) => {
  const res = await api.get(
    "/v1/members/specialist/reservation/" + data.code + "/counseling/answer",
    {
      params: {
        lang: data.lang,
        limit: "99",
        sort: "asc",
        type: "pre_counseling",
      },
    }
  );
  return res.data;
};

export const checkFormPrecounseling = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/counseling", {
    params: {
      lang: data.lang,
      type: "pre_counseling",
      is_active: "true",
    },
  });
  return res.data;
};

export const getCurrentSession = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/specialist/reservation/current", {
    params: {
      lang: data.lang,
    },
  });
  return res.data;
};

export const checkReview = async (data: { code: string; lang: string }) => {
  const res = await api.get(
    "/v1/members/specialist/reservation/" + data.code + "/review",
    {
      params: {
        lang: data.lang,
      },
    }
  );
  return res.data;
};

export const checkClientNotes = async (data: {
  code: string;
  lang: string;
}) => {
  const res = await api.get(
    "/v1/members/specialist/reservation/" + data.code + "/notes",
    {
      params: {
        lang: data.lang,
      },
    }
  );
  return res.data;
};

export const checkClientNotesFile = async (data: {
  code: string;
  lang: string;
}) => {
  const res = await api.get(
    "/v1/members/specialist/reservation/" + data.code + "/notes/file",
    {
      params: {
        lang: data.lang,
      },
    }
  );
  return res.data;
};

export const getCancelReason = async (data: { lang: string }) => {
  const res = await api.get(
    "/v1/members/specialist/reservation/cancel/reason",
    {
      params: {
        lang: data.lang,
      },
    }
  );
  return res.data;
};

export const postCancel = async (data: {
  code: string;
  email: string;
  cancel_code: string;
  other_reason: string;
}) => {
  const res = await api.post(
    "/v1/members/specialist/reservation/" + data.code + "/cancel",
    {
      email: data.email,
      cancel_reason_code: data.cancel_code,
      other_reason: data.other_reason,
    }
  );
  return res.data;
};

export const addReview = async (data: {
  code: string;
  review: string;
  rating: boolean;
}) => {
  const res = await api.post(
    "/v1/members/specialist/reservation/" + data.code + "/review",
    {
      review: data.review,
      rating: data.rating,
    }
  );
  return res.data;
};

export const updateReview = async (data: {
  code: string;
  review: string;
  rating: boolean;
}) => {
  const res = await api.put(
    "/v1/members/specialist/reservation/" + data.code + "/review",
    {
      review: data.review,
      rating: data.rating,
    }
  );
  return res.data;
};

export const addPrecounseling = async (data: { code: string; form }) => {
  const res = await api.post(
    "/v1/members/specialist/reservation/" + data.code + "/counseling",
    data.form
  );
  return res.data;
};

export const checkOvertime = async (data: { code: string }) => {
  const res = await api.get(
    "/v1/members/specialist/reservation/" + data.code + "/overtime"
  );
  return res.data;
};

export const lastReservation = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/specialist/reservation/current", {
    params: {
      status: "all",
      lang: data.lang,
    },
  });
  return res.data;
};

export const nextReservation = async (data: { lang: string }) => {
  const res = await api.get("/v1/members/specialist/reservation/current", {
    params: {
      lang: data.lang,
    },
  });
  return res.data;
};

export const getScheduleMonthly = async (params: {
  psychologCode: string;
  formatCall: string;
  year: number;
  month: number;
}) => {
  const res = await api.get(
    "/v1/members/specialist/" + params.psychologCode + "/schedule/monthly",
    {
      params: {
        format_call: params.formatCall,
        year: params.year,
        month: params.month,
      },
    }
  );
  return res.data;
};

export const getSchedulesDaily = async (params: {
  memberCode: string;
  status?: string;
  format_call?: string;
  date: string;
}) => {
  const resp = await api.get(
    `/v1/members/specialist/${params.memberCode}/schedule`,
    {
      params: {
        status: params.status,
        format_call: params.format_call,
        date: params.date,
      },
    }
  );

  return resp.data;
};

export const putReschedule = async (params: {
  lang: string;
  reservationCode: string;
  scheduleCode: string;
}) => {
  const res = await api.put(
    `/v1/members/specialist/reservation/${params.reservationCode}/reschedule`,
    {
      lang: params.lang,
      schedule_detail_code: params.scheduleCode,
      reschedule_reason: "",
    }
  );
  return res.data;
};

export const putRequestReschedule = async (params: {
  lang: string;
  reservationCode: string;
  reason: string;
}) => {
  const res = await api.put(
    `/v1/members/specialist/reservation/${params.reservationCode}/reschedule`,
    {
      lang: params.lang,
      schedule_detail_code: "",
      reschedule_reason: params.reason,
    }
  );
  return res.data;
};

export const updateReservation = async (params: {
  reservationCode: string;
  roomLink: string;
  eventId: string;
}) => {
  const res = await api.put(
    `/v1/members/specialist/reservation/${params.reservationCode}`,
    {
      room_link: params.roomLink,
      event_id: params.eventId,
    }
  );
  return res.data;
};

export const updateSessionStatus = async (data: {
  code: string;
  status: string;
  roomLink: string;
  eventId: string;
}) => {
  const res = await api.put("/v1/members/specialist/reservation/" + data.code, {
    status: data.status,
    room_link: data.roomLink,
    event_id: data.eventId,
  });
  return res.data;
};
