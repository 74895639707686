import { useEffect } from 'react';

export default function Linker() {
    const onDownloadApp = () => {
        var userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (/android/i.test(userAgent)) {
            return window.location.href = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.rebelworks.maxi_app_prod';
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            return window.location.href = 'https://apps.apple.com/id/app/maxi-think-perform-better/id1632571529?itsct=apps_box_link&itscg=30200';
        }

        return window.location.href = 'https://linktr.ee/maxiapp';
    };

    useEffect(() => {
        onDownloadApp();
    }, []);

    return (
        <h3 className='text-center'>Loading...</h3>
    );
}