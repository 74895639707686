import api from "./api";

export const getTransaction = async (data: {
  lang: string;
  keyword: string;
  limit: string;
}) => {
  const res = await api.get("/v1/members/transaction", {
    params: {
      is_active: true,
      lang: data.lang,
      sort: "desc",
      page: "1",
      limit: data.limit,
      keyword: "",
    },
  });
  return res.data;
};

export const getDetailTransaction = async (data: { code: string }) => {
  return api
    .get("/v1/members/transaction/" + data.code)
    .then((val) => val.data);
};

export const createRefund = async (data: {
  order_code: string;
  email: string;
}) => {
  return api
    .post("/v1/payment/" + data.order_code + "/refund", {
      email: data.email,
    })
    .then((val) => val.data);
};
