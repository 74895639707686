import { ReactComponent as IconCircleFill } from "assets/icons/ic_check_circle_fill.svg";
import { ReactComponent as IconCircle } from "assets/icons/ic_check_circle.svg";
import { useEffect, useState } from "react";
import moment from "moment";

export default function NoticePeriod(props: {
  data;
  choosedData;
  onSave: (data) => void;
}) {
  const onSubmit = async () => {
    props.onSave(dataChoosed);
  };

  const onChoosed = async (code) => {
    setDataChoosed(code);
  };

  const [dataChoosed, setDataChoosed] = useState<any>();

  useEffect(() => {
    setDataChoosed(props.choosedData);
  }, [props.choosedData]);

  return (
    <div className="w-full p-4 m-4 bg-white rounded-lg">
      <div className="text-center">
        <p className="text-xl font-semibold">Periode Peringatan</p>
        <p className="text-sm mt-3">
          Klien Anda akan memesan slot waktu Anda lebih awal dari waktu yang
          Anda tentukan
        </p>
      </div>

      <div className="mt-4">
        {props.data.map((item, i) => (
          <div
            key={i}
            className={`flex justify-center py-4 cursor-pointer rounded-lg mb-3 border ${
              item.value === dataChoosed
                ? "border-black bg-blue-app/20"
                : "bg-white"
            }`}
            onClick={() => onChoosed(item.value)}
          >
            <p className="font-medium hover:text-text-dark-blue">
              {item.label}
            </p>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center ">
        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full w-80 h-12"
          onClick={() => onSubmit()}
        >
          <strong>{"Simpan"}</strong>
        </button>
      </div>
    </div>
  );
}
