import { ReactComponent as IconCircleFill } from "assets/icons/ic_check_circle_fill.svg";
import { ReactComponent as IconCircle } from "assets/icons/ic_check_circle.svg";
import { useEffect, useState } from "react";

export default function Job(props: {
  data;
  choosedData;
  onSave: (code, name) => void;
}) {
  const onSubmit = async () => {
    props.onSave(dataCodeChoosed, dataNameChoosed);
  };

  const onChoosed = async (code, name) => {
    setDataCodeChoosed(code);
    setDataNameChoosed(name);
  };

  const [dataCodeChoosed, setDataCodeChoosed] = useState("");
  const [dataNameChoosed, setDataNameChoosed] = useState("");

  useEffect(() => {
    setDataCodeChoosed(props.choosedData);
  }, [props.choosedData]);

  return (
    <div className="w-full p-4 m-4 bg-white rounded-lg">
      <label
        htmlFor="message"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Title
      </label>

      <div className="divide-y divide-custom-white">
        {props.data.map((item, index) => (
          <div
            key={item.target_code}
            className="flex items-center py-4"
            onClick={() => onChoosed(item.target_code, item.name)}
          >
            {item.target_code === dataCodeChoosed ? (
              <IconCircleFill />
            ) : (
              <IconCircle />
            )}
            {<p className="ml-3">{item.name}</p>}
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center ">
        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full w-80 h-12"
          onClick={() => onSubmit()}
        >
          <strong>{"Save"}</strong>
        </button>
      </div>
    </div>
  );
}
