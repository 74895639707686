import api from "./api";

export const addOvertime = async (params: {
  reservationCode: string;
  duration: number;
  price: number;
}) => {
  return api
    .post(
      `/v1/members/specialist/reservation/${params.reservationCode}/overtime`,
      {
        price: params.price,
        duration: params.duration,
      }
    )
    .then((val) => val.data);
};

export const updateOvertime = async (params: {
  reservationCode: string;
  duration: number;
  price: number;
}) => {
  return api
    .put(
      `/v1/members/specialist/reservation/${params.reservationCode}/overtime`,
      {
        price: params.price,
        duration: params.duration,
      }
    )
    .then((val) => val.data);
};

export const deleteOvertime = (params: { reservationCode: string }) => {
  return api
    .delete(
      `/v1/members/specialist/reservation/${params.reservationCode}/overtime`
    )
    .then((val) => val.data);
};
