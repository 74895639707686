import React from "react";
import ReactDOM from "react-dom";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@mui/material/Dialog";
import { ModalCLoseIcon } from "./modal";
import { DialogContent } from "@mui/material";
import Button from "@material-tailwind/react/components/Button";
const modalCloseIcon = <FontAwesomeIcon icon={faClose} />;

export function ModalOvertimeConfirm(props: {
  isShow: boolean;
  title?: string;
  onHide: () => void;
  onSubmit: () => void;
}) {
  return (
    <Dialog
      open={props.isShow}
      onClose={props.onHide}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {props.title ?? "Apakah kamu ingin menambah kelebihan waktu?"}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          {/* <img src={Images.overtime} style={{ width: "146px" }} /> */}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onHide}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onSubmit}>
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export interface ModalProps {
  title: string;
  isShown: boolean;
  width?: string;
  hide: () => void;
  reset?: () => any;
  children: JSX.Element;
}

export const ModalSimple: React.FunctionComponent<ModalProps> = ({
  isShown,
  title,
  hide,
  reset,
  children,
  width,
}) => {
  const clearOut = () => {
    hide();

    if (reset) reset();
  };
  const ModalDefault = (
    <Dialog
      PaperProps={{
        sx: { width: width ?? "640px", maxWidth: width ?? "640px" },
      }}
      open={isShown}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          hide();
        }
      }}
      aria-labelledby="draggable-dialog-title"
      className="h-auto"
    >
      <DialogTitle
        style={{
          cursor: "move",
        }}
        id="draggable-dialog-title"
      >
        {title}
        <ModalCLoseIcon title="Close" onClick={clearOut}>
          {modalCloseIcon}
        </ModalCLoseIcon>
      </DialogTitle>

      {children}
    </Dialog>
  );

  return isShown ? ReactDOM.createPortal(ModalDefault, document.body) : null;
};

export function ModalDelete(props: {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title?: string;
}) {
  const title = props.title ?? "Are you sure want to delete this post?";

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        sx={{
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Batal
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onDelete}>
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
