import { useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutePage from "route";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ScrollToTop } from "components/scrollTop";
import utils from "utils";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { LocalStorage } from "helpers";

const queryClient = new QueryClient();

export const ResponseInterceptor = () => {
  // Use useRef to prevent a re-render in the useEffect.
  // A ref, cannot be used as a useEffect dependency, hence,
  // your linters shouldn't complain about missing dependencies.
  const navRef = useRef(useNavigate());
  //const navigate = useNavigate()
  //const interceptorId = useRef<number | null>(null);

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (resp) => resp,
      (error) => {
        console.log(error);
        if (error.response.status === 401) {
          LocalStorage.clearTokenAuth();
          LocalStorage.clearNameCookies();
          navRef.current("/");
        }

        let message = error.response.data.stat_msg;
        if (!message) {
          message = error.toString();
        }
        // toast.error(message);
        return Promise.reject(message);
      }
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, []);

  return null;
};

export default function App() {
  const contentRef = useRef<HTMLDivElement>(null);

  const onScrollToTop = () => {
    if (!window.top) return;

    utils.postMessageScrollTop();
  };

  const onResize = () => {
    if (!window.top) return;

    const message = JSON.stringify({
      type: "height",
      value: contentRef.current?.scrollHeight,
    });
    window.top.postMessage(message, "*");
  };

  // Start observing the element when the component is mounted
  useEffect(() => {
    // refreshToken();

    // initProfile();

    const element = contentRef.current;
    if (!element) return;

    const observer = new ResizeObserver(onResize);
    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="App" ref={contentRef}>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <ResponseInterceptor />
        <RoutePage></RoutePage>
        <ToastContainer />
        <ScrollToTop onScrollToTop={onScrollToTop} />
      </QueryClientProvider>
    </div>
  );
}
