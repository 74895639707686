import { ReactComponent as IconGoogle } from "assets/icons/ic_google.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { InputText } from "components/cinput";
import TermCondition from "components/term_condition";
import config from "helpers/config";
import {
  setNameCookies,
  setRoleCookies,
  setTokenAuth,
  setUserData,
} from "helpers/localStorage";
import { CloseCircle } from "iconsax-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  authLogin,
  authLoginSosmed,
  authRegistration,
  authRegistrationSosmed,
  checkUserGoogle,
  requestOTP,
  verifyEmail,
} from "services/auth";
import { CapitalizeFirstLetter } from "utils";
import ShowHidePassword from "components/showHidePassword";
import OtpInput from "components/OtpInput";

export default function Login() {
  const [isDisabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setDisabled(email === "" || password === "");
  }, [email, password]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isLoadingApple, setIsLoadingApple] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);

  const [authType, setAuthType] = useState(config.constant.login);

  const [showTermCondition, setShowTermCondition] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);

  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(false);

  const changeAuthType = (type) => {
    setEmail("");
    setPassword("");
    setAuthType(type);
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
      setTimeLeft(0);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onForgotPassword = async () => {
    navigate("/forgot");
  };

  const onSubmit = async () => {
    if (authType === config.constant.login) {
      setIsLoading(true);
      authLogin({
        email: email,
        password: password,
      })
        .then((res) => {
          setIsLoading(false);
          const data = res.data;
          const token = data.token;

          setTokenAuth(token);
          setUserData({
            memberCode: data.member_code,
            username: data.username,
            email: data.email,
            profile_url: data.profile_url,
            role_name: data.role_name,
          });

          if (data.username === "") {
            navigate(`/register`);
          } else {
            setNameCookies(data.username);
            setRoleCookies(data.role_name);
            if (data.role_name.includes("member")) {
              window.location.replace(config.LANDING_URL);
            } else {
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.warn(CapitalizeFirstLetter(err));
        });
    }

    if (authType === config.constant.signup) {
      setIsLoading(true);
      authRegistration({
        email: email,
        password: password,
      })
        .then((res) => {
          setIsLoading(false);
          const token = res.data.token;
          setTokenAuth(token);
          requestOTP().then((res) => {
            setCanResend(false);
            setShowVerifyOTP(true);
            setTimeLeft(30);
          });
        })
        .catch((err) => {
          setIsLoading(false);
          toast.warn(CapitalizeFirstLetter(err));
        });
    }
  };

  const onSubmitGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      if (authType === config.constant.login) {
        loginGoogle("gmail", tokenResponse.access_token, userInfo.email);
      } else {
        registerGoogle("gmail", tokenResponse.access_token, userInfo.email);
      }
    },
    onError: () => {
      toast.warn(CapitalizeFirstLetter("Gagal Login"));
    },
  });

  const loginGoogle = async (type, token, emailGoogle) => {
    setIsLoadingGoogle(true);
    authLoginSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
    })
      .then((res) => {
        setIsLoadingGoogle(false);
        const data = res.data;
        const token = data.token;

        setTokenAuth(token);
        setUserData({
          memberCode: data.member_code,
          username: data.username,
          email: data.email,
          profile_url: data.profile_url,
          role_name: data.role_name,
        });

        if (data.username === "") {
          navigate(`/register`);
        } else {
          setNameCookies(data.username);
          setRoleCookies(data.role_name);
          if (data.role_name.includes("member")) {
            window.location.replace(config.LANDING_URL);
          } else {
            navigate(`/home`);
          }
        }
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const registerGoogle = async (type, token, emailGoogle) => {
    setIsLoadingGoogle(true);
    authRegistrationSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
      code: "",
    })
      .then((res) => {
        setIsLoadingGoogle(false);
        const data = res.data;
        const token = data.token;
        setTokenAuth(token);
        requestOTP().then((res) => {
          setCanResend(false);
          setShowVerifyOTP(true);
          setTimeLeft(30);
        });
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onSubmitApple = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      loginApple("apple", tokenResponse.access_token);
    },
    onError: () => {
      toast.warn(CapitalizeFirstLetter("Gagal Login"));
    },
    // flow: "auth-code",
  });

  const loginApple = async (type, token) => {
    setIsLoadingApple(true);
    const res = await authLoginSosmed({
      email: email,
      sosmed_type: type,
      sosmed_token: token,
    });

    setIsLoadingApple(false);
    if (res.stat_msg === "Success") {
      const data = res.data;
      const token = data.token;

      setTokenAuth(token);
      setNameCookies(data.username);
      setRoleCookies(data.role_name);

      if (data.role_name.includes("member")) {
        window.location.replace(config.LANDING_URL);
      } else {
        window.location.reload();
      }
    } else {
      alert(res);
    }
  };

  const [otp, setOtp] = useState("");
  const [isBtnVerifyDisabled, setBtnVerifyDisabled] = useState(true);

  const onChangeOTP = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    setBtnVerifyDisabled(otp.trim().length < 4);
  }, [otp]);

  const onResend = async () => {
    requestOTP().then((res) => {
      setTimeLeft(30);
      setCanResend(false);
    });
  };

  const onVerify = async () => {
    setIsLoadingVerify(true);
    verifyEmail({
      pin: otp,
    })
      .then((res) => {
        setIsLoadingVerify(false);
        navigate(`/register`);
      })
      .catch((err) => {
        setIsLoadingVerify(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  return (
    <div className="max-h-screen ">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
        <div className="relative max-h-screen w-full md:w-full ">
          <img
            src="/images/bg_login.png"
            className="max-h-screen w-full object-cover object-top"
            alt=""
          />
          <img
            src="/images/logo_circle.png"
            className="absolute top-10 left-10 w-16 h-16"
            alt=""
          />
        </div>
        <div className="bg-white px-8 md:px-20 py-5 my-auto max-h-screen overflow-y-auto">
          {authType === config.constant.login ? (
            <SectionLogin
              email={email}
              password={password}
              isLoading={isLoading}
              isLoadingGoogle={isLoadingGoogle}
              isLoadingApple={isLoadingApple}
              isDisabled={isDisabled}
              setEmail={setEmail}
              setPassword={setPassword}
              setAuthType={changeAuthType}
              onSubmit={onSubmit}
              onForgotPassword={onForgotPassword}
              onSubmitGoogle={onSubmitGoogle}
              onSubmitApple={onSubmitApple}
            ></SectionLogin>
          ) : authType === config.constant.signup ? (
            <SectionRegister
              email={email}
              password={password}
              isLoading={isLoading}
              isLoadingGoogle={isLoadingGoogle}
              isDisabled={isDisabled}
              setEmail={setEmail}
              setPassword={setPassword}
              setAuthType={changeAuthType}
              setShowTerm={setShowTermCondition}
              onSubmit={onSubmit}
              onSubmitGoogle={onSubmitGoogle}
            ></SectionRegister>
          ) : null}
        </div>
      </div>
      {showTermCondition ? (
        <>
          <div className="flex justify-center mx-10 items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-lg font-semibold">
                    Terms and Conditions
                  </h3>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setShowTermCondition(false)}
                  >
                    <CloseCircle size="24" color="#121122" />
                  </button>
                </div>
                <TermCondition />
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showVerifyOTP ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className="w-full h-full bg-black/40"
              onClick={() => {
                // setShowVerifyOTP(false);
              }}
            ></div>
            <div className="absolute w-auto my-6 mx-auto max-w-xl">
              <div className="border-0 rounded-lg shadow-lg flex justify-center w-full bg-white focus:outline-none">
                <div className="text-center px-20 py-16">
                  <p className="text-lg font-semibold">Verify your email</p>
                  <p className="text-sm font-normal text-text-grey mt-4">
                    Please enter the 4 Digit code sent to
                  </p>
                  <p className="text-sm font-medium text-text-grey mb-6">
                    {email}
                  </p>
                  <div className="flex justify-center">
                    <OtpInput
                      value={otp}
                      valueLength={4}
                      onChange={onChangeOTP}
                    />
                  </div>
                  <div className="mt-6">
                    {canResend ? (
                      <div className="flex justify-center">
                        <p className="text-sm text-text-grey">
                          Didn't receive code?
                        </p>
                        <p
                          className="text-sm font-medium text-text-dark-blue ml-1"
                          onClick={() => {
                            onResend();
                          }}
                        >
                          Resend Code
                        </p>
                      </div>
                    ) : (
                      <p className="text-sm text-text-grey">
                        {"Please wait " +
                          timeLeft +
                          " seconds to resend the code"}
                      </p>
                    )}
                  </div>
                  <button
                    type="button"
                    disabled={isBtnVerifyDisabled}
                    className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-10 mt-4 disabled:bg-slate-400	disabled:cursor-not-allowed"
                    onClick={() => onVerify()}
                  >
                    <strong>{isLoadingVerify ? "Loading..." : "Verify"}</strong>
                    {isLoadingVerify ? (
                      <div
                        className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      ></div>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

function SectionRegister(props: {
  email: string;
  password: string;
  isLoading: boolean;
  isLoadingGoogle: boolean;
  isDisabled: boolean;
  setEmail: (_: string) => void;
  setPassword: (_: string) => void;
  setAuthType: (_: string) => void;
  setShowTerm: (_: boolean) => void;
  onSubmit: () => void;
  onSubmitGoogle: () => void;
}) {
  return (
    <div className="">
      <p className="text-2xl">Welcome to Maxi!</p>
      <p className="text-4xl font-semibold">Sign up for your account</p>
      <button
        type="button"
        className="text-white px-4 flex items-center justify-between bg-white border border-solid border-custom-border hover:bg-blue/10 rounded-full w-full h-12 mt-4 "
        onClick={props.onSubmitGoogle}
      >
        {props.isLoadingGoogle ? (
          <div
            className="h-5 w-5 animate-spin rounded-full border-4 border-solid border-blue border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          ></div>
        ) : (
          <IconGoogle />
        )}
        <span className="text-text-black font-medium">
          {props.isLoadingGoogle ? "Loading..." : "Sign Up With Google"}
        </span>
        <span className="w-5"></span>
      </button>
      <div className="flex flex-row	items-center mt-8">
        <div className="flex-1 h-px bg-custom-border" />
        <div>
          <p className="w-auto px-4 text-xs text-text-grey">
            or continue with email
          </p>
        </div>
        <div className="flex-1 h-px bg-custom-border" />
      </div>
      <div className="mt-8">
        <InputText
          placeholder="Your email"
          onChange={(val) => props.setEmail(val)}
          defaultValue={props.email}
          type="email"
          id="email"
        ></InputText>
      </div>
      <div className="mt-4">
        <ShowHidePassword
          name={props.password}
          defaultValue={props.password}
          placeholder="Password (8+ characters)"
          onChange={(val) => {
            props.setPassword(val);
          }}
        ></ShowHidePassword>
      </div>
      <div className="flex items-center justify-center mt-4">
        <div className="text-center	">
          <p className="text-xs text-text-grey">
            By signing up, I agree to the
          </p>
          <p
            className="text-text-dark-blue text-xs cursor-pointer"
            onClick={() => props.setShowTerm(true)}
          >
            terms and conditions
          </p>
        </div>
      </div>
      <button
        type="button"
        className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-12 mt-4 disabled:bg-slate-300"
        onClick={props.onSubmit}
        disabled={props.isDisabled}
      >
        <strong>{props.isLoading ? "Loading..." : "Register"}</strong>
        {props.isLoading ? (
          <div
            className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          ></div>
        ) : null}
      </button>
      <div className="flex items-center justify-center mt-4">
        <div className="font-normal text-sm text-text-grey">
          Already have an account?{" "}
          <span
            className="text-brand-dark cursor-pointer"
            onClick={() => props.setAuthType(config.constant.login)}
          >
            Login
          </span>
        </div>
      </div>
    </div>
  );
}

function SectionLogin(props: {
  email: string;
  password: string;
  isLoading: boolean;
  isLoadingGoogle: boolean;
  isLoadingApple: boolean;
  isDisabled: boolean;
  setEmail: (_: string) => void;
  setPassword: (_: string) => void;
  setAuthType: (_: string) => void;
  onSubmit: () => void;
  onForgotPassword: () => void;
  onSubmitGoogle: () => void;
  onSubmitApple: () => void;
}) {
  return (
    <div className="">
      <p className="text-2xl">Welcome to Maxi!</p>
      <p className="text-4xl font-semibold">Log In to your account</p>
      <button
        type="button"
        className="text-white px-4 flex items-center justify-between bg-white border border-solid border-custom-border hover:bg-blue/10 rounded-full w-full h-12 mt-4 "
        onClick={props.onSubmitGoogle}
      >
        {props.isLoadingGoogle ? (
          <div
            className="h-5 w-5 animate-spin rounded-full border-4 border-solid border-blue border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          ></div>
        ) : (
          <IconGoogle />
        )}
        <span className="text-text-black font-medium">
          {props.isLoadingGoogle ? "Loading..." : "Sign In With Google"}
        </span>
        <span className="w-5"></span>
      </button>
      <div className="flex flex-row	items-center mt-8">
        <div className="flex-1 h-px bg-custom-border" />
        <div>
          <p className="w-auto px-4 text-xs text-text-grey">
            or continue with email
          </p>
        </div>
        <div className="flex-1 h-px bg-custom-border" />
      </div>
      <div className="mt-8">
        <InputText
          placeholder="Your email"
          onChange={(val) => {
            props.setEmail(val);
          }}
          defaultValue={props.email}
          type="email"
          id="email"
        ></InputText>
      </div>
      <div className="mt-4">
        <ShowHidePassword
          name={props.password}
          defaultValue={props.password}
          placeholder="Password"
          onChange={(val) => {
            props.setPassword(val);
          }}
        ></ShowHidePassword>
      </div>

      <div
        className="flex items-center justify-end mt-4 cursor-pointer"
        onClick={props.onForgotPassword}
      >
        <div className="font-medium text-sm text-text-dark-blue">
          Forgot your password?
        </div>
      </div>

      <button
        type="button"
        className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-12 mt-4 disabled:bg-gray-200"
        disabled={props.isDisabled}
        onClick={props.onSubmit}
      >
        <strong>{props.isLoading ? "Loading..." : "Login"}</strong>
        {props.isLoading ? (
          <div
            className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          ></div>
        ) : null}
      </button>
      <div className="flex items-center justify-center mt-4">
        <div className="font-normal text-sm text-text-grey">
          Don’t have an account?{" "}
          <span
            className="text-brand-dark cursor-pointer"
            onClick={() => props.setAuthType(config.constant.signup)}
          >
            Sign Up
          </span>
        </div>
      </div>
    </div>
  );
}
