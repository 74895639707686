export const sessionTypes = [
  {
    key: "preview",
    label: "Preview 15-min",
  },
  {
    key: "video_call",
    label: "Video Call 60-min",
  },
  {
    key: "voice_call",
    label: "Voice Call 60-min",
  },
];
