import moment from "moment";

export const removeEmoji = (text?: string) => {
  if (!text) {
    return "";
  }

  return text
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    )
    .replace(/\s+/g, " ")
    .trim();
};

export const GetExtension = (str) => {
  return str.split("/").pop().split(".").pop();
};

export const CapitalizeFirstLetter = (str) => {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export function formatRupiah(amount) {
  return Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(amount);
}

export function getSessionType(text: string) {
  const PREVIEW = "preview";
  const VIDEO_CALL = "video_call";
  const VOICE_CALL = "voice_call";

  if (text === VIDEO_CALL) {
    return "Video Call";
  } else if (text === VOICE_CALL) {
    return "Voice Call";
  } else {
    return "Free";
  }
}

export function getSessionTypeShort(type: string) {
  if (type.includes("in_person")) {
    return "In-Person";
  } else {
    return "Online";
  }
}

export function getScheduleByDay(day: string, data: any) {
  if (data && data.schedule_day) {
    console.log(JSON.stringify(data.schedule_day));
    var index = data.schedule_day.findIndex((_x) => _x.day === day);
    if (index > -1) {
      var days = "";
      for (const _day of data.schedule_day[index].time) {
        if (days === "") {
          days =
            moment(_day.time_from, "HH:mm:ss").format("HH:mm A") +
            " - " +
            moment(_day.time_to, "HH:mm:ss").format("HH:mm A");
        } else {
          days =
            days +
            "\n" +
            moment(_day.time_from, "HH:mm:ss").format("HH:mm A") +
            " - " +
            moment(_day.time_to, "HH:mm:ss").format("HH:mm A");
        }
      }
      return days;
    } else {
      return "-";
    }
  } else {
    return "-";
  }
}

function postMessageScrollTop() {
  if (!window.top) return;

  const message = JSON.stringify({
    type: "scroll_top",
  });
  window.top.postMessage(message, "*");
}

const utils = {
  postMessageScrollTop,
};
export default utils;
