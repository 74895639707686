import { ReactComponent as IconCircleFill } from "assets/icons/ic_check_circle_fill.svg";
import { ReactComponent as IconCircle } from "assets/icons/ic_check_circle.svg";
import { useEffect, useState } from "react";

export default function Country(props: {
  data;
  choosedData;
  onSave: (name) => void;
}) {
  const onSubmit = async () => {
    props.onSave(dataNameChoosed);
  };

  const onChoosed = async (name) => {
    setDataNameChoosed(name);
  };

  const [dataNameChoosed, setDataNameChoosed] = useState("");

  useEffect(() => {
    setDataNameChoosed(props.choosedData);
  }, [props.choosedData]);

  return (
    <div className="w-full p-4 m-4 bg-white rounded-lg">
      <label
        htmlFor="message"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Country
      </label>

      <div className="divide-y divide-custom-white max-h-[500px] overflow-y-auto	">
        {props.data.map((item, index) => (
          <div
            key={item.country_code}
            className="flex items-center py-4"
            onClick={() => onChoosed(item.name)}
          >
            {item.name === dataNameChoosed ? (
              <IconCircleFill />
            ) : (
              <IconCircle />
            )}
            {<p className="ml-3">{item.name}</p>}
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center ">
        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full w-80 h-12"
          onClick={() => onSubmit()}
        >
          <strong>{"Save"}</strong>
        </button>
      </div>
    </div>
  );
}
