const config = {
  API_URL: process.env.REACT_APP_API_URL,
  LANDING_URL: `${process.env.REACT_APP_LANDING_URL}`,
  AWS_URL_IMAGES: process.env.REACT_APP_AWS_URL,
  GOOGLE_CLIENT_ID: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
  GOOGLE_API_KEY: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  isProduction: process.env!.NODE_ENV === "production",
  constant: {
    signup: "signup",
    login: "login",
  },
  useQuery: {
    fetchOnce: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  },
  timezoneOffsetHour: Math.round(new Date().getTimezoneOffset() / -60),
};

export default config;
