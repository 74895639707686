import axios from "axios";
import config from "helpers/config";
import { getTokenAuth } from "helpers/localStorage";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    "X-Channel": "app",
    "X-TIMEZONE": config.timezoneOffsetHour,
  },
});

// Add a request interceptor
api.interceptors.request.use((req) => {
  const token = getTokenAuth();
  req.headers!.Authorization = token!;

  return req;
});

export default api;
