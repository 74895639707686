import { useEffect, useState } from "react";

export default function AboutMe(props: { data; onSave: (name) => void }) {
  const [bio, setBio] = useState<any>();

  useEffect(() => {
    setBio(props.data);
  }, [props.data]);

  return (
    <div className="w-full p-4 m-4 bg-white rounded-lg">
      <label
        htmlFor="message"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Tentang Saya
      </label>
      <textarea
        id="message"
        rows={10}
        value={bio}
        onChange={(val) => setBio(val.target.value)}
        className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Bagikan lebih banyak tentang diri saya"
      ></textarea>
      <div className="w-full flex justify-center ">
        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full w-80 h-12 disabled:bg-slate-300"
          disabled={bio === ""}
          onClick={(v) => props.onSave(bio)}
        >
          <strong>{"Simpan"}</strong>
        </button>
      </div>
    </div>
  );
}
