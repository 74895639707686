import Cookies from "js-cookie";
import config from "./config";

const MaxiToken = "maxi-token";
const MaxiUser = "maxi-user-data";
const MaxiUserName = "maxi_name_user";
const MaxiRole = "maxi_role";
const MaxiLastRefresh = "last_refresh";

//  User Name
export function clearNameCookies() {
  Cookies.remove(MaxiUserName, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function setNameCookies(name: string) {
  Cookies.set(MaxiUserName, name, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function getNameCookies() {
  return Cookies.get(MaxiUserName);
}

//  Role
export function clearRoleCookies() {
  Cookies.remove(MaxiRole, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function setRoleCookies(name: string) {
  Cookies.set(MaxiRole, name, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function getRoleCookies() {
  return Cookies.get(MaxiRole);
}

// Last Refresh
export function clearRefreshDate() {
  return localStorage.removeItem(MaxiLastRefresh);
}

// Last Refresh //
export function getLastRefreshDate() {
  return localStorage.getItem(MaxiLastRefresh);
}

export function setLastRefreshDate(val: string) {
  return localStorage.setItem(MaxiLastRefresh, val);
}

// User Data
export function clearUserData() {
  return localStorage.removeItem(MaxiUser);
}

// User Data //
export function setUserData(data: any) {
  return localStorage.setItem(MaxiUser, JSON.stringify(data));
}

export function getUserdata() {
  return JSON.parse(localStorage.getItem(MaxiUser) ?? "");
}

// TOKEN //
export function getTokenAuth() {
  return Cookies.get(MaxiToken);
}

export async function setTokenAuth(token: string) {
  Cookies.set(MaxiToken, token, {
    domain: config.COOKIE_DOMAIN,
  });
}

export function clearTokenAuth() {
  Cookies.remove(MaxiToken, {
    domain: config.COOKIE_DOMAIN,
  });

  return Promise.all([
    // localStorage.removeItem(MaxiToken),
    clearNameCookies(),
    clearUserData(),
  ]);
}
