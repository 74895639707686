import { ReactComponent as IconCircleFill } from "assets/icons/ic_check_circle_fill.svg";
import { ReactComponent as IconCircle } from "assets/icons/ic_check_circle.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  checkCalendarToken,
  refreshCalendarToken,
  updateCalendarToken,
} from "services/schedule";
import { toast } from "react-toastify";

export default function CalendarIntegration(props: {
  code;
  onSave: () => void;
}) {
  const onSubmit = async () => {
    checkCalendarToken({ memberCode: props.code }).then(async (val) => {
      if (val.data === null) {
        checkCalendar();
      } else {
        await updateCalendarToken({
          status: "active",
          memberCode: props.code,
        });

        toast.success("Berhasil Mengintegrasikan Kalender Google!");
      }
    });
  };

  const checkCalendar = async () => {
    refreshCalendarToken({
      memberCode: props.code,
    }).then((val) => {
      const windowName = "LoginGoogle";
      const w = 500;
      const h = 600;
      const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
      const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
      const myWindow = window.open(
        val.data.url,
        windowName,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );

      const timer = setInterval(async function () {
        // if (
        //   myWindow?.location.href === "https://landing.ohanaspace.id/" ||
        //   myWindow?.location.href === "https://ohanaspace.id/"
        // ) {
        //   myWindow?.close();
        // }

        if (myWindow?.closed) {
          clearInterval(timer);
          await updateCalendarToken({
            status: "active",
            memberCode: props.code,
          });

          toast.success("Berhasil Mengintegrasikan Kalender Google!");
        }
      }, 200);
    });
  };

  return (
    <div className="w-full p-4 m-4 bg-white rounded-lg">
      <div className="text-center">
        <p className="text-xl font-semibold">Integrasi Kalender Google</p>
        <p className="text-sm mt-3">
          Klien Anda akan memesan slot waktu Anda lebih awal dari waktu yang
          Anda tentukan
        </p>
        <button
          type="button"
          className="text-white bg-black hover:bg-black/60 rounded-full w-80 h-12 mt-4"
          onClick={() => onSubmit()}
        >
          <p>Integrasikan Kalender Google</p>
        </button>
      </div>
    </div>
  );
}
