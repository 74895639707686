import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { useEffect, useRef, useState } from "react";
import { getSessionCalendar, getSessionDaily } from "services/session";
import moment from "moment";
import config from "helpers/config";
import { Calendar } from "iconsax-react";
import DetailSession from "./content/detail_session";
import { ReactComponent as IconNoSession } from "assets/icons/ic_no_session.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ListSession } from "components/ListSession";

export default function Session() {
  const navigate = useNavigate();

  const { id } = useParams();
  const { date } = useParams();
  const [choosedSession, setChoosedSession] = useState("");

  const initialValue = dayjs(new Date().toLocaleDateString());
  const requestAbortController = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [value, setValue] = useState<Dayjs>(initialValue);

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date.add(1, "day"));
  };

  const fetchHighlightedDays = (date: Dayjs) => {
    return getSessionCalendar({
      year: date.year().toString(),
      month: (date.month() + 1).toString(),
    }).then((res) => {
      var tifOptions: number[] = [];

      for (let index = 0; index < res.data.activities.length; index++) {
        if (res.data.activities[index].counseling.count > 0) {
          tifOptions.push(res.data.activities[index].date);
        }
      }

      setIsLoading(false);
      setHighlightedDays(tifOptions);
    });
  };

  function ServerDay(
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
  ) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        sx={
          isSelected
            ? {
                "& .MuiBadge-badge": {
                  width: 36,
                  height: 36,
                  borderRadius: 30,
                  mt: 1.6,
                  mr: 1.8,
                  backgroundColor: "#8BD2F038",
                  zIndex: -10,
                },
              }
            : {}
        }
        overlap="circular"
        // variant="dot"
        badgeContent={isSelected ? "" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  const [dataDaily, setDataDaily] = useState<any[]>([]);
  const fetchDaily = async (date: Dayjs) => {
    const res = await getSessionDaily({
      date: moment(date.toString()).format("YYYY-MM-DD"),
      lang: "en",
      types: "counseling",
    });
    setDataDaily(res.data);
  };

  useEffect(() => {
    setValue(dayjs(date));
  }, [date]);

  useEffect(() => {
    setChoosedSession(id ?? "");
  }, [id]);

  useEffect(() => {
    fetchHighlightedDays(value);
    fetchDaily(value);
  }, [value]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-6 h-auto min-h-screen ">
      <div className="col-span-2 h-auto min-h-full p-6 md:border-r md:border-r-grey-500 border-b border-b-grey-500">
        <h1 className="text-xl font-semibold">Session</h1>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            views={["day"]}
            defaultValue={initialValue}
            loading={isLoading}
            onMonthChange={handleMonthChange}
            className="w-[290px] m-0"
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            sx={{
              width: 1,
              mb: 3,
              "& .MuiPickersFadeTransitionGroup-root ": {
                width: 1,
                display: "",
              },
              "& .MuiDayCalendar-header ": {
                width: 1,
                justifyContent: "space-between",
              },
              "& .MuiPickersSlideTransition-root ": {
                width: 1,
                display: "",
              },
              "& .MuiDayCalendar-weekContainer ": {
                width: 1,
                justifyContent: "space-between",
              },
            }}
            slotProps={{
              day: {
                highlightedDays,
              } as any,
            }}
            value={value}
            onChange={(newValue) => {
              setChoosedSession("");
              setValue(newValue!);
              fetchDaily(newValue!);
            }}
          />
        </LocalizationProvider>
        <p className="text-base font-medium">
          {moment(value.toString()).format("DD MMM YYYY")}
        </p>
        <div className="divide-y divide-custom-white">
          {dataDaily !== undefined
            ? dataDaily !== null
              ? dataDaily.map((item, i) => (
                  <ListSession value={value} item={item} />
                ))
              : null
            : null}
        </div>
      </div>
      <div className="col-span-4 h-auto min-h-full bg-white">
        {choosedSession !== "" ? (
          <DetailSession code={choosedSession} onChange={() => {}} />
        ) : (
          <div className="p-6">
            <div className="w-full">
              <h1 className="text-xl font-semibold">Session's Info</h1>
            </div>
            <div className="w-full flex flex-col items-center justify-center text-center mt-[150px] mb-[150px]">
              <IconNoSession />
              <p className="text-text-grey text-xs">No Session yet</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
